import React, { memo } from 'react'
import { Border } from 'components/Border/Border.component'
import { BorderType } from 'components/Border/Border.model'
import { colors } from 'shared/theme/theme'
import classnames from 'classnames'
import styles from './CircleChoiceItem.module.scss'
import type {
  CircleChoiceImageProps,
  CircleChoiceItemProps
} from './CircleChoiceItem.model'

/**
 * Use `memo` to skip re-rendering the React component of CircleChoiceImage
 * when its props are unchanged.
 * The `optionImage` prop is of primitive type.
 */
const CachedCircleChoiceImage = memo(function CircleChoiceImage({
  optionImage
}: CircleChoiceImageProps): React.JSX.Element {
  return (
    <Border
      type={BorderType.Circle2}
      fill={optionImage ? 'none' : colors.white}
      image={optionImage}
    />
  )
})

export function CircleChoiceItem({
  selected,
  option,
  onHandleClick
}: CircleChoiceItemProps): React.JSX.Element {
  const handleClick = () => {
    onHandleClick(option)
  }

  return (
    <div
      className={classnames(styles['circlechoice-item__option'], {
        [styles['circlechoice-item__option--dimmer']]:
          selected && selected !== option.label
      })}
      key={`circle-choice-${option.id}`}
      role="button"
      onClick={handleClick}
    >
      <CachedCircleChoiceImage optionImage={option.image} />
    </div>
  )
}
