import type {
  SiteTarget,
  Stratification,
  TargetCollection
} from 'api/client.model'
import groupBy from 'lodash/groupBy'
import type {
  AnalysisModel,
  TargetAnalysisModel,
  TargetAnalysisData
} from 'screens/ra-dashboard/index.model'
import { AGE, GENDER, INDIGENOUS } from 'shared/constants/SurveyFields.d'
import store from 'store/store'
import type { SurveyResponse } from 'store/type'
import type { Gender, RADashCalcModel } from './raDashboardCalculation.model'
import { matchCondition } from 'shared/utils/matchCondition/matchCondition'
import { RaTargetsType } from 'shared/constants/Constants.d'
import type { DeepReadonly } from 'shared/types/general'

export const RADashCalc = (): RADashCalcModel => {
  const storeData = store.getState()
  const { isMatchCondition } = matchCondition()

  /**
   * Retrieve the survey responses by filters from the Redux store.
   * @param projectId The selected project id.
   * @param siteId The selected site id.
   * @param today `true` if only today's responses should be calculated; `false` otherwise.
   * @param ra Current login RA id.
   *
   * @returns The filtered survey responses from the Redux store.
   */
  const calcTotalResponses = (
    projectId: string,
    siteId: string,
    today?: boolean,
    ra?: string
  ): SurveyResponse[] => {
    if (!storeData.surveyResponses) return []
    return storeData.surveyResponses.filter(
      (data) =>
        data.local &&
        data.metadata?.projectId?.toString() === projectId &&
        data.metadata?.siteId?.toString() === siteId &&
        data.metadata.startTime &&
        (today
          ? new Date(data.metadata.startTime).toDateString() ===
            new Date().toDateString()
          : true) &&
        (ra ? data.metadata.raId === ra : true)
    )
  }

  const calcCategoryNumber = (
    aboriginal: boolean,
    gender: Gender,
    age: number[],
    responses: DeepReadonly<SurveyResponse[]>
  ): number => {
    const indigenous = ['aboriginal', 'both']
    const num = responses.filter(
      (res) =>
        res.responses &&
        (aboriginal
          ? indigenous.includes(res.responses[INDIGENOUS])
          : !indigenous.includes(res.responses[INDIGENOUS])) &&
        res.responses[GENDER] === gender &&
        age.includes(Number(res.responses[AGE]))
    )
    return num.length
  }

  /**
   * Get the count of responses relative to each project site target.
   * @param stratification The stratification in per site target
   * @param gender The gender in per site target
   * @param minAge The mixAge in per site target
   * @param maxAge The maxAge in per site target
   * @param unSyncedResponses The unsynced completed survey responses collected.
   *
   * @returns A number representing the count of responses relative to each project site target.
   */
  const calcUnsyncedSurveysTargetNumber = (
    stratification: Stratification,
    gender: string,
    minAge: number,
    maxAge: number,
    unSyncedResponses: DeepReadonly<SurveyResponse[]>
  ): number => {
    const filteredResponses = unSyncedResponses.filter(
      (res) =>
        res.responses &&
        res.responses[GENDER] === gender.toLowerCase() &&
        minAge <= Number(res.responses[AGE]) &&
        maxAge >= Number(res.responses[AGE]) &&
        (!stratification.value ||
          (stratification.value &&
            isMatchCondition(
              stratification,
              res.responses[stratification.surveyField]
            )))
    )

    // Get unique SLK values from the unsynced completed survey responses relative to current site target. (Issue #559)
    const uniqueSLKs = filteredResponses.reduce<string[]>((acc, cv) => {
      if (cv.metadata?.slk && !acc.includes(cv.metadata?.slk)) {
        acc.push(cv.metadata?.slk)
      }
      return acc
    }, [])

    return uniqueSLKs.length
  }

  const calcTargetSurveys = (
    projectId: string,
    siteId: string
  ): SiteTarget[] | undefined => {
    const targets = storeData.projects
      ?.find((project) => project.id === projectId)
      ?.sites?.find((site) => site.id === siteId)?.targets
    return targets || undefined
  }

  const generateTargetData = (
    siteTargets: DeepReadonly<TargetCollection[]> | null | undefined,
    raTargetsType: RaTargetsType,
    unsyncedCompletedResponses?: DeepReadonly<SurveyResponse[]>
  ): TargetAnalysisModel[] => {
    if (siteTargets == null) {
      return []
    }

    siteTargets = siteTargets.filter((item) => item.stratification)

    const groupedTarget = groupBy(siteTargets, (d) => {
      return d.stratification.label
    })

    const results: TargetAnalysisModel[] = []

    for (const targetValue in groupedTarget) {
      const targetvalue = groupedTarget[targetValue]
      const groupedGender = groupBy(targetvalue, 'gender')
      const data: TargetAnalysisData[] = []

      for (const gender in groupedGender) {
        const itemsInGroupedGender = groupedGender[gender]
        const analysisArr: AnalysisModel[] = []

        for (const item of itemsInGroupedGender) {
          const syncedCollection: number = item.numberOfCollection ?? 0

          let quantity: number
          let itemCount: number

          if (
            raTargetsType.valueOf() === RaTargetsType.OverallProgress.valueOf()
          ) {
            // Numerator: num of the collected (synced from any RA)
            quantity = syncedCollection
            // Denominator: target
            itemCount = item.count
          } else {
            // RaTargetsType.DashBoard
            // Numerator: num of the unsynced completed surveys
            quantity = calcUnsyncedSurveysTargetNumber(
              item.stratification,
              gender,
              item.minAge,
              item.maxAge,
              unsyncedCompletedResponses!
            )

            // Denominator: remaining = target - synced
            itemCount =
              item.count >= syncedCollection ? item.count - syncedCollection : 0
          }

          const analysisData: AnalysisModel = {
            label:
              `aged ${item.minAge}` +
              (item.maxAge <= 100 ? `-${item.maxAge}` : '+'),
            quantity: quantity,
            count: itemCount
          }
          analysisArr.push(analysisData)
        }

        const oneData: TargetAnalysisData = {
          gender: gender,
          data: analysisArr
        }
        data.push(oneData)
      }

      const result: TargetAnalysisModel = {
        value: targetValue,
        data: data
      }
      results.push(result)
    }

    return results
  }

  return {
    calcTotalResponses,
    calcCategoryNumber,
    calcTargetSurveys,
    calcUnsyncedSurveysTargetNumber,
    generateTargetData
  }
}
