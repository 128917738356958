import React, { useRef } from 'react'
import { Box } from '@mui/material'
import { Icon } from 'components/Icons/Icon.component'
import { IconType } from 'components/Icons/Icon.model'
import { Button } from 'components/Button/Button.component'
import type { SummaryProps } from './Summary.model'
import classnames from 'classnames'
import styles from './Summary.module.scss'
import { SummaryList } from './SummaryList/SummaryList.component'

export const Summary: React.FC<SummaryProps> = ({
  updatedConsumptionList,
  onDiaryIncrease,
  onDiaryDecrease,
  isOutro
}) => {
  const scrollRef = useRef<HTMLDivElement>(null)
  const scrollOffset = 100

  const handleScrollUp = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({
        top: -scrollOffset,
        left: 0,
        behavior: 'smooth'
      })
    }
  }

  const handleScrollDown = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({
        top: scrollOffset,
        left: 0,
        behavior: 'smooth'
      })
    }
  }

  return (
    <div style={{ width: '100%' }}>
      <Button
        aria-label="ChevronUp"
        onClick={handleScrollUp}
        noBorder={true}
        className={classnames(
          styles['summary__button'],
          styles['summary__button--up']
        )}
      >
        <Icon icon={IconType.ChevronUp} size={10} className="icon" />
      </Button>

      <Box sx={{ width: '100%', overflow: 'hidden' }}>
        <SummaryList
          updatedConsumptionList={updatedConsumptionList}
          onDiaryIncrease={onDiaryIncrease}
          onDiaryDecrease={onDiaryDecrease}
          scrollRef={scrollRef}
          isOutro={isOutro}
        />
      </Box>

      <Button
        aria-label="ChevronDown"
        onClick={handleScrollDown}
        noBorder={true}
        className={classnames(
          styles['summary__button'],
          styles['summary__button__down']
        )}
      >
        <Icon icon={IconType.ChevronDown} size={10} className="icon" />
      </Button>
    </div>
  )
}
