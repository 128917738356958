import React from 'react'
import styles from './SummaryDrinkListing.module.scss'
import classnames from 'classnames'
import * as iterUtils from 'shared/utils/iterUtils/iterUtils'
import type {
  SummaryDrinkListingGridProps,
  SummaryDrinkListingProps
} from './SummaryDrinkListing.model'
import { Container } from 'components/GrogConsumptions/Container/Container.component'
import { Product } from 'components/GrogConsumptions/Product/Product.component'
import { Button } from 'components/Button/Button.component'
import { Icon } from 'components/Icons/Icon.component'
import { IconType } from 'components/Icons/Icon.model'

// To wrap Container in React.memo to prevent unnecessary re-renders
// (I am not sure why the same implementation does not work for Product)
const ContainerMemo = React.memo(Container)

const ContainerDrinkListingGrid: React.FC<SummaryDrinkListingGridProps> = (
  props
) => {
  const { item } = props

  return (
    <>
      {iterUtils.range(item.drinkAmounts.individualAmount ?? 0).map((num) => (
        <div
          key={`grog-summary-container-${item.uuid}-${num}`}
          className={classnames(
            styles['summary-drink-listing__item__container']
          )}
        >
          <ContainerMemo
            image={item.container.image}
            fillMaskImage={item.container.fillMaskImage}
            topBorder={item.container.topBorder}
            bottomBorder={item.container.bottomBorder}
            min={0}
            max={100}
            maxHeight={100}
            drinkValue={item.container.grog}
            fizzyDrinkValue={
              (item.container.grog != null ? item.container.grog : 0) +
              (item.container.fizzy != null ? item.container.fizzy : 0)
            }
            drinkColor={item.container.colour}
          />
        </div>
      ))}
    </>
  )
}

const ProductDrinkListingGrid: React.FC<SummaryDrinkListingGridProps> = (
  props
) => {
  const { item } = props

  const isPack = item.subContainer?.amount === 1 ? false : true
  const drinkAmount = Number(item.drinkAmounts.individualAmount)
  const step = Number(
    isPack ? item.subContainer?.amount : item.subContainer?.stepsInEach
  )
  const max = Math.ceil(drinkAmount / Number(step))

  return (
    <>
      {iterUtils.range(max).map((num) => {
        const clipPercentage =
          100 -
          (drinkAmount - (num + 1) * step > 0
            ? 1
            : (drinkAmount - num * step) / step) *
            100
        return (
          <div
            key={`grog-summary-product-${item.uuid}-${num}`}
            className={classnames(
              styles['summary-drink-listing__item__container']
            )}
          >
            <Product
              isPack={isPack}
              clipPercentage={clipPercentage}
              image={item.product?.image || ''}
              style={{ width: '7vw' }}
            />
          </div>
        )
      })}
    </>
  )
}

export const SummaryDrinkListing: React.FC<SummaryDrinkListingProps> = (
  props
) => {
  const { item, onDiaryIncrease, onDiaryDecrease, isOutro = false } = props

  const isContainer = Boolean(item.container.name)

  return (
    <div className={classnames(styles['summary-drink-listing__item'])}>
      <div className={classnames(styles['summary-drink-listing__item__info'])}>
        <h4>{item.brand || item.subtype?.name}</h4>
        <h5>{item.container.name || item.product?.name}</h5>
      </div>

      <div
        className={classnames(styles['summary-drink-listing__item__wrapper'])}
      >
        {!isOutro && (
          <Button
            aria-label="Decrease"
            onClick={() => onDiaryDecrease(item.uuid)}
            noBorder={true}
            disabled={
              item.drinkAmounts.individualAmount != null &&
              item.drinkAmounts.individualAmount <= 1
            }
          >
            <Icon icon={IconType.Decrease} size={36} />
          </Button>
        )}

        <div
          className={classnames(styles['summary-drink-listing__item__drink'])}
        >
          <div className={styles['summary-drink-listing__item__grid']}>
            {isContainer ? (
              <ContainerDrinkListingGrid item={item} />
            ) : (
              <ProductDrinkListingGrid item={item} />
            )}
          </div>
        </div>

        {!isOutro && (
          <Button
            aria-label="Increase"
            onClick={() => onDiaryIncrease(item.uuid)}
            noBorder={true}
          >
            <Icon icon={IconType.Increase} size={36} />
          </Button>
        )}
      </div>
    </div>
  )
}
