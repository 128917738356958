import React from 'react'
import styles from './ProgressAnalysis.module.scss'
import type { SpecialisedAnalysisComponentProps } from 'components/ra-dashboard/Analysis/Analysis.model'
import { Analysis } from 'components/ra-dashboard/Analysis/Analysis.component'

export const ProgressAnalysis: React.FC<SpecialisedAnalysisComponentProps> = (
  props
) => {
  const { title, values, sectionIndex } = props

  return (
    <Analysis
      title={title}
      values={values}
      sectionIndex={sectionIndex}
      renderQualifier="progress"
      drawProgressBar={true}
      stylingClasses={{
        mainDiv: styles['progress-analysis__section'],
        wrapperDiv: styles['progress-analysis__section__wrapper'],
        labels: styles['progress-analysis__section__labels'],
        progressBar: styles['progress-analysis__progress']
      }}
    />
  )
}
