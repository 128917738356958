import React, { useContext, useEffect, useId, useState } from 'react'
import classnames from 'classnames'
import {
  MultipleChoiceModel,
  MultipleChoicePropsModel
} from './MultipleChoice.model'
import styles from './MultipleChoice.module.scss'
import Box from '@mui/material/Box'
import { OptionalButtons } from 'components/OptionalButtons/OptionalButtons.component'
import store from 'store/store'
import { AudioContext } from 'context/AudioContext'
import { isString } from 'shared/types/guards'
import { useColourPicker } from 'context/ColourContext'
import { surveyResponseValueToArray } from 'shared/utils/surveyDataConverter/surveyDataConverter'
import { MultipleChoiceQuestionsMarkup } from './MultipleChoiceQuestionsMarkup/MultipleChoiceQuestionsMarkup.component'
import { matchCondition } from 'shared/utils/matchCondition/matchCondition'

const CONST_OPTION_STYLES = {
  withIllustration: {
    'display': 'flex',
    'flexDirection': 'column',
    'gridColumn': '1 / 3',
    'justifyContent': 'center',
    'gridTemplateColumns': `repeat(8, 1fr)`,
    'height': 'calc(100vh - 355px)'
  },
  withIllustrationAndOptional: {
    'display': 'flex',
    'flexDirection': 'column',
    'gridColumn': '1 / 3',
    'justifyContent': 'center',
    'gridTemplateColumns': `repeat(8, 1fr)`,
    'height': 'calc(100vh - 430px)'
  }
} as const

export const MultipleChoice = <TOptional = string,>(
  props: MultipleChoicePropsModel<TOptional>
): React.JSX.Element => {
  const {
    className,
    title,
    subtitle,
    options,
    isMultiple: multiple,
    columnNum = 4,
    defaultValue,
    setValue,
    isCarousel,
    optionalButtons,
    onOptionalButtonSelect,
    vertical,
    withTick = true,
    image,
    altText,
    sideLayout,
    defaultImage,
    borderType,
    leftAligned,
    optionsDisabled,
    dependingScreen
  } = props

  const { secondaryColour } = useColourPicker()
  const { playAudio } = useContext(AudioContext)
  const { displayConditionsMatched } = matchCondition()

  const [validOptions, setValidOptions] = useState<MultipleChoiceModel[]>([])
  const [dataLength, setDataLength] = useState<number>(0)
  const [imageUrl, setImageUrl] = useState<string | undefined>(defaultImage)
  const [selected, setSelected] = useState<string>('')
  const [resetOptionalButtons, setResetOptionalButtons] =
    useState<boolean>(false)

  const id = useId()

  const handleOptionalButtonClick = (value: TOptional) => {
    if (onOptionalButtonSelect) {
      onOptionalButtonSelect(value)
    } else {
      const valueToSet = isString(value) ? value : String(value)
      setValue([valueToSet])
    }

    const otherOptions = [...validOptions]
    otherOptions.forEach((option) => (option.selected = false))
  }

  const onItemClick = (index: number, exclusive?: boolean) => {
    if (optionsDisabled) {
      return false
    }
    setResetOptionalButtons(!resetOptionalButtons)
    const updateOptions = [...validOptions]
    updateOptions[index].selected = multiple
      ? !updateOptions[index].selected
      : true

    if (!multiple || exclusive) {
      updateOptions.forEach((option, optionIndex) => {
        if (optionIndex !== index) {
          option.selected = false
        }
        return updateOptions
      })
    }

    if (multiple && !exclusive) {
      updateOptions.forEach((option) => {
        if (option.isExclusive === 1) {
          option.selected = false
        }
        return updateOptions
      })
    }

    // Play option audio
    if (
      updateOptions[index]?.voices &&
      updateOptions[index]?.id &&
      // Do not play if is multiple and the currently selected option is clicked
      !(multiple && !validOptions[index]?.selected)
    ) {
      playAudio?.(`${updateOptions[index]?.id}-option`)
    }

    setValue(
      updateOptions
        .filter((option) => option.selected)
        .map((validItem) => validItem.value)
    )
    setValidOptions(updateOptions)
  }

  useEffect(() => {
    const selectedOption: MultipleChoiceModel[] = validOptions.filter(
      (option) => option.selected === true
    )
    let dynamicImageUrl: string | undefined
    if (selectedOption[0]) {
      setSelected(selectedOption[0].label)
      dynamicImageUrl = selectedOption[0].image
        ? selectedOption[0].image
        : defaultImage
    } else {
      dynamicImageUrl = defaultImage
    }
    setImageUrl(dynamicImageUrl)
  }, [defaultImage, validOptions])

  useEffect(() => {
    setDataLength(validOptions.length)
    if (defaultValue) {
      const defaultOptions = [...validOptions]
      defaultOptions.forEach((option) => {
        if (defaultValue.includes(option.value)) {
          option.selected = true
        }
        return option
      })
    }
  }, [defaultValue, validOptions])

  useEffect(() => {
    if (options) {
      let optionsModified = options
        .filter((item) => item.isActive !== 0)
        .filter((item) =>
          displayConditionsMatched(item.displayConditions ?? [])
        )
        .map((item) =>
          item.selected === undefined ? { ...item, selected: false } : item
        )

      const reduxValues = store.getState().surveyData
      if (dependingScreen && dependingScreen.length > 0 && reduxValues) {
        let dependingValues: string[] = []

        dependingScreen.forEach((screen) => {
          if (reduxValues[screen]) {
            const arr = surveyResponseValueToArray(reduxValues[screen])
            dependingValues = dependingValues.concat(arr)
          }
        })
        optionsModified = optionsModified.filter((item) =>
          dependingValues.includes(item.value)
        )
      }

      setValidOptions(optionsModified)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options])

  const optionsStyles = {
    'gridTemplateColumns': `repeat(${columnNum * 2}, 1fr)`
  }

  const optionsWithCarouselStyles = {
    'gridTemplateColumns': `repeat(${
      dataLength > columnNum ? dataLength * 2 : columnNum * 2
    }, 1fr)`
  }

  /**  Sets grid col based on columnNum prop if withCarousel is false,
    otherwise to no. of options x 2 */
  const optionStyleCondition = () => {
    if (vertical) {
      if (optionalButtons) {
        return CONST_OPTION_STYLES.withIllustrationAndOptional
      }
      return CONST_OPTION_STYLES.withIllustration
    }
    if (isCarousel) {
      return optionsWithCarouselStyles
    }
    return optionsStyles
  }

  /**
   * Determines whether the markup (provided by the {@link MultipleChoiceQuestionsMarkup}
   * component) should be rendered as a carousel or not, done by checking whether the
   * `isCarousel` prop is set to `true`, and whether the length of `validOptions` is
   * greater than 6.
   *
   * Whilst theoretically the `isCarousel` prop should determine whether to render the
   * contents as a carousel or not, in the app's current implementation, this is not the
   * only logic which is used to determine if the contents of the {@link MultipleChoice}
   * component should be wrapped in a carousel.
   *
   * Perhaps ideally, some of this logic should be changed on the Umbraco side, however,
   * using this function to check whether to wrap the contents of this component in a
   * carousel provides minimal friction with data already available in Umbraco.
   *
   * `true` if the contents of this component should be wrapped in a carousel,
   * `false` otherwise.
   */
  const markupIsCarousel = validOptions.length > 6 && isCarousel

  // Not rendering if no options available
  if (validOptions.length === 0) {
    return <></>
  }

  const markup = (
    <MultipleChoiceQuestionsMarkup
      validOptions={validOptions}
      columnNum={columnNum}
      dataLength={dataLength}
      multipleChoiceId={id}
      onItemClick={onItemClick}
      multipleSelections={multiple}
      vertical={vertical}
      multipleChoiceIsCarousel={isCarousel}
      wrapInCarouselComponent={markupIsCarousel}
      leftAligned={leftAligned}
      withTick={withTick}
      borderType={borderType}
      optionsDisabled={optionsDisabled}
    />
  )

  return (
    <div
      className={classnames(styles.multiplechoice, className, {
        [styles['multiplechoice--side']]: sideLayout
      })}
    >
      <div className={styles['multiplechoice__body']}>
        {!vertical && title && (
          <h1
            className={classnames(
              'screen-titles--title',
              styles['multiplechoice__title']
            )}
            dangerouslySetInnerHTML={{ __html: title }}
          />
        )}
        {subtitle && (
          <p
            className={classnames(
              'screen-titles--subtitle',
              styles['multiplechoice__subtitle']
            )}
            dangerouslySetInnerHTML={{ __html: subtitle }}
          />
        )}
        <div
          className={classnames(
            styles['multiplechoice__wrapper'],
            'wrapper-style',
            {
              'no-padding': isCarousel && validOptions.length > 6,
              [styles['multiplechoice__wrapper--top-space']]:
                title &&
                validOptions.length > columnNum &&
                (title.length > 100 || (title.length > 100 && subtitle))
            }
          )}
          style={{ marginTop: subtitle ? '-50px' : undefined }}
        >
          <Box
            className={classnames(
              styles['multiplechoice__container'],
              'box-style'
            )}
            sx={{
              display: 'grid',
              gridTemplateColumns: `repeat(${
                isCarousel ? 5 : columnNum * 2
              }, 1fr)`
            }}
          >
            {markupIsCarousel ? (
              <div className={styles['multiplechoice__slider-container']}>
                {markup}
              </div>
            ) : (
              <>
                <div
                  className={classnames(
                    styles['multiplechoice__options'],
                    'option-style'
                  )}
                  style={optionStyleCondition()}
                >
                  {markup}
                </div>
                {vertical && (
                  <div
                    className={
                      optionalButtons
                        ? styles['multiplechoice__illustration--optional']
                        : styles['multiplechoice__illustration']
                    }
                  >
                    <img
                      src={imageUrl}
                      alt={`Image for ${selected || 'Default Image'}`}
                    />
                  </div>
                )}
              </>
            )}
          </Box>
          {optionalButtons && (
            <OptionalButtons
              optionalButtons={optionalButtons}
              color={secondaryColour}
              reset={resetOptionalButtons}
              onButtonSelect={handleOptionalButtonClick}
              style={vertical ? { marginTop: '24px' } : undefined}
            />
          )}
        </div>
      </div>
      {!!image && !isCarousel && sideLayout && !vertical && (
        <div className={styles['multiplechoice__image']}>
          <img src={image} alt={altText || `Image for ${title}`} />
        </div>
      )}
    </div>
  )
}
