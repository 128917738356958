import type { DeepReadonly } from 'shared/types/general'
import type {
  ProjectDataModel,
  ProjectSiteTargetCollectionModel,
  SiteTargetCollectionModel
} from '../api/client.model'

export const mapProjectSiteTargetCollection = (
  projects: DeepReadonly<ProjectDataModel[]>
): DeepReadonly<ProjectSiteTargetCollectionModel>[] => {
  const projectSiteTargetCollection: DeepReadonly<ProjectSiteTargetCollectionModel>[] =
    []

  for (const project of projects) {
    const { id, name, sites } = project
    const siteTargetCollection: DeepReadonly<SiteTargetCollectionModel>[] = []
    if (sites && sites.length > 0) {
      for (const site of sites) {
        const siteTarget: DeepReadonly<SiteTargetCollectionModel> = {
          id: site.id,
          name: site.name,
          targets: site.targets
        }
        siteTargetCollection.push(siteTarget)
      }
    }

    projectSiteTargetCollection.push({
      id,
      name,
      sites: siteTargetCollection
    })
  }
  return projectSiteTargetCollection
}
