import type {
  DisplayScreenConditionModel,
  FollowUpQuestionConditionModel,
  FollowUpQuestionModel,
  NextScreenConditionModel,
  Stratification,
  SurveyDataScreenModel
} from 'api/client.model'
import { Operator } from 'api/client.model'
import store from 'store/store'
import type { ISurveyData, ISurveyDataType } from 'store/type'
import type { MatchCondition } from './matchCondition.model'
import { surveyResponseValueToArray } from 'shared/utils/surveyDataConverter/surveyDataConverter'
import type { DeepReadonly } from 'shared/types/general'

export const matchCondition = (): MatchCondition => {
  const isMatchCondition = (
    condition:
      | NextScreenConditionModel
      | FollowUpQuestionConditionModel
      | Stratification,
    orgValue: ISurveyDataType
  ) => {
    let value = orgValue
    if (orgValue && typeof orgValue === 'string' && orgValue.includes('[', 0)) {
      value = JSON.parse(orgValue)
    }

    if (Array.isArray(value)) {
      if (value.length === 0) {
        return false
      }

      let testArray: string[]
      const typeTestValue = value[0]
      if (typeof typeTestValue === 'number') {
        const min = value[0] as number
        const max = value[1] as number
        testArray = Array.from({ length: max - min + 1 }, (_, i) =>
          (i + min).toString()
        )
      } else {
        testArray = value as string[]
      }

      // Filter the array to exclude the element(s) used as a button
      const filteredTestArray: string[] = testArray.filter(
        (opt) => !opt.startsWith('action-button-')
      )

      switch (true) {
        case condition.operator === Operator.LONGER_THAN &&
          filteredTestArray.length > Number(condition.value):
          return true
        case condition.operator === Operator.SHORTER_THAN &&
          filteredTestArray.length < Number(condition.value):
          return true
        case condition.operator === Operator.CONTAIN &&
          testArray.includes(condition.value.toString()):
          return true
        case condition.operator === Operator.NOT_CONTAIN &&
          !testArray.includes(condition.value.toString()):
          return true
        case condition.operator === Operator.EQUAL_TO &&
          condition.value != 'null' &&
          filteredTestArray.length === 1 &&
          filteredTestArray[0] === condition.value.toString():
          return true
        case condition.operator === Operator.NOT_EQUAL_TO &&
          condition.value != 'null' &&
          (testArray.length > 1 || testArray[0] != condition.value.toString()):
          return true
        case condition.operator === Operator.EQUAL_TO &&
          condition.value === 'null' &&
          (testArray.length === 0 || !testArray):
          return true
        case condition.operator === Operator.NOT_EQUAL_TO &&
          condition.value === 'null' &&
          testArray &&
          testArray.length > 0:
          return true
      }
    } else {
      // Stringify other type of values if not null or undefined
      if (value !== null && value !== undefined) {
        value = value.toString()
      }

      switch (true) {
        case condition.operator === Operator.EQUAL_TO &&
          condition.value != 'null' &&
          condition.value === value:
          return true
        case condition.operator === Operator.EQUAL_TO &&
          condition.value === 'null' &&
          (value === 'null' || !value):
          return true
        case condition.operator === Operator.NOT_EQUAL_TO &&
          condition.value != 'null' &&
          condition.value != value:
          return true
        case condition.operator === Operator.NOT_EQUAL_TO &&
          condition.value === 'null' &&
          value &&
          value != 'null':
          return true
        case condition.operator === Operator.GREATER_THAN &&
          Number(value) > Number(condition.value):
          return true
        case condition.operator === Operator.GREATER_AND_EQUAL &&
          Number(value) >= Number(condition.value):
          return true
        case condition.operator === Operator.LESS_THAN &&
          Number(value) < Number(condition.value):
          return true
        case condition.operator === Operator.LESS_AND_EQUAL &&
          Number(value) <= Number(condition.value):
          return true
      }
    }
    return false
  }

  /**
   * Checks whether any one of the conditions in the passed `displayConditions` array has
   * been matched based on existing survey data, effectively acting as a Boolean "OR"
   * check.
   *
   * Each condition may, in a addition, have multiple conditions which must **all** be
   * satisfied (acting as a Boolean "AND" check), which is taken into account when
   * evaluating whether the provided conditions have been satisfied.
   *
   * @param displayConditions - An array of conditions to check which are satisfied, based
   * on values existing in survey data.
   * @param surveyData - Optionally, the survey data to use when evaluating whether the
   * passed conditions are true. If this is not passed as a parameter, the function will
   * source the existing survey data from Redux by default, if available.
   *
   * @returns `true` if *any* condition passed in the array of `displayConditions` is
   * satisfied, `false` otherwise.
   *
   * Note that an empty array passed to `displayConditions` implies there are no
   * conditions to check - in this scenario, the function will always return `true`.
   */
  const displayConditionsMatched = (
    displayConditions: DeepReadonly<DisplayScreenConditionModel[]>,
    surveyData?: DeepReadonly<ISurveyData>
  ): boolean => {
    if (displayConditions.length === 0) {
      // No conditions implies all conditions are matched
      //
      // NB - somewhat conflicts with the original logic in `checkDisplayScreenCondition`,
      // as an empty `displayConditions` array would imply the condition is `false`, but
      // for now, this is overcome by only calling this function if the conditions array
      // length is > 0
      return true
    }

    // Get survey data from parameter, if passed, otherwise fallback and try to get the
    // survey data from the current Redux store
    const loadedSurveyData = surveyData ?? store.getState().surveyData
    if (loadedSurveyData == null) {
      return true
    }

    let conditionsMatched = false

    for (const condition of displayConditions) {
      const conditionFieldValue = loadedSurveyData[condition.screenSurveyField]

      const currentConditionMatched = isMatchCondition(
        { operator: condition.operator, value: condition.value },
        conditionFieldValue
      )
      if (!currentConditionMatched) {
        // Current condition does not match, try the next one
        continue
      }

      // Condition successfully matched
      // Now, check if there are any "AND" conditions to check
      let andConditionsMatched = true

      if (condition.and != null) {
        for (const andCondition of condition.and) {
          const andConditionFieldValue =
            loadedSurveyData[andCondition.screenSurveyField]

          const currentAndConditionMatched = isMatchCondition(
            { operator: andCondition.operator, value: andCondition.value },
            andConditionFieldValue
          )
          if (!currentAndConditionMatched) {
            andConditionsMatched = false
            break
          }
        }
      }

      // If the condition, including and possible "AND" conditions is satisfied, stop
      // looping (mimicking a short-circuiting Boolean "OR" operator)
      if (andConditionsMatched) {
        conditionsMatched = true
        break
      }
    }

    return conditionsMatched
  }

  const checkScreenDisplayCondition = (
    screenId?: string,
    screens?: DeepReadonly<SurveyDataScreenModel[]>,
    surveyData?: ISurveyData
  ): string | undefined => {
    if (!screens || !surveyData) return screenId
    let finalScreenId = screenId

    const screen = screens.find((item) => item.id == screenId)

    if (
      screen &&
      screen.displayScreenCondition &&
      screen.displayScreenCondition.length > 0
    ) {
      const matchScreenConditions = displayConditionsMatched(
        screen.displayScreenCondition,
        surveyData
      )

      // recursive call to check next screen until it matches condition/no condition
      if (!matchScreenConditions) {
        finalScreenId = checkScreenDisplayCondition(
          screen.nextScreen?.nextScreenId,
          screens,
          surveyData
        )
      }
    }
    return finalScreenId
  }

  const getNextScreenId = (
    value?: ISurveyDataType,
    conditions?: DeepReadonly<NextScreenConditionModel[]>,
    defaultScreenId?: string
  ) => {
    const storeData = store.getState()
    const { survey, surveyData } = storeData
    const screens = survey?.screens

    let result = defaultScreenId

    if (
      value !== null &&
      value !== undefined &&
      conditions &&
      conditions.length > 0
    ) {
      conditions.some((condition) => {
        if (isMatchCondition(condition, value)) {
          result = condition.nextScreenId
          return true
        }
      })
    }

    return checkScreenDisplayCondition(result, screens, surveyData)
  }

  const showFollowUpQuestion = (
    value: ISurveyDataType,
    followUpQuestion?: FollowUpQuestionModel
  ) => {
    let result = false

    const mainConditions: FollowUpQuestionConditionModel[] =
      followUpQuestion?.conditions || []

    const additionalConditions: FollowUpQuestionConditionModel[] =
      followUpQuestion?.additionalQuestions?.flatMap(
        (questions): FollowUpQuestionConditionModel[] =>
          questions?.conditions || []
      ) || []

    // Hotfix: https://github.com/sydney-uni-ict/Drug_App/issues/9
    const multipleChoice = followUpQuestion?.data?.multipleChoice || null

    const conditions: FollowUpQuestionConditionModel[] = [
      ...mainConditions,
      ...additionalConditions
    ]

    if (conditions && conditions.length > 0) {
      result = conditions.some((condition) =>
        isMatchCondition(condition, value)
      )
    } else if (multipleChoice && multipleChoice.isMultiple === 1) {
      result = true
      const reduxValues = store.getState().surveyData
      const dependingScreen = multipleChoice.dependingScreen
      if (dependingScreen && dependingScreen.length > 0 && reduxValues) {
        let dependingValues: string[] = []
        dependingScreen.forEach((screen: any) => {
          if (reduxValues[screen]) {
            const arr = surveyResponseValueToArray(reduxValues[screen])
            dependingValues = dependingValues.concat(arr)
          }
        })
        //console.log('dependingScreen', dependingScreen)
        const filteredOptions = multipleChoice.options?.filter((opt: any) =>
          dependingValues.includes(opt.value)
        )
        if (filteredOptions && filteredOptions.length === 1) {
          result = false
        }
      }
    } else {
      result = true
    }
    //console.log('showFollowUpQuestion result', result)
    return result
  }

  const showAdditionalFollowUpQuestion = (
    value: ISurveyDataType,
    conditions?: FollowUpQuestionConditionModel[]
  ) => {
    let result = false
    if (conditions && conditions.length > 0) {
      result = conditions.some((condition) =>
        isMatchCondition(condition, value)
      )
    } else {
      result = true
    }
    return result
  }

  return {
    isMatchCondition,
    displayConditionsMatched,
    getNextScreenId,
    showFollowUpQuestion,
    showAdditionalFollowUpQuestion
  }
}
