import React from 'react'
import styles from './ProgressTargets.module.scss'
import type { ProgressTargetsProps } from './ProgressTargets.model'
import { RaTargetsType } from 'shared/constants/Constants.d'
import { ProgressAnalysis } from '../ProgressAnalysis/ProgressAnalysis.component'
import { ProjectTargets } from 'components/ra-dashboard/ProjectTargets/ProjectTargets.component'

export const ProgressTargets: React.FC<ProgressTargetsProps> = (props) => {
  const { projectId, siteId, projectSiteTargetCollection } = props

  return (
    <ProjectTargets
      projectId={projectId}
      siteId={siteId}
      projectSiteTargetCollection={projectSiteTargetCollection}
      targetsType={RaTargetsType.OverallProgress}
      analysisComponent={ProgressAnalysis}
      renderQualifier="progress"
      stylingClasses={{
        sectionDiv: styles['progress-targets__analysis']
      }}
    />
  )
}
