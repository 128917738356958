import React from 'react'
import type { AnalysisProps } from './Analysis.model'
import Box from '@mui/material/Box'
import { AnalysisBorderLinearProgress } from './AnalysisBorderLinearProgress/AnalysisBorderLinearProgress.component'

export const Analysis: React.FC<AnalysisProps> = (props) => {
  const {
    title,
    values,
    sectionIndex,
    renderQualifier,
    drawProgressBar,
    stylingClasses
  } = props

  return (
    <div className={stylingClasses?.mainDiv}>
      <p>{title}</p>
      <div className={stylingClasses?.wrapperDiv}>
        {values.map((item, index) => (
          <Box
            key={`${renderQualifier}-analysis-section-${sectionIndex}-item-${index}`}
            sx={{ width: '100%' }}
          >
            <label
              id={`${renderQualifier}-analysis-item-${index}`}
              className={stylingClasses?.labels}
            >
              <span>{item.label}</span>
              <span>{`${item.quantity} / ${item.count}`}</span>
            </label>

            {drawProgressBar && (
              <AnalysisBorderLinearProgress
                className={stylingClasses?.progressBar}
                variant="determinate"
                value={item.count > 0 ? (item.quantity / item.count) * 100 : 0}
                aria-labelledby={`${renderQualifier}-analysis-item-${index}`}
              />
            )}
          </Box>
        ))}
      </div>
    </div>
  )
}
