import React, { useState } from 'react'
import type { Dispatch } from 'redux'
import type { ISurveyMetaData, SurveyState } from 'store/type'
import { connect, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  updateSurvey,
  updateSurveyMetadata,
  updateSelectedProjectSite
} from 'store/reducer'
import styles from './ProjectSelectorScreen.module.scss'
import Box from '@mui/material/Box'
import type { ProjectSelectorScreenComponentProps } from './ProjectSelectorScreen.model'
import { InitialisationScreen } from 'components/ra-dashboard/Initialisation/InitialisationScreen/InitialisationScreen.component'
import { ProjectSiteSelect } from 'components/ProjectSiteSelect/ProjectSiteSelect.component'

const ProjectSelectorScreenComponent: React.FC<
  ProjectSelectorScreenComponentProps
> = (props) => {
  const navigate = useNavigate()
  const dispatch: Dispatch<any> = useDispatch()

  const { service, user, projects } = props

  const [projectId, setProjectId] = useState<string>('')
  const [siteId, setSiteId] = useState<string>('')

  const handleSurveyData = () => {
    const project = projects?.find((item) => item.id === projectId)

    if (!service || projects.length === 0 || !project) {
      return
    }

    // Persists the selected project and site across the application
    dispatch(updateSelectedProjectSite({ projectId, siteId }))
    dispatch(updateSurvey(project.survey))

    // Update Metadata
    const metadata: ISurveyMetaData = {
      surveyId: project.survey?.id,
      serviceId: service.id,
      projectId,
      siteId,
      shopId:
        project.shopId != null && project.shopId !== 0
          ? project.shopId
          : undefined,
      raId: user?.id,
      flaggedIncomplete: false,
      status: 'incomplete'
    }
    dispatch(updateSurveyMetadata(metadata))
    navigate('/ra-dashboard/dashboard')
  }

  if (!service || projects.length === 0) {
    return <></>
  }

  return (
    <InitialisationScreen
      mainWrapper={{
        title: 'Project Selection',
        additionalStylingClasses: styles['projectselectorscreen']
      }}
      card={{
        title: 'Hi!',
        button: {
          text: 'Continue',
          disabledCondition: !(projectId && siteId),
          onClick: handleSurveyData
        }
      }}
    >
      <Box className={styles['projectselectorscreen-select']} component="form">
        <ProjectSiteSelect
          selectedProjectId={projectId}
          selectedSiteId={siteId}
          setSelectedProjectId={setProjectId}
          setSelectedSiteId={setSiteId}
          projects={projects}
          style={{
            marginRight: 20
          }}
        />
      </Box>
    </InitialisationScreen>
  )
}

const mapStateToProps = (state: SurveyState) => ({
  service: state.service,
  user: state.user,
  projects: state.projects ?? []
})

export const ProjectSelectorScreen = connect(mapStateToProps)(
  ProjectSelectorScreenComponent
)
