import React from 'react'
import classnames from 'classnames'
import styles from './DateSquare.module.scss'
import type { DateSquareProps } from './DateSquare.model'
import { Border } from 'components/Border/Border.component'
import { BorderType } from 'components/Border/Border.model'
import { colors } from 'shared/theme/theme'
import { useColourPicker } from 'context/ColourContext'
import Paper from '@mui/material/Paper'

export const DateSquare: React.FC<DateSquareProps> = (props) => {
  const { date, title, styleClasses } = props

  const { secondaryColour } = useColourPicker()

  return (
    <div className={classnames(styles['date-square__date'])}>
      <h3 className={classnames(styles['date-square__title'])}>{title}</h3>

      <div className={styles['date-square__wrapper']}>
        <Border
          type={BorderType.Card1}
          fill={colors.white}
          dropShadow
          withTitle
          titleColor={secondaryColour}
        />
        <Paper
          className={classnames('u-border-style-sketch', styleClasses?.body)}
        >
          <div className={classnames(styles['date-square__month'])}>
            {date.toLocaleString('default', { month: 'long' })}
          </div>

          <div className={classnames(styles['date-square__day'])}>
            {date.toLocaleString('default', { day: 'numeric' })}
          </div>
        </Paper>
      </div>
    </div>
  )
}
