import React from 'react'
import LinearProgress, {
  linearProgressClasses
} from '@mui/material/LinearProgress'
import { styled } from '@mui/material/styles'
import { colors } from 'shared/theme/theme'

export const AnalysisBorderLinearProgress = styled(LinearProgress)(() => ({
  height: 8,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: 'rgba(51, 51, 51, .2)'
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: colors.capePalliser
  }
}))
