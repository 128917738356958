import React, { HTMLAttributes, ReactNode } from 'react'

/**
 * Controls the modal's closing mechanism:
 * - (default): Displays 'Close'/'Confirm' buttons at the bottom.
 * - info: Enables closing via a top-right cross icon or clicking outside the modal.
 *
 * (More variations have been designed for other pages, to be discussed with the team)
 */
export enum ModalVariation {
  DEFAULT = 'default',
  INFO = 'info'
}

export interface ModalPropsModel extends HTMLAttributes<HTMLElement> {
  /** Open modal or not */
  open: boolean

  /** Title of the Modal */
  title?: string

  /** Title Color of the Modal */
  titleColor?: string

  /** If to show close icon on the top right cornor */
  closeIcon?: boolean

  /** If to show actions section */
  hasActions?: boolean

  /** Custom action buttons */
  buttons?: ReactNode

  /** Invalid to Conform */
  invalid?: boolean

  /** On modal close function */
  customClose?: (cancel?: boolean) => void

  /**
   * Variation passed down from 'ModalScreen'
   *
   * @default ModalVariation.DEFAULT
   */
  variation?: ModalVariation
}
