import React from 'react'
import classnames from 'classnames'
import styles from './FollowUpBodyImages.module.scss'
import type { FollowUpBodyImagesProps } from './FollowUpBodyImages.model'

export const FollowUpBodyImages: React.FC<FollowUpBodyImagesProps> = (
  props
) => {
  const { symptoms, selectedSymptom, wigglingKey, onSymptomClick } = props

  return (
    <>
      {Object.keys(symptoms).map((key) => (
        <button
          key={`follow-up-body-option-${key}`}
          className={classnames(
            styles['follow-up-body-images__image--circle'],
            styles[`follow-up-body-images__image--${key}`],
            {
              [styles['follow-up-body-images__image--selected']]:
                selectedSymptom?.iconImage ===
                symptoms[key as keyof typeof symptoms].iconImage,
              [styles['follow-up-body-images__image--circle--wiggle']]:
                wigglingKey === key && !selectedSymptom
            }
          )}
          aria-label={key}
          onClick={() => onSymptomClick(key as keyof typeof symptoms)}
        >
          <img
            src={symptoms[key as keyof typeof symptoms].iconImage}
            alt={key}
          />
        </button>
      ))}
    </>
  )
}
