import React, { useEffect, useState } from 'react'
import type { Dispatch } from 'redux'
import type { ISurveyMetaData, SurveyResponse, SurveyState } from 'store/type'
import { connect, useDispatch } from 'react-redux'
import { Button } from 'components/Button/Button.component'
import { useNavigate } from 'react-router-dom'
import {
  updateContinueLocalIdentifier,
  updateSurvey,
  updateSurveyMetadata
} from 'store/reducer'
import styles from './DashboardScreen.module.scss'
import Box from '@mui/material/Box'
import type { DashboardScreenComponentProps } from './DashboardScreen.model'
import { SideMenu } from 'components/ra-dashboard/SideMenu/SideMenu.component'
import classnames from 'classnames'
import { IconType } from 'components/Icons/Icon.model'
import { colors } from 'shared/theme/theme'
import { RADashCalc } from 'shared/utils/raDashboardCalculation/raDashboardCalculation'
import { Card } from 'components/Card/Card.component'
import { RADashboardMenu } from '../index.model'
import { Modal } from 'components/Modals/Modal/Modal.component'
import { Icon } from 'components/Icons/Icon.component'
import { DailyFeedback } from 'components/ra-dashboard/Home/DailyFeedback/DailyFeedback.component'
import { BorderType } from 'components/Border/Border.model'
import { genericObject } from 'screens/survey/type'
import { surveyAPI } from 'api/client'
import { syncSurveysAPI } from 'store/actionCreators'
import { mapProjectSiteTargetCollection } from 'store/mapper'
import { ProjectSiteSelect } from 'components/ProjectSiteSelect/ProjectSiteSelect.component'
import { Snackbar } from 'components/Snackbar/Snackbar.component'
import { SUCCESSFULLY_SYNCED } from 'shared/constants/messages/Messages.d'
import { DashboardTargets } from 'components/ra-dashboard/Home/DashboardTargets/DashboardTargets.component'

const DashboardScreenComponent: React.FC<DashboardScreenComponentProps> = (
  props
) => {
  const navigate = useNavigate()
  const dispatch: Dispatch<any> = useDispatch()

  const { calcTotalResponses } = RADashCalc()
  const { handleFeedbackSubmit } = surveyAPI()

  const {
    offline,
    user,
    serviceId,
    projects,
    projectSiteTargets,
    surveyId,
    isEnableSLK,
    projectId: selectedProjectId,
    siteId: selectedSiteId,
    surveyResponses,
    syncRounds,
    syncStatus,
    surveyMetaData
  } = props

  const [projectId, setProjectId] = useState<string>(selectedProjectId)
  const [siteId, setSiteId] = useState<string>(selectedSiteId)
  const [isSync, setIsSync] = useState<boolean>(false)
  const [newSurveyModalOpen, setNewSurveyModalOpen] = useState<boolean>(false)
  const [showFeedbackModal, setShowFeedbackModal] = useState<boolean>(false)
  const [feedbackState, setFeedbackState] = useState<genericObject>()
  const [feedbackDate, setFeedbackDate] = useState<string>()
  const [feedbackProject, setFeedbackProject] = useState<string>()
  const [feedbackSite, setFeedbackSite] = useState<string>()
  const [feedbackError, setFeedbackError] = useState<string>()
  const [feedbackSubmitting, setFeedbackSubmitting] = useState<boolean>(false)
  const [feedbackSaved, setFeedbackSaved] = useState<boolean>(false)
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false)

  // Map a projectSiteTargets instance from project if the state.projectSiteTargets does not exist
  const projectSiteTargetCollection =
    projectSiteTargets.length > 0
      ? projectSiteTargets
      : mapProjectSiteTargetCollection(projects)

  // Retrieve all the survey responses by filters from the Redux store.
  const totalSurveys = calcTotalResponses(projectId, siteId, true, user?.id)

  // The completed survey responses that have been collected.
  const totalCompletedSurveys = totalSurveys.filter(
    (survey) =>
      !survey.metadata?.flaggedComingBack &&
      !survey.metadata?.flaggedDeleted &&
      !survey.metadata?.flaggedIncomplete &&
      survey.metadata?.status !== 'invalidSLK'
  )

  // The unsynced completed survey responses that have been collected.
  const unsyncedCompletedSurveys = totalCompletedSurveys.filter(
    (res) => res.responses && res.metadata && res.metadata.status !== 'synced'
  )

  // Currently non-Indigenous people are considered ineligible. (Issue #435)
  // TODO: this is a temporally implementation to filter out the ineligible responses.
  const eligibleUnsyncedCompletedSurveys = unsyncedCompletedSurveys.filter(
    (res) => res.responses && res.responses['indigenous'] != 'non-indigenous'
  )

  const sortedEligibleSurveys = structuredClone(
    eligibleUnsyncedCompletedSurveys
  ).sort(function (a, b) {
    if (!a.metadata || !b.metadata) return 0
    if (!a.metadata.slk || !b.metadata.slk) return 0
    if (!a.metadata.endTime || !b.metadata.endTime)
      return a.metadata.slk.localeCompare(b.metadata.slk)

    // Sort by slk (ascending) then endTime (ascending)
    return (
      a.metadata.slk.localeCompare(b.metadata.slk) ||
      a.metadata.endTime.getTime() - b.metadata.endTime.getTime()
    )
  })

  // Counting down of recruited completed participants; SLKs not being double counted (PR#561)
  const uniqueEligibleSurveys = sortedEligibleSurveys.reduce<SurveyResponse[]>(
    (acc, cv) => {
      if (
        cv.metadata?.slk &&
        !acc.find((x) => x.metadata?.slk === cv.metadata?.slk)
      ) {
        acc.push(cv)
      }
      return acc
    },
    []
  )

  const handleSurveyAction = (isContinue: boolean) => {
    // Update Survey if Project changes
    const project = projects.find((item) => item.id === projectId)
    const survey = project?.survey
    if (survey && (!surveyId || surveyId !== survey.id)) {
      dispatch(updateSurvey(survey))
    }

    const metadata: ISurveyMetaData = {
      ...surveyMetaData,
      serviceId,
      projectId,
      siteId,
      shopId:
        project?.shopId != null && project?.shopId !== 0
          ? project?.shopId
          : undefined,
      raId: user?.id,
      flaggedIncomplete: false,
      startTime: new Date(),
      status: 'incomplete',
      isEnableSLK: !!isEnableSLK
    }

    dispatch(updateSurveyMetadata(metadata))

    if (!isEnableSLK) {
      dispatch(updateContinueLocalIdentifier(undefined))

      navigate('/survey')
    } else if (isContinue) {
      navigate('/enter-participants-slk/continue')
    } else {
      navigate('/enter-participants-slk')
    }
  }

  const handleSyncAPI = () => {
    setIsSync(true)
    // Create a clean projectSiteTargetsRequest without the numbers of collections populated
    const projectSiteTargetsRequest = mapProjectSiteTargetCollection(projects)
    dispatch(
      syncSurveysAPI({
        serviceId,
        surveyResponses,
        projectSiteTargets: projectSiteTargetsRequest
      })
    )

    setOpenSnackbar(true)
  }

  const handleMenuChange = (menu: string) => {
    navigate(`/ra-dashboard/${menu}`)
  }

  const handleFeedbackFormSubmit = () => {
    if (!feedbackDate || !feedbackProject || !feedbackSite || !feedbackState) {
      setFeedbackError('Please fill in the required field')
      return
    }

    setFeedbackSubmitting(true)

    handleFeedbackSubmit({
      ...feedbackState,
      Date: feedbackDate,
      ProjectId: feedbackProject,
      SiteId: feedbackSite,
      RaId: user?.id,
      SerivceId: serviceId
    })
      .then((response) => {
        if (response) {
          setShowFeedbackModal(false)
          setFeedbackSaved(true)
        } else {
          setFeedbackError('Submit failed. Please try again')
        }
        setFeedbackSubmitting(false)
      })
      .catch((err) => {
        setFeedbackError(err)
        setFeedbackSubmitting(false)
      })
  }

  useEffect(() => {
    setIsSync(false)
  }, [syncRounds, surveyResponses, projectId, siteId])

  return (
    <div className={styles.dashboardscreen}>
      <SideMenu
        options={RADashboardMenu}
        value={RADashboardMenu[0].value}
        setValue={handleMenuChange}
      />
      <main className={styles['dashboardscreen-content']}>
        <h1>
          Hello, {user ? `${user.firstName} ${user.lastName}` : undefined}
        </h1>
        <div className={styles['dashboardscreen-row1']}>
          <Box
            key={`dashboard-select`}
            component="form"
            className={styles['dashboardscreen-row1-form']}
          >
            <ProjectSiteSelect
              selectedProjectId={projectId}
              selectedSiteId={siteId}
              setSelectedProjectId={setProjectId}
              setSelectedSiteId={setSiteId}
              projects={projects}
              additionalStylingClasses={styles['dashboardscreen-select']}
              borderType={BorderType.Select2}
              style={{
                marginRight: 20,
                backgroundColor: colors.white
              }}
            />
          </Box>
          {offline ? (
            <Button
              className={styles['dashboardscreen-row1-syncButton']}
              variation="primary"
              disabled
              icon={IconType.NotSync}
              style={{ backgroundColor: colors.white }}
            >
              Sync
            </Button>
          ) : (
            <Button
              className={classnames(styles['dashboardscreen-row1-syncButton'], {
                [styles['dashboardscreen-row1-syncButton--loading']]: isSync
              })}
              onClick={handleSyncAPI}
              icon={IconType.Sync}
              borderType={BorderType.Option1}
              aria-label="sync"
              style={{
                backgroundColor: isSync ? colors.greenSmoke : colors.white
              }}
            >
              {!isSync && 'Sync'}
            </Button>
          )}
          {!isSync && (
            <Snackbar
              message={
                syncStatus && syncStatus.isSynced
                  ? SUCCESSFULLY_SYNCED
                  : syncStatus?.errorMessage || ''
              }
              isError={(syncStatus && !syncStatus.isSynced) || false}
              open={openSnackbar}
              setOpen={setOpenSnackbar}
            ></Snackbar>
          )}
        </div>
        <div className={styles['dashboardscreen-row2']}>
          <Button
            className={styles['dashboardscreen-row2-card']}
            icon={IconType.Add}
            iconPosition="top"
            dropShadow
            onClick={() => setNewSurveyModalOpen(true)}
            borderType={BorderType.Option1}
            style={{
              backgroundColor: colors.greenSmoke
            }}
          >
            Start a survey
          </Button>
          {isEnableSLK && (
            <Button
              className={styles['dashboardscreen-row2-card']}
              icon={IconType.Continue}
              iconPosition="top"
              dropShadow
              borderType={BorderType.Option1}
              onClick={() => handleSurveyAction(true)}
              style={{
                backgroundColor: colors.parisWhite
              }}
            >
              Continue a survey
            </Button>
          )}
          <Button
            className={styles['dashboardscreen-row2-card']}
            icon={IconType.Feedback}
            iconPosition="top"
            dropShadow
            borderType={BorderType.Option1}
            style={{
              backgroundColor: colors.botticelli
            }}
            onClick={() => setShowFeedbackModal(true)}
          >
            Daily feedback
          </Button>
          {!feedbackSubmitting && (
            <Snackbar
              message={'Your daily feedback has been saved, thanks!'}
              isError={false}
              open={feedbackSaved}
              setOpen={setFeedbackSaved}
            ></Snackbar>
          )}
        </div>
        <div className={styles['dashboardscreen-row3']}>
          <p>Surveys I have collected: {uniqueEligibleSurveys.length}</p>
          <Card
            className={styles['dashboardscreen-card']}
            title="People I have collected today"
            titleColor={colors.breakerBay}
            titleHeight={57}
            centerAlign={false}
          >
            <div className={styles['dashboardscreen-analysisWrapper']}>
              <DashboardTargets
                projectId={projectId}
                siteId={siteId}
                projectSiteTargetCollection={projectSiteTargetCollection}
                uniqueEligibleSurveys={uniqueEligibleSurveys}
              />
            </div>
          </Card>
        </div>
      </main>
      {newSurveyModalOpen && (
        <Modal
          className={styles['dashboardscreen-newSurveyModal']}
          open={newSurveyModalOpen}
          customClose={() => setNewSurveyModalOpen(false)}
          title="New Survey"
          titleColor={colors.capePalliser}
          closeIcon={true}
          buttons={<></>}
          style={{ backgroundColor: colors.white }}
        >
          <div className={styles['dashboardscreen-newSurveyModal-content']}>
            <div
              className={styles['dashboardscreen-newSurveyModal-content-info']}
            >
              <Icon icon={IconType.Information} />
              <Icon icon={IconType.Person} />
              <p>This survey will collect data</p>
            </div>
            <p>Please choose a project and site below</p>
            <Box
              key={`dashboard-modal-select`}
              component="form"
              className={styles['dashboardscreen-newSurveyModal-content-form']}
            >
              <ProjectSiteSelect
                selectedProjectId={projectId}
                selectedSiteId={siteId}
                setSelectedProjectId={setProjectId}
                setSelectedSiteId={setSiteId}
                projects={projects}
                additionalStylingClasses={
                  styles['dashboardscreen-newSurveyModal-content-form-select']
                }
              />
              <Button
                disabled={!(projectId && siteId)}
                className={
                  styles[
                    'dashboardscreen-newSurveyModal-content-form-startButton'
                  ]
                }
                borderType={BorderType.Select1}
                onClick={() => handleSurveyAction(false)}
                style={{
                  backgroundColor: colors.greenSmoke
                }}
              >
                Start survey
              </Button>
              <Button
                disabled={!(projectId && siteId)}
                className={
                  styles[
                    'dashboardscreen-newSurveyModal-content-form-cancelButton'
                  ]
                }
                borderType={BorderType.Select1}
                onClick={() => navigate('/ra-dashboard/declined')}
                style={{
                  backgroundColor: colors.chestnutRose
                }}
              >
                Declined to take part
              </Button>
            </Box>
          </div>
        </Modal>
      )}

      {showFeedbackModal && (
        <Modal
          className="dashboardscreen-dailyFeedbackModal"
          open={showFeedbackModal}
          customClose={() => setShowFeedbackModal(false)}
          style={{ backgroundColor: colors.white }}
          closeIcon={true}
          hasActions={false}
          buttons={<> </>}
        >
          <DailyFeedback
            projects={projects}
            feedbackState={feedbackState}
            feedbackSubmitting={feedbackSubmitting}
            feedbackDate={feedbackDate}
            feedbackSite={feedbackSite}
            feedbackError={feedbackError}
            setFeedbackState={setFeedbackState}
            setFeedbackDate={setFeedbackDate}
            setFeedbackSite={setFeedbackSite}
            feedbackProject={feedbackProject}
            setFeedbackProject={setFeedbackProject}
            btnCallBack={handleFeedbackFormSubmit}
          />
        </Modal>
      )}
    </div>
  )
}

const mapStateToProps = (state: SurveyState) => ({
  offline: state.offline || false,
  user: state.user,
  serviceId: state.service?.id,
  isEnableSLK: state.survey?.isEnableSLK,
  projects: state.projects ?? [],
  projectSiteTargets: state.projectSiteTargets ?? [],
  surveyId: state.survey?.id,
  projectId: state.selectedProjectSite?.projectId || '',
  siteId: state.selectedProjectSite?.siteId || '',
  surveyResponses: state.surveyResponses,
  syncRounds: state.syncRounds,
  syncStatus: state.syncStatus,
  surveyMetaData: state.surveyMetaData
})

export const DashboardScreen = connect(mapStateToProps)(
  DashboardScreenComponent
)
