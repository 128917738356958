import React from 'react'
import { Carousel } from 'components/Carousel/Carousel.component'
import { GrogDiary } from 'components/GrogConsumptions/GrogDiary/GrogDiary.component'
import { GrogDiaryPropsModel } from 'components/GrogConsumptions/GrogDiary/GrogDiary.model'
import { Screen } from 'components/Screen/Screen.component'
import {
  BENZOS_AMOUNT,
  BENZOS_TYPE,
  CANNABIS_AMOUNT,
  CANNABIS_TYPE,
  GROG_DIARY,
  HEROIN_HOW_LONG_ONE_UNIT_LAST,
  HEROIN_TYPE,
  METH_DAYS_IN_A_ROW,
  METH_TYPE,
  OPIOIDS_AMOUNT,
  OPIOIDS_TYPE,
  OTHER_TYPE,
  TOBACCO_AMOUNT,
  TOBACCO_TYPE
} from 'shared/constants/SurveyFields.d'
import type {
  OutroCustomLabel,
  UsingAmountImageGridProps,
  UsingAmountScreenPropsModel,
  UsingOption
} from './UsingAmountScreen.model'
import styles from './UsingAmountScreen.module.scss'
import { DynamicSlider } from 'components/DynamicSlider/DynamicSlider.component'
import { SurveyState } from 'store/type'
import type { MultipleChoiceScreenData } from 'screens/survey/MultipleChoiceScreen/MultipleChoiceScreen.model'
import { connect } from 'react-redux'
import { matchCondition } from 'shared/utils/matchCondition/matchCondition'
import type { SurveyDataScreenModel } from 'api/client.model'
import { Grid } from '@mui/material'
import type { DynamicSliderOutroProps } from 'components/DynamicSlider/DynamicSlider.model'
import type { UsingAmountMarkupProps } from 'components/UsingAmountMarkup/UsingAmountMarkup.model'
import { UsingAmountMarkup } from 'components/UsingAmountMarkup/UsingAmountMarkup.component'

/**
 * @param props - containing a string array containing up to two image urls
 * @return React.JSX.Element Return a custom component with the assembly of
 * 1. an image of the drug in question
 * 2. a picture of a calendar icon, with a large number representing days
 */
const UsingAmountImageGrid: React.FC<UsingAmountImageGridProps> = (props) => {
  const { images } = props

  return (
    <Grid container spacing={2} sx={{ flexGrow: 1 }}>
      {images.map((image, idx) => (
        <Grid item xs md={6} key={`using-amount-screen-grid-image-${idx}`}>
          <img src={image} alt={image} />
        </Grid>
      ))}
    </Grid>
  )
}

export const UsingAmountScreenComponent: React.FC<
  UsingAmountScreenPropsModel
> = (props) => {
  const {
    currentScreenId,
    headerProps,
    screenData: { title, subtitle, options, image, customLabels },
    footerProps,
    shortcodes,
    states,
    survey
  } = props

  const { getNextScreenId } = matchCondition()

  const handleFooterProps = () => {
    const updatedFooterProps = { ...footerProps }

    if (updatedFooterProps.nextScreenId) {
      updatedFooterProps.nextScreenId = getNextScreenId(
        undefined,
        footerProps.conditions,
        footerProps.nextScreenId
      )
    }

    return updatedFooterProps
  }

  /**
   * @param val a number of "days in a row", or a string value of "How long does a {unit} last"
   * @return the url of the corresponding calendar image that represents days in a row
   */
  const getOutroCustomLabelImage = (
    val: number | string
  ): string | undefined => {
    if (customLabels && customLabels.length > 0) {
      let customLabel: OutroCustomLabel | undefined
      if (typeof val === 'string') {
        customLabel = customLabels.find((item) => item.stringValue === val)
        return customLabel ? customLabel.image : undefined
      } else {
        customLabel = customLabels.find((item) => item.value === val)
        return customLabel ? customLabel.image : customLabels.at(-1)?.image
      }
    }
    return undefined
  }

  const generateGrogArgs = () => {
    if (states && states[GROG_DIARY]) {
      let grogStates = states[GROG_DIARY]

      if (typeof grogStates === 'string') {
        grogStates = JSON.parse(grogStates)
      }

      const grogKey = Object.keys(grogStates).sort().reverse()[0]

      return {
        date: grogKey,
        consumptions: grogStates[grogKey].consumptions,
        isOutro: true
      } as GrogDiaryPropsModel
    }
  }

  /** Notes:
   * The Outro page logic in generateTobaccoArgs may be affected by the changes of Tobacco flow in the CMS.
   */
  const generateTobaccoArgs = () => {
    if (states && survey && states[TOBACCO_TYPE]) {
      const amount = TOBACCO_AMOUNT.reduce((acc, cv) => {
        if (states[cv]) {
          acc = cv
        }
        return acc
      })

      const tobaccoSmokedMostlyValue = states[TOBACCO_TYPE]
      const selectedTobacco = Array.isArray(tobaccoSmokedMostlyValue)
        ? tobaccoSmokedMostlyValue[0]
        : tobaccoSmokedMostlyValue

      const screenDataList = survey.screens.reduce<SurveyDataScreenModel[]>(
        (acc, cv) => {
          if (cv.surveyField === TOBACCO_TYPE) {
            acc.push(cv)
          }
          return acc
        },
        []
      )

      let imageTobacco = ''

      screenDataList.forEach((screenData: SurveyDataScreenModel) => {
        const multipleChoiceScreenData = screenData.data.multipleChoice
          ? (screenData.data.multipleChoice as MultipleChoiceScreenData)
          : null
        if (multipleChoiceScreenData) {
          multipleChoiceScreenData.options.reduce((acc, cv) => {
            if (cv.value === selectedTobacco) {
              imageTobacco = cv.image as string
            }
            return acc
          }, '')
        }
      })

      return {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        setValue: () => {},
        image: imageTobacco,
        defaultValue: states[amount],
        dynamicImage: true
      }
    }
  }

  const generateCannabisArgs = () => {
    if (states && survey && states[CANNABIS_TYPE]) {
      const amount = CANNABIS_AMOUNT.reduce((acc, cv) => {
        if (states[cv]) {
          acc = cv
        }
        return acc
      })

      const mostlyUsedCannabis = Array.isArray(states[CANNABIS_TYPE])
        ? states[CANNABIS_TYPE][0]
        : states[CANNABIS_TYPE]

      const screenDataList = survey.screens.reduce<SurveyDataScreenModel[]>(
        (acc, cv) => {
          if (cv.surveyField === CANNABIS_TYPE) {
            acc.push(cv)
          }
          return acc
        },
        []
      )

      let imageCannabis = ''

      screenDataList.forEach((screenData: SurveyDataScreenModel) => {
        const multipleChoiceScreenData = screenData.data.multipleChoice
          ? (screenData.data.multipleChoice as MultipleChoiceScreenData)
          : null
        if (multipleChoiceScreenData) {
          multipleChoiceScreenData.options.reduce((acc, cv) => {
            if (cv.value === mostlyUsedCannabis) {
              imageCannabis = cv.image as string
            }
            return acc
          }, '')
        }
      })

      return {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        setValue: () => {},
        image: imageCannabis,
        defaultValue: states[amount],
        dynamicImage: true
      }
    }
  }

  const generateMethArgs = () => {
    // If the value of states[METH_TYPE] is null, please check its Umbraco config
    if (states && survey && states[METH_TYPE] !== undefined) {
      const daysInARow = Number(states[METH_DAYS_IN_A_ROW])
      const mostlyUsedMeth = Array.isArray(states[METH_TYPE])
        ? states[METH_TYPE][0]
        : states[METH_TYPE]

      const screenDataList = survey.screens.reduce<SurveyDataScreenModel[]>(
        (acc, cv) => {
          if (cv.surveyField === METH_TYPE) {
            acc.push(cv)
          }
          return acc
        },
        []
      )

      let imageMeth = ''

      screenDataList.forEach((screenData: SurveyDataScreenModel) => {
        const multipleChoiceScreenData = screenData.data.multipleChoice
          ? (screenData.data.multipleChoice as MultipleChoiceScreenData)
          : null
        if (multipleChoiceScreenData) {
          multipleChoiceScreenData.options.reduce((acc, cv) => {
            if (cv.value === mostlyUsedMeth) {
              imageMeth = cv.image as string
            }
            return acc
          }, '')
        }
      })

      const imageDaysInARow = getOutroCustomLabelImage(daysInARow)

      let customComponent = undefined
      if (imageDaysInARow) {
        const gridImages = imageMeth
          ? [imageMeth, imageDaysInARow]
          : [imageDaysInARow]
        customComponent = <UsingAmountImageGrid images={gridImages} />
      }

      return {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        setValue: () => {},
        image: imageDaysInARow,
        customComponent,
        defaultValue: 1,
        dynamicImage: false,
        isOutro: true
      }
    }
  }

  const generateHeroinArgs = () => {
    if (states && survey && states[HEROIN_TYPE]) {
      const howLongOneUnitLast = String(states[HEROIN_HOW_LONG_ONE_UNIT_LAST])
      const mostlyUsedHeroin = Array.isArray(states[HEROIN_TYPE])
        ? states[HEROIN_TYPE][0]
        : states[HEROIN_TYPE]

      const screenDataList = survey.screens.reduce<SurveyDataScreenModel[]>(
        (acc, cv) => {
          if (cv.surveyField === HEROIN_TYPE) {
            acc.push(cv)
          }
          return acc
        },
        []
      )

      let imageHeroin = ''

      screenDataList.forEach((screenData: SurveyDataScreenModel) => {
        const multipleChoiceScreenData = screenData.data.multipleChoice
          ? (screenData.data.multipleChoice as MultipleChoiceScreenData)
          : null
        if (multipleChoiceScreenData) {
          multipleChoiceScreenData.options.reduce((acc, cv) => {
            if (cv.value === mostlyUsedHeroin) {
              imageHeroin = cv.image as string
            }
            return acc
          }, '')
        }
      })

      const imageHowLongOneUnitLast =
        getOutroCustomLabelImage(howLongOneUnitLast)

      let customComponent = undefined
      if (imageHowLongOneUnitLast) {
        const gridImages = imageHeroin
          ? [imageHeroin, imageHowLongOneUnitLast]
          : [imageHowLongOneUnitLast]
        customComponent = <UsingAmountImageGrid images={gridImages} />
      }

      return {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        setValue: () => {},
        image: imageHowLongOneUnitLast,
        customComponent,
        defaultValue: 1,
        dynamicImage: false,
        isOutro: true
      }
    }
  }

  const generateBenzosArgs = () => {
    if (states && survey && states[BENZOS_AMOUNT]) {
      const amount = states[BENZOS_AMOUNT]
      const mostlyUsedBenzos = Array.isArray(states[BENZOS_TYPE])
        ? states[BENZOS_TYPE][0]
        : states[BENZOS_TYPE]

      const screenDataList = survey.screens.reduce<SurveyDataScreenModel[]>(
        (acc, cv) => {
          if (cv.surveyField === BENZOS_TYPE) {
            acc.push(cv)
          }
          return acc
        },
        []
      )

      let imageBenzos = ''

      screenDataList.forEach((screenData: SurveyDataScreenModel) => {
        const multipleChoiceScreenData = screenData.data.multipleChoice
          ? (screenData.data.multipleChoice as MultipleChoiceScreenData)
          : null
        if (multipleChoiceScreenData) {
          multipleChoiceScreenData.options.reduce((acc, cv) => {
            if (cv.value === mostlyUsedBenzos) {
              imageBenzos = cv.image as string
            }
            return acc
          }, '')
        }
      })

      return {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        setValue: () => {},
        image: imageBenzos,
        defaultValue: amount,
        dynamicImage: true
      }
    }
  }

  /** Notes:
   * The Outro page logic in generateOpioidsArgs may be affected by the changes of Opioids flow in the CMS.
   */
  const generateOpioidsArgs = () => {
    if (states && survey && states[OPIOIDS_AMOUNT]) {
      const amount = states[OPIOIDS_AMOUNT]
      const [mostlyUseWhichOpioids, mostlyUseOpioids] = OPIOIDS_TYPE
      const mostlyUseWhichOpioidsValue = states[mostlyUseWhichOpioids]
      const selectedOpioid = Array.isArray(mostlyUseWhichOpioidsValue)
        ? mostlyUseWhichOpioidsValue[0]
        : mostlyUseWhichOpioidsValue

      const screenDataList = survey.screens.reduce<SurveyDataScreenModel[]>(
        (acc, cv) => {
          if (
            cv.surveyField === mostlyUseWhichOpioids ||
            cv.surveyField === mostlyUseOpioids
          ) {
            acc.push(cv)
          }
          return acc
        },
        []
      )

      let imageOpioids = ''

      screenDataList.forEach((screenData: SurveyDataScreenModel) => {
        const multipleChoiceScreenData = screenData.data.multipleChoice
          ? (screenData.data.multipleChoice as MultipleChoiceScreenData)
          : null
        if (multipleChoiceScreenData) {
          multipleChoiceScreenData.options.reduce((acc, cv) => {
            if (cv.value === selectedOpioid) {
              imageOpioids = cv.image as string
            }
            return acc
          }, '')
        }
      })

      return {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        setValue: () => {},
        image: imageOpioids,
        defaultValue: amount,
        dynamicImage: true
      }
    }
  }

  const generateOtherArgs = () => {
    if (states && survey && states[OTHER_TYPE]) {
      return {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        setValue: () => {},
        image: image,
        defaultValue: states[OTHER_TYPE] ? 1 : undefined,
        dynamicImage: false
      }
    }
  }

  const GrogArgs = generateGrogArgs()
  const TobaccoArgs = generateTobaccoArgs()
  const CannabisArgs = generateCannabisArgs()
  const OpioidsArgs = generateOpioidsArgs()
  const MethArgs = generateMethArgs()
  const HeroinArgs = generateHeroinArgs()
  const BenzosArgs = generateBenzosArgs()
  const OtherArgs = generateOtherArgs()

  const generateUsingAmountComponent = (
    option: UsingOption
  ): React.JSX.Element => {
    if (option === 'grog') {
      return <GrogDiary {...GrogArgs!} />
    }

    let componentProps: Omit<DynamicSliderOutroProps, 'isOutro'>

    switch (option) {
      case 'tobacco':
        componentProps = TobaccoArgs!
        break
      case 'cannabis':
        componentProps = CannabisArgs!
        break
      case 'meth':
        componentProps = MethArgs!
        break
      case 'heroin':
        componentProps = HeroinArgs!
        break
      case 'opioid':
        componentProps = OpioidsArgs!
        break
      case 'benzos':
        componentProps = BenzosArgs!
        break
      case 'other':
        componentProps = OtherArgs!
        break
    }

    return <DynamicSlider {...componentProps} isOutro={true} />
  }

  const generateUsingTypeString = (option: UsingOption): string => {
    let type: string = option

    if (states != null) {
      switch (option) {
        case 'tobacco':
          type = states[TOBACCO_TYPE]
          break
        case 'cannabis':
          type = states[CANNABIS_TYPE]
          break
        case 'meth':
          type = 'ice or meth'
          break
        case 'opioid':
          type = states[OPIOIDS_TYPE[0]] || states[OPIOIDS_TYPE[1]]
          break
        case 'benzos':
          type = states[BENZOS_TYPE]
          break
        case 'other': {
          // Not entirely sure if this is necessary as I'm not sure if
          // `states[OTHER_TYPE]` will every be a string, but at least this is hopefully
          // safe
          let surveyDataOthersTypesArray: string[]
          if (typeof states[OTHER_TYPE] === 'string') {
            surveyDataOthersTypesArray = [states[OTHER_TYPE].replace(/'/g, '"')]
          } else {
            // Assume it is already an array
            surveyDataOthersTypesArray = states[OTHER_TYPE]
          }

          type = surveyDataOthersTypesArray.join(' and ')
          break
        }
        default:
          break
      }
    }

    return type
  }

  const generateMarkupProps = (option: UsingOption): UsingAmountMarkupProps => {
    const usingType = generateUsingTypeString(option)
    const amountDisplayComponent = generateUsingAmountComponent(option)

    return {
      usingType,
      amountDisplayComponent,
      title,
      subtitle,
      shortcodes
    }
  }

  const filteredOptions = options.filter((option) => {
    switch (option) {
      case 'benzos':
        return BenzosArgs && BenzosArgs.image && BenzosArgs.defaultValue
      case 'cannabis':
        return CannabisArgs && CannabisArgs.image && CannabisArgs.defaultValue
      case 'grog':
        return states && states[GROG_DIARY]
      case 'heroin':
        return HeroinArgs && HeroinArgs.image && HeroinArgs.defaultValue
      case 'meth':
        return MethArgs && MethArgs.image && MethArgs.defaultValue
      case 'opioid':
        return OpioidsArgs && OpioidsArgs.image && OpioidsArgs.defaultValue
      case 'other':
        return OtherArgs?.defaultValue != null && OtherArgs?.defaultValue !== 0
      case 'tobacco':
        return TobaccoArgs && TobaccoArgs.image && TobaccoArgs.defaultValue
    }
  })

  const listItems = filteredOptions.map((option, index) => (
    <div key={`using-amount-screen-carousel-item-${index}`}>
      <UsingAmountMarkup {...generateMarkupProps(option)} />
    </div>
  ))

  return (
    <div className={`drug-app-screen ${styles.usingamountscreen}`}>
      <Screen
        currentScreenId={currentScreenId}
        headerProps={headerProps}
        footerProps={handleFooterProps()}
      >
        {options.length > 1 ? (
          <div className={styles['using-amount-screen__container']}>
            <Carousel slides={listItems} slidesToShow={1} />
          </div>
        ) : (
          <UsingAmountMarkup {...generateMarkupProps(filteredOptions[0])} />
        )}
      </Screen>
    </div>
  )
}

const mapStateToProps = (state: SurveyState) => ({
  states: state.surveyData,
  survey: state.survey
})

export const UsingAmountScreen = connect(mapStateToProps)(
  UsingAmountScreenComponent
)
