import React, { useContext, useEffect, useState } from 'react'
import {
  OptionalButton,
  OptionalButtonsPropsModel
} from './OptionalButtons.model'
import styles from './OptionalButtons.module.scss'
import classnames from 'classnames'
import { Button } from 'components/Button/Button.component'
import { Box } from '@mui/material'
import { colors } from 'shared/theme/theme'
import { AudioContext } from 'context/AudioContext'
import { useColourPicker } from 'context/ColourContext'

export const OptionalButtons = <T = string,>(
  props: OptionalButtonsPropsModel<T>
): React.JSX.Element => {
  const {
    className,
    wide,
    reset,
    optionalButtons,
    onButtonSelect,
    ...componentProps
  } = props
  const { secondaryColour } = useColourPicker()
  const { playAudio } = useContext(AudioContext)
  const [selectedIndex, setSelectedIndex] = useState<number>()

  const handleClick = (index: number, item: OptionalButton<T>) => {
    onButtonSelect(item.value)
    setSelectedIndex(index)

    // Play button audio
    if (item?.voices && item?.id) playAudio?.(`${item?.id}-button`)
  }

  const calColumnPosition = (): string | string[] => {
    let centreColumn = '4/6'
    let leftColumn = '3/5'
    let rightColumn = '5/7'

    if (wide) {
      centreColumn = '3/7'
      leftColumn = '2/5'
      rightColumn = '5/8'
    }

    // If there are two buttons and one is in the corner, assign both centreColumn
    if (optionalButtons.length === 2) {
      const oneInCorner = optionalButtons.some((button) => button.isInCorner)
      return oneInCorner ? centreColumn : [leftColumn, rightColumn]
    } else {
      // Default for single button or more than two
      return centreColumn
    }
  }

  const columnPosition = calColumnPosition()

  const getButtonClassName = (index: number) => {
    if (optionalButtons.length === 1) {
      // One button case
      return optionalButtons[0].isInCorner
        ? styles['button-one-button-corner']
        : styles['button-one-button-default']
    } else if (optionalButtons.length === 2) {
      // Two buttons case
      if (optionalButtons.every((button) => button.isInCorner)) {
        return styles['button-two-buttons-two-corner']
      } else if (optionalButtons[index].isInCorner) {
        return styles['button-two-buttons-one-corner']
      } else {
        return styles['button-two-buttons-default']
      }
    } else {
      return styles.default
    }
  }

  useEffect(() => {
    setSelectedIndex(undefined)
  }, [reset, optionalButtons])

  return (
    <div
      className={classnames(className, styles.optionalbuttons)}
      {...componentProps}
    >
      <Box className={styles.gridContainer}>
        {optionalButtons.map((item, index) => (
          <Box
            key={`optional-buttons-button-${index}`}
            className={styles.gridItem}
            gridColumn={
              Array.isArray(columnPosition)
                ? columnPosition[index]
                : columnPosition
            }
          >
            <Button
              variation="secondary"
              onClick={() => handleClick(index, item)}
              className={classnames(styles.button, getButtonClassName(index))}
              style={{
                padding: '20px 0',
                color:
                  selectedIndex === index
                    ? `${colors.white} !important`
                    : undefined,
                backgroundColor:
                  selectedIndex === index
                    ? `${secondaryColour || colors.tundora}`
                    : undefined
              }}
            >
              {item.label}
            </Button>
          </Box>
        ))}
      </Box>
    </div>
  )
}
