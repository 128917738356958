import React from 'react'
import styles from './UsingAmountMarkup.module.scss'
import type { UsingAmountMarkupProps } from './UsingAmountMarkup.model'
import { shortcodesRender } from 'shared/utils/shortcodesRender/shortcodesRender'

const formatTitle = (orgTitle: string, type: string) => {
  return orgTitle.replace('[DRUG_TYPE]', type)
}

export const UsingAmountMarkup: React.FC<UsingAmountMarkupProps> = (props) => {
  const {
    usingType,
    amountDisplayComponent,
    title = '',
    subtitle,
    shortcodes
  } = props

  return (
    <div>
      <div className={styles['using-amount-markup__titles']}>
        {title && (
          <h1
            className="screen-titles--title"
            dangerouslySetInnerHTML={{
              __html:
                shortcodesRender(shortcodes, formatTitle(title, usingType)) ||
                formatTitle(title, usingType)
            }}
          />
        )}
        {subtitle && (
          <p
            className="screen-titles--subtitle"
            dangerouslySetInnerHTML={{
              __html:
                shortcodesRender(
                  shortcodes,
                  formatTitle(subtitle, usingType)
                ) || formatTitle(subtitle, usingType)
            }}
          />
        )}
      </div>
      <div className={styles['using-amount-markup__amount']}>
        {amountDisplayComponent}
      </div>
    </div>
  )
}
