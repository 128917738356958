import React from 'react'
import classnames from 'classnames'
import styles from './MultipleChoiceQuestionsMarkup.module.scss'
import type {
  MultipleChoiceOptionLabelProps,
  MultipleChoiceQuestionsMarkupProps
} from './MultipleChoiceQuestionsMarkup.model'
import { sliceIntoChunks } from 'shared/utils/sliceIntoChunks/sliceIntoChunks'
import { BorderType } from 'components/Border/Border.model'
import { useColourPicker } from 'context/ColourContext'
import { colors } from 'shared/theme/theme'
import { Border } from 'components/Border/Border.component'
import Paper from '@mui/material/Paper'
import { Icon } from 'components/Icons/Icon.component'
import { IconType } from 'components/Icons/Icon.model'
import { Carousel } from 'components/Carousel/Carousel.component'

const MultipleChoiceOptionLabel: React.FC<MultipleChoiceOptionLabelProps> = (
  props
) => {
  const { label } = props

  if (label.includes('(')) {
    const indexOfBracket = label.indexOf('(')
    const mainStr = label.slice(0, indexOfBracket)
    const subStr = label.slice(indexOfBracket)
    return (
      <>
        {mainStr}
        <span
          className={
            styles['multiplechoice-questions-markup__option__text--small']
          }
        >
          {subStr}
        </span>
      </>
    )
  }

  return <>{label}</>
}

export const MultipleChoiceQuestionsMarkup: React.FC<
  MultipleChoiceQuestionsMarkupProps
> = (props) => {
  const {
    validOptions,
    columnNum,
    dataLength,
    multipleChoiceId,
    onItemClick,
    multipleSelections = false,
    vertical = false,
    multipleChoiceIsCarousel = false,
    wrapInCarouselComponent = false,
    leftAligned = false,
    withTick = false,
    borderType = BorderType.Option1,
    optionsDisabled = false
  } = props

  const { secondaryColour } = useColourPicker()

  const rowNum =
    multipleChoiceIsCarousel &&
    validOptions.length > 6 &&
    validOptions.filter((o) => o.image).length < 1
      ? 2
      : 1

  const hasImages = !!validOptions.find((item) => item.image)
  const chunkValidOptions = sliceIntoChunks(validOptions, rowNum)

  const calcGridColumnEnd = (index: number): number | 'auto' => {
    let cNum = columnNum

    if (multipleChoiceIsCarousel && dataLength > columnNum) {
      cNum = dataLength
    }

    if (!leftAligned && validOptions.length % cNum !== 0) {
      const ophanIndex = validOptions.length % cNum
      for (let i = 0; i < ophanIndex; i++) {
        if (validOptions.length - i === index + 1) {
          return -(cNum * 2 - (cNum - ophanIndex + 1) - (index % cNum) * 2)
        }
      }
    }

    return 'auto'
  }

  const markupStyle = (
    index: number
  ): { gridColumnEnd: number | 'auto' } | undefined => {
    if (!multipleChoiceIsCarousel || validOptions.length < 7) {
      return { gridColumnEnd: calcGridColumnEnd(index) }
    }
  }

  /**
   * Generates the main body of this component as an array of JSX elements.
   *
   * This allows the result of this function to be passed to the {@link Carousel}
   * component (allowing for each option to be rendered as a carousel slide), or simply be
   * rendered in place.
   *
   * @returns The main body of this component as an array of JSX elements.
   */
  const generateBody = (): React.JSX.Element[] => {
    return chunkValidOptions.map((chunk, chunkIndex) => (
      <div
        key={`multiple-choice-${chunkIndex}-chunk`}
        style={markupStyle(chunkIndex)}
      >
        {chunk.map((option, index) => {
          const imageHeight =
            hasImages || option.image ? 'calc(100% - 10px)' : '100%'
          const height = rowNum > 1 ? '100px' : imageHeight
          return (
            <div
              key={`multiple-choice-${index}-option`}
              style={{
                position: 'relative',
                height,
                zIndex: 0,
                marginTop: withTick ? 24 : 0
              }}
            >
              <Border
                type={borderType}
                fill={option.selected ? secondaryColour : colors.white}
                dropShadow
                transform
              />
              <Paper
                className={classnames(
                  styles['multiplechoice-questions-markup__option'],
                  'u-border-style-sketch',
                  {
                    [styles[
                      'multiplechoice-questions-markup__option--selected'
                    ]]: !!option.selected,
                    [styles[
                      'multiplechoice-questions-markup__option--with-image'
                    ]]: option.image,
                    [styles[
                      'multiplechoice-questions-markup__option--vertical'
                    ]]: vertical,
                    [styles[
                      'multiplechoice-questions-markup__option--vertical--with-image'
                    ]]: vertical && option.optionImage,
                    [styles[
                      'multiplechoice-questions-markup__option--disabled'
                    ]]: optionsDisabled
                  }
                )}
                name={`options-${
                  multipleSelections ? `${chunkIndex}-${index}` : ''
                }-${multipleChoiceId}`}
                component={'button'}
                type="button"
                role={multipleSelections ? 'checkbox' : 'radio'}
                aria-checked={option.selected ? 'true' : 'false'}
                aria-label={option.label}
                sx={{
                  height: rowNum > 1 ? '100% !important' : undefined,
                  padding: vertical || option.image ? '10px' : '25px 15px'
                }}
                onClick={() =>
                  onItemClick(
                    index + chunkIndex * rowNum,
                    option.isExclusive === 1
                  )
                }
              >
                {!vertical && option?.image && (
                  <div
                    className={
                      styles['multiplechoice-questions-markup__option__image']
                    }
                  >
                    <img src={option.image} alt={'Image of ' + option.label} />
                  </div>
                )}
                {vertical && option?.optionImage && (
                  <div
                    className={
                      styles[
                        'multiplechoice-questions-markup__option--vertical__image'
                      ]
                    }
                  >
                    <img
                      src={option.optionImage}
                      alt={'Option image of ' + option.label}
                    />
                  </div>
                )}
                {option.selected && withTick && (
                  <Icon
                    className={
                      styles['multiplechoice-questions-markup__option__icon']
                    }
                    icon={IconType.Tick}
                    size={32}
                    stroke={colors.black}
                    fill={colors.white}
                    border
                  />
                )}
                <MultipleChoiceOptionLabel label={option.label} />
              </Paper>
            </div>
          )
        })}
      </div>
    ))
  }

  // Render the body, which is an array
  // This will either be passed to a `Carousel` (if the contents should be rendered as a
  // carousel), otherwise, a React fragment to render directly.
  const body = generateBody()

  return wrapInCarouselComponent ? (
    <Carousel slides={body} slidesToShow={columnNum} />
  ) : (
    <>{body}</>
  )
}
