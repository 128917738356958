import React, { useEffect, useState } from 'react'
import { Screen } from 'components/Screen/Screen.component'
import { MultipleChoice } from 'components/MultipleChoice/MultipleChoice.component'
import { useDispatch } from 'react-redux'
import { Dispatch } from 'redux'
import { updateSurveyAnswer } from 'store/reducer'
import { ISurveyData } from 'store/type'
import {
  MultipleChoiceOptionsModel,
  MultipleChoiceScreenPropsModel,
  MultipleChoiceValueModel
} from './MultipleChoiceImageScreen.model'
import styles from './MultipleChoiceImageScreen.module.scss'
import { MultipleChoiceModel } from 'components/MultipleChoice/MultipleChoice.model'
import { matchCondition } from 'shared/utils/matchCondition/matchCondition'
import { ModalScreen } from '../ModalScreen/ModalScreen'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import classnames from 'classnames'
import { shortcodesRender } from 'shared/utils/shortcodesRender/shortcodesRender'
import { Border } from 'components/Border/Border.component'
import { colors } from 'shared/theme/theme'
import { BorderType } from 'components/Border/Border.model'

export const MultipleChoiceImageScreen: React.FC<
  MultipleChoiceScreenPropsModel
> = (props) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { getNextScreenId, showFollowUpQuestion } = matchCondition()
  const {
    currentScreenId,
    name,
    headerProps,
    screenData: {
      title,
      subtitle,
      isMultiple,
      optionGroups,
      image,
      optionalButtons
    },
    followUpQuestion,
    footerProps,
    shortcodes
  } = props

  const generateValidOptions = (
    optionGroup: MultipleChoiceOptionsModel
  ): MultipleChoiceModel[] => {
    const validOptionsArray = optionGroup.options
      .filter((item) => item.isActive)
      .map((item) => ({
        ...item,
        selected: item.default === 1
      }))

    return validOptionsArray
  }

  const [validOptions, setValidOptions] = useState<MultipleChoiceModel[]>(
    optionGroups ? generateValidOptions(optionGroups[0]) : []
  )

  const [group, setGroup] = useState<string | undefined>(
    optionGroups ? optionGroups[0].value : undefined
  )
  const [optionValue, setOptionValue] = useState<MultipleChoiceValueModel>()
  const [showModal, setShowModal] = useState<boolean>(false)
  const [isFollowUpValueSet, setIsFollowUpValueSet] = useState<boolean>(false)

  const handleOptionsButtonClick = (
    _event: React.MouseEvent<HTMLElement>,
    selected: string | null
  ): void => {
    dispatch(
      updateSurveyAnswer({
        [name]: undefined
      } as ISurveyData)
    )
    setOptionValue(undefined)
    if (selected !== null) {
      setGroup(selected)
      const selectedGroup = optionGroups?.find(
        (optionGroup) => optionGroup.value === selected
      )
      if (selectedGroup) {
        setValidOptions(generateValidOptions(selectedGroup))
      }
    }
  }

  const handleSetValue = (choices: string[]) => {
    const value = {
      group,
      value: isMultiple ? choices : choices[0]
    } as MultipleChoiceValueModel
    setOptionValue(value)
    dispatch(
      updateSurveyAnswer({
        [name]: value
      } as ISurveyData)
    )

    // Remove follow up data if main screen data changed and doesn't match any conditions
    if (
      followUpQuestion &&
      followUpQuestion.isActive &&
      !showFollowUpQuestion(value, followUpQuestion) &&
      followUpQuestion.surveyField
    ) {
      dispatch(
        updateSurveyAnswer({
          [followUpQuestion.surveyField]: undefined
        } as ISurveyData)
      )

      followUpQuestion?.additionalQuestions?.forEach((question) => {
        const { surveyField } = question
        if (surveyField) {
          dispatch(
            updateSurveyAnswer({
              [surveyField]: undefined
            } as ISurveyData)
          )
        }
      })

      setIsFollowUpValueSet(false)
    }
  }

  const handleFooterProps = () => {
    const updatedFooterProps = { ...footerProps }
    if (optionValue && optionValue.value) {
      updatedFooterProps.invalid = false
    } else {
      updatedFooterProps.invalid = true
    }

    const value = optionValue?.value
    // Next Screen Condition Logic
    if (
      !isFollowUpValueSet &&
      followUpQuestion &&
      followUpQuestion.isActive &&
      showFollowUpQuestion(value, followUpQuestion)
    ) {
      updatedFooterProps.customNextClick = () => setShowModal(true)
    } else {
      updatedFooterProps.nextScreenId = getNextScreenId(
        value,
        footerProps.conditions,
        footerProps.nextScreenId
      )
    }

    return updatedFooterProps
  }

  const handleModalScreenClose = (hasValue: boolean) => {
    setShowModal(false)
    if (hasValue) {
      setIsFollowUpValueSet(true)
    }
  }

  const defaultValue =
    optionValue != null
      ? Array.isArray(optionValue.value)
        ? optionValue.value
        : [optionValue.value]
      : []

  useEffect(() => {
    if (optionGroups && optionValue && optionValue.value) {
      const defaultGroup = optionGroups.find(
        (optionGroup) => optionGroup.value === optionValue.group
      )

      if (defaultGroup) {
        setGroup(defaultGroup.value)
        setValidOptions(
          defaultGroup.options.map((option) => {
            if (
              optionValue.value.length > 0 &&
              optionValue.value.includes(option.value)
            ) {
              return Object.assign({}, option, { selected: true })
            }

            if (optionValue.value.length === 0 && option.default) {
              return Object.assign({}, option, { selected: true })
            }

            return option
          })
        )
      }
    }

    // Ensure the default option in the group is "selected"
    const defaultOption = validOptions.find((option) => option.selected)
    if (defaultOption) {
      handleSetValue([defaultOption.value])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionGroups, validOptions])

  // Clear Redux
  useEffect(() => {
    const reset: ISurveyData = {
      [name]: undefined
    }

    if (followUpQuestion && followUpQuestion.surveyField) {
      reset[followUpQuestion.surveyField] = undefined
    }

    dispatch(updateSurveyAnswer(reset))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name])

  return (
    <div className={`drug-app-screen ${styles.multiplechoiceimagescreen}`}>
      <Screen
        currentScreenId={currentScreenId}
        headerProps={headerProps}
        footerProps={handleFooterProps()}
      >
        <div className={styles['multiplechoiceimagescreen-title']}>
          {title && (
            <h1
              className="screen-titles--title"
              dangerouslySetInnerHTML={{
                __html: shortcodesRender(shortcodes, title) || title
              }}
            />
          )}
          {optionGroups && optionGroups.length > 1 && (
            <ToggleButtonGroup
              className={styles['multiplechoiceimagescreen-buttons']}
              exclusive
              onChange={handleOptionsButtonClick}
              value={group}
            >
              {optionGroups.map((item, index) => (
                <ToggleButton
                  key={`multiple-choice-image-screen-option-${index}`}
                  className={styles['multiplechoiceimagescreen-button']}
                  value={item.value}
                >
                  <Border
                    type={BorderType.Select1}
                    fill={group === item.value ? colors.black : colors.white}
                    dropShadow
                  />
                  {item.label}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          )}
        </div>
        {subtitle && (
          <p
            className={classnames(
              'screen-titles--subtitle',
              styles['multiplechoiceimagescreen-subtitle'],
              {
                [styles['multiplechoiceimagescreen-subtitle--hasOptions']]:
                  optionGroups && optionGroups.length > 0
              }
            )}
            dangerouslySetInnerHTML={{
              __html: shortcodesRender(shortcodes, subtitle) || subtitle
            }}
          />
        )}
        <div className={styles['multiplechoiceimagescreen-choices']}>
          <MultipleChoice
            isMultiple={isMultiple}
            options={validOptions}
            defaultValue={defaultValue}
            setValue={handleSetValue}
            vertical={true}
            defaultImage={validOptions[0]?.image || image}
            withTick={false}
            optionalButtons={optionalButtons}
          />
        </div>
        {followUpQuestion && !!followUpQuestion.isActive && (
          <ModalScreen
            screenId={currentScreenId}
            modalData={followUpQuestion}
            open={showModal}
            onModalScreenClose={handleModalScreenClose}
            shortcodes={shortcodes}
          />
        )}
      </Screen>
    </div>
  )
}
