import React from 'react'
import styles from './DashboardTargets.module.scss'
import type { DashboardTargetsProps } from './DashboardTargets.model'
import { RaTargetsType } from 'shared/constants/Constants.d'
import { DashboardAnalysis } from '../DashboardAnalysis/DashboardAnalysis.component'
import { ProjectTargets } from 'components/ra-dashboard/ProjectTargets/ProjectTargets.component'

export const DashboardTargets: React.FC<DashboardTargetsProps> = (props) => {
  const {
    projectId,
    siteId,
    projectSiteTargetCollection,
    uniqueEligibleSurveys
  } = props

  return (
    <ProjectTargets
      projectId={projectId}
      siteId={siteId}
      projectSiteTargetCollection={projectSiteTargetCollection}
      uniqueEligibleSurveys={uniqueEligibleSurveys}
      targetsType={RaTargetsType.Dashboard}
      analysisComponent={DashboardAnalysis}
      renderQualifier="dashboard"
      stylingClasses={{
        sectionDiv: styles['dashboard-targets__analysis']
      }}
    />
  )
}
