import React, { useId } from 'react'
import { InputErrorMessages, InputFormat, InputPropsModel } from './Input.model'
import styles from './Input.module.scss'
import classnames from 'classnames'
import { Border } from 'components/Border/Border.component'
import { BorderType } from 'components/Border/Border.model'
import TextareaAutosize from '@mui/material/TextareaAutosize'
import {
  validateEmail,
  validatePhone,
  validateSLK
} from 'shared/utils/validateValue/validateValue'
import { Input as MuiInput } from '@mui/material'

export const Input: React.FC<InputPropsModel> = (props) => {
  const { className, inputOption, setValue, style } = props

  const id = useId()

  const commonInputProps = {
    'placeholder': `${inputOption.label ? inputOption.label : ''}${
      inputOption.required ? ' (required)' : ''
    }`,
    'aria-labelledby': `label-${id}`,
    'onChange': (e: React.ChangeEvent<HTMLTextAreaElement>) =>
      handleValueValidation(
        e.target.value,
        inputOption.required,
        inputOption.inputFormat
      )
  }

  const handleValueValidation = (
    value: string,
    required: boolean,
    format?: InputFormat | null
  ) => {
    if (required && value === '') {
      setValue(value, InputErrorMessages.empty)
      return
    }

    if (value !== '' && format === InputFormat.email && !validateEmail(value)) {
      setValue(value, InputErrorMessages.emailFormat)
      return
    }

    if (value !== '' && format === InputFormat.phone && !validatePhone(value)) {
      setValue(value, InputErrorMessages.phoneFormat)
      return
    }

    if (value !== '' && format === InputFormat.slk && !validateSLK(value)) {
      setValue(value, InputErrorMessages.slkFormat)
      return
    }

    setValue(value)
  }

  return (
    <div className={classnames(className, styles.input)}>
      <div style={{ position: 'relative' }}>
        <Border
          type={
            inputOption.allowMultipleLines
              ? BorderType.Modal
              : BorderType.Select2
          }
          fill={style?.backgroundColor}
        />
        <label id={`label-${id}`}>{inputOption.label}</label>
        {inputOption.allowMultipleLines ? (
          <TextareaAutosize maxRows={1} minRows={12} {...commonInputProps} />
        ) : (
          <MuiInput
            fullWidth={true}
            disableUnderline={true}
            {...commonInputProps}
          />
        )}
      </div>
    </div>
  )
}
