import React from 'react'
import Box from '@mui/material/Box'
import { RADashCalc } from 'shared/utils/raDashboardCalculation/raDashboardCalculation'
import type { ProjectTargetsProps } from './ProjectTargets.model'

export const ProjectTargets: React.FC<ProjectTargetsProps> = (props) => {
  const {
    projectId,
    siteId,
    projectSiteTargetCollection,
    targetsType,
    analysisComponent: AnalysisComponent,
    renderQualifier,
    uniqueEligibleSurveys,
    stylingClasses
  } = props

  const { generateTargetData } = RADashCalc()

  const selectedProject = projectSiteTargetCollection.find(
    (p) => p.id === projectId
  )
  const site = selectedProject?.sites?.find((s) => s.id === siteId)
  const targets = site?.targets
  const results = generateTargetData(
    targets,
    targetsType,
    uniqueEligibleSurveys
  )

  return (
    <>
      {results.map((item, index) => (
        <Box key={`${renderQualifier}-targets-target-${index}`}>
          <p>{item.value}</p>

          <div className={stylingClasses?.sectionDiv}>
            {item.data &&
              item.data.map((currentData, currentDataIndex) => (
                <AnalysisComponent
                  key={`${renderQualifier}-targets-analysis-section-${currentDataIndex}`}
                  title={currentData.gender}
                  values={currentData.data}
                  sectionIndex={currentDataIndex}
                />
              ))}
          </div>
        </Box>
      ))}
    </>
  )
}
