import { Screen } from 'components/Screen/Screen.component'
import { MultipleChoice } from 'components/MultipleChoice/MultipleChoice.component'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Dispatch } from 'redux'
import { updateSurveyAnswer } from 'store/reducer'
import { ISurveyData, SurveyState } from 'store/type'
import { MultipleChoiceScreenPropsModel } from './MultipleChoiceScreen.model'
import styles from './MultipleChoiceScreen.module.scss'
import { MultipleChoiceModel } from 'components/MultipleChoice/MultipleChoice.model'
import { matchCondition } from 'shared/utils/matchCondition/matchCondition'
import { ModalScreen } from '../ModalScreen/ModalScreen'
import store from 'store/store'
import { shortcodesRender } from 'shared/utils/shortcodesRender/shortcodesRender'
import { surveyResponseValueToArray } from 'shared/utils/surveyDataConverter/surveyDataConverter'

export const MultipleChoiceScreen: React.FC<
  MultipleChoiceScreenPropsModel & SurveyState
> = (props) => {
  const dispatch: Dispatch<any> = useDispatch()
  const { getNextScreenId, showFollowUpQuestion } = matchCondition()
  const {
    currentScreenId,
    name,
    headerProps,
    screenData: {
      title,
      subtitle,
      columnNum,
      options,
      isMultiple,
      isCarousel,
      isOptional,
      image,
      altText,
      sideLayout,
      optionalButtons,
      dependingScreen
    },
    followUpQuestion,
    footerProps,
    shortcodes
  } = props

  const [validOptions, setValidOptions] = useState<MultipleChoiceModel[]>([])
  const [optionValue, setOptionValue] = useState<string[]>([])
  const [showModal, setShowModal] = useState<boolean>(false)
  const [isFollowUpValueSet, setIsFollowUpValueSet] = useState<boolean>(false)

  // Clear Redux
  useEffect(() => {
    const reset: ISurveyData = {
      [name]: undefined
    }

    if (followUpQuestion && followUpQuestion.surveyField) {
      reset[followUpQuestion.surveyField] = undefined
    }

    dispatch(updateSurveyAnswer(reset))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name])

  const handleSetValue = (choices: string[]) => {
    setOptionValue(choices)
    const value = isMultiple ? choices : choices[0]
    dispatch(
      updateSurveyAnswer({
        [name]: value
      } as ISurveyData)
    )

    // Remove follow-up data if main screen data changed and doesn't match any conditions
    if (
      followUpQuestion &&
      followUpQuestion.isActive &&
      followUpQuestion.surveyField
    ) {
      dispatch(
        updateSurveyAnswer({
          [followUpQuestion.surveyField]: undefined
        } as ISurveyData)
      )

      followUpQuestion?.additionalQuestions?.forEach((question) => {
        const { surveyField } = question
        if (surveyField) {
          dispatch(
            updateSurveyAnswer({
              [surveyField]: undefined
            } as ISurveyData)
          )
        }
      })

      setIsFollowUpValueSet(false)
    }
  }

  const handleFooterProps = () => {
    const updatedFooterProps = { ...footerProps }

    if (optionValue.length === 0) {
      updatedFooterProps.invalid = true
    } else {
      updatedFooterProps.invalid = false
    }

    const value = isMultiple ? optionValue : optionValue[0]
    // Next Screen Condition Logic
    if (
      !isFollowUpValueSet &&
      followUpQuestion &&
      followUpQuestion.isActive &&
      showFollowUpQuestion(value, followUpQuestion)
    ) {
      updatedFooterProps.customNextClick = () => setShowModal(true)
    } else {
      updatedFooterProps.nextScreenId = getNextScreenId(
        value,
        footerProps.conditions,
        footerProps.nextScreenId
      )
    }

    updatedFooterProps.toggleNextButton = isFollowUpValueSet && !showModal

    return updatedFooterProps
  }

  const handleModalScreenClose = (hasValue: boolean) => {
    setShowModal(false)
    if (hasValue) {
      setIsFollowUpValueSet(true)
    } else {
      setIsFollowUpValueSet(false)
    }
  }

  useEffect(() => {
    if (!options) {
      return
    }

    let filterOptions = options.filter((item) => item.isActive === 1)

    // Depending Screen Logic
    const reduxValues = store.getState().surveyData
    if (dependingScreen && dependingScreen.length > 0 && reduxValues) {
      let dependingValues: string[] = []

      dependingScreen.forEach((screen) => {
        if (reduxValues[screen]) {
          const arr = surveyResponseValueToArray(reduxValues[screen])
          dependingValues = dependingValues.concat(arr)
        }
      })

      filterOptions = filterOptions.filter((item) =>
        dependingValues.includes(item.value)
      )
    }

    const values: string[] = []
    setValidOptions(
      filterOptions.map((option) => {
        if (option.default === 1) {
          values.push(option.value)
          return Object.assign({}, option, { selected: true })
        }

        return option
      })
    )

    handleSetValue(values)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options])

  return (
    <div className={`drug-app-screen ${styles.multiplechoicescreen}`}>
      <Screen
        currentScreenId={currentScreenId}
        headerProps={headerProps}
        footerProps={handleFooterProps()}
      >
        <div className={styles['multiplechoicescreen-choices']}>
          <MultipleChoice
            title={shortcodesRender(shortcodes, title)}
            subtitle={shortcodesRender(shortcodes, subtitle)}
            isMultiple={isMultiple}
            options={validOptions}
            defaultValue={optionValue}
            columnNum={columnNum ? Number(columnNum) : undefined}
            setValue={handleSetValue}
            withTick={true}
            isCarousel={isCarousel}
            image={image}
            altText={altText}
            sideLayout={sideLayout}
            optionalButtons={isOptional ? optionalButtons : undefined}
          />
        </div>
        {followUpQuestion &&
          !!followUpQuestion.isActive &&
          followUpQuestion.data && (
            <ModalScreen
              screenId={currentScreenId}
              modalData={followUpQuestion}
              open={showModal}
              onModalScreenClose={handleModalScreenClose}
              shortcodes={shortcodes}
              value={optionValue}
              setValue={setOptionValue}
            />
          )}
      </Screen>
    </div>
  )
}
