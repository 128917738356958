import React, { useState } from 'react'
import styles from './CalendarMultiSelect.module.scss'
import multipleChoiceMarkupStyles from 'components/MultipleChoice/MultipleChoiceQuestionsMarkup/MultipleChoiceQuestionsMarkup.module.scss'
import classnames from 'classnames'
import { CalendarMultiSelectPropsModel } from './CalendarMultiSelect.model'
import Calendar, { CalendarProps, CalendarTileProperties } from 'react-calendar'
import { Grid } from '@mui/material'
import { Icon } from 'components/Icons/Icon.component'
import { IconType } from 'components/Icons/Icon.model'
import { Alert } from 'components/Alert/Alert.component'
import { Button } from 'components/Button/Button.component'
import { useSwipeable } from 'react-swipeable'
import { AnimatedIcon } from 'components/AnimatedIcon/AnimatedIcon.component'
import { useColourPicker } from 'context/ColourContext'

export const CalendarMultiSelect: React.FC<CalendarMultiSelectPropsModel> = (
  props
) => {
  const {
    title,
    notice,
    selectRange,
    events,
    maxDate,
    minDate,
    setValue,
    birthday,
    isMoreThan12MonthsShow = false,
    moreThan12MonthsEvent,
    selectionDisabled = false
  } = props
  const { secondaryColour } = useColourPicker()
  const [defaultDate] = useState(maxDate || new Date())
  const [activeStartDate, setActiveStartDate] = useState(maxDate || defaultDate)

  /**
   * Convert Date object to Date String
   * @param date - input date object
   * @returns {string} - Date string
   */
  const getDateString = (date: Date) => date.toDateString()

  /**
   * Get Next Month from provided date object
   * @param date - input date object
   * @returns {date} previousMonth
   */
  const getNextMonth = (date: Date) =>
    new Date(date.getFullYear(), date.getMonth() + 1, date.getDate())

  /**
   * Get Previous Month from provided date object
   * @param date - input date object
   * @returns {date} nextMonth
   */
  const getPrevMonth = (date: Date) =>
    new Date(date.getFullYear(), date.getMonth() - 1, date.getDate())

  /**
   * Modify React Calendar tile based on special dates and today date
   * @param tileProps - Tile Properties from React Calendar
   * @returns {JSX.Element} - Injected dom element
   */
  const modifyTileContent = (tileProps: CalendarTileProperties) => {
    const dates = events?.map((specialDate) => getDateString(specialDate.date))

    // events
    if (events && dates?.includes(getDateString(tileProps.date))) {
      const selectedDate = events[dates?.indexOf(getDateString(tileProps.date))]
      return <label>{selectedDate.name}</label>
    }

    // birthday
    if (
      tileProps.date.toLocaleString('en-US', {
        month: 'long',
        day: 'numeric'
      }) == birthday
    ) {
      return (
        <>
          <Icon
            className={styles['calendarmultiselect__calendar-icon']}
            icon={IconType.Cake}
            size={32}
          />
          <label>My Birthday</label>
        </>
      )
    }

    // Today
    if (getDateString(tileProps.date) == getDateString(new Date())) {
      return <label>Today</label>
    }

    return null
  }

  /**
   * Modify the tile classname for today
   * @param tileProps  - Tile Properties from React Calendar
   * @returns {string} - class names to be added
   */
  const modifyTileClassName = (tileProps: CalendarTileProperties) => {
    const dates = events
      ?.filter((d) => d.selectedDate)
      .map((specialDate) => getDateString(specialDate.date))
    return classnames(
      multipleChoiceMarkupStyles['multiplechoice-questions-markup__option'],
      getDateString(tileProps.date) === getDateString(new Date()) &&
        styles['calendarmultiselect__today'],
      dates?.includes(getDateString(tileProps.date)) &&
        'react-calendar__tile--active',
      dates?.includes(getDateString(tileProps.date)) &&
        styles['calendarmultiselect__selected-date']
    )
  }

  /**
   * Checks if there is a previous month available from current date and minimum date
   */
  const hasPreviousMonth =
    (minDate && getPrevMonth(activeStartDate) >= minDate) || !minDate

  /**
   * Checks if there is a next month available from current date and maximum date
   */
  const hasNextMonth =
    (maxDate && getNextMonth(activeStartDate) <= maxDate) || !maxDate

  /**
   * Handle navigation to the previous month
   */
  const handlePreviousMonth = (): void =>
    setActiveStartDate(getPrevMonth(activeStartDate))

  /**
   * Handle navigation to the next month
   */
  const handleNextMonth = (): void =>
    setActiveStartDate(getNextMonth(activeStartDate))

  /* Setup swipe controls for calender navigation, applied to container and trigger on swipe right and left*/
  const swipeHandlers = useSwipeable({
    onSwipedRight: () => hasPreviousMonth && handlePreviousMonth(),
    onSwipedLeft: () => hasNextMonth && handleNextMonth(),
    swipeDuration: Infinity,
    preventScrollOnSwipe: true,
    trackMouse: true
  })

  const defaultProps: CalendarProps = {
    maxDate,
    activeStartDate,
    selectRange,
    onChange: setValue,
    showNavigation: false,
    tileClassName: modifyTileClassName,
    tileContent: modifyTileContent,
    tileDisabled: (tileProps: CalendarTileProperties) =>
      selectionDisabled
        ? true
        : !!(
            (maxDate && tileProps.date > maxDate) ||
            (minDate && tileProps.date < minDate)
          )
  }

  return (
    <div className={classnames(styles.calendarmultiselect)}>
      <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={12}>
          <h2 className={classnames(styles['calendarmultiselect__title'])}>
            {title}
          </h2>
          {notice && <Alert text={notice} backgroundColor={secondaryColour} />}
        </Grid>
      </Grid>
      <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={10}>
          <h3
            className={classnames(
              styles['calendarmultiselect__calendar-title']
            )}
          >
            {activeStartDate.toLocaleString('default', {
              month: 'long',
              year: 'numeric'
            })}
          </h3>
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: 382 }}
        {...swipeHandlers}
      >
        <Grid item xs={2}>
          {hasPreviousMonth && (
            <button
              className={classnames(styles['calendarmultiselect__button'], {
                'initial-month': defaultDate === activeStartDate
              })}
              aria-label="Previous Month"
              onClick={handlePreviousMonth}
            >
              <AnimatedIcon variation="prompt" deltaY={-200}>
                <div
                  className={classnames(
                    styles['calendarmultiselect__button--chevron']
                  )}
                >
                  <Icon icon={IconType.ArrowLeft} size={36} border={true} />
                </div>
              </AnimatedIcon>
            </button>
          )}
        </Grid>
        <Grid
          item
          xs={8}
          sx={{
            alignSelf: 'flex-start',
            '.react-calendar__tile.react-calendar__tile--active': {
              bgcolor: `${secondaryColour} !important`
            }
          }}
        >
          <Calendar {...defaultProps} />
        </Grid>
        <Grid item xs={2}>
          {hasNextMonth && (
            <button
              className={classnames(styles['calendarmultiselect__button'])}
              aria-label="Next Month"
              onClick={handleNextMonth}
            >
              <div
                className={classnames(
                  styles['calendarmultiselect__button--chevron']
                )}
              >
                <Icon icon={IconType.ArrowRight} size={36} border={true} />
              </div>
            </button>
          )}
        </Grid>
      </Grid>
      {isMoreThan12MonthsShow ? (
        <Grid
          container
          alignItems="center"
          justifyContent="end"
          paddingTop={window.outerHeight < 750 ? '5px' : '15px'}
        >
          <Grid item xs={3}>
            <Button
              className={classnames(
                styles['calendarmultiselect__button--morethan12'],
                {
                  [styles['calendarmultiselect__button--morethan12--flat']]:
                    window.outerHeight < 750
                }
              )}
              variation="secondary"
              onClick={moreThan12MonthsEvent}
            >
              More than 12 months ago
            </Button>
          </Grid>
          <Grid item xs={2} />
        </Grid>
      ) : null}
    </div>
  )
}
