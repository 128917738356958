import { Screen } from 'components/Screen/Screen.component'
import React, { useState } from 'react'
import {
  outroGlobalShortcodesRender,
  shortcodesRender
} from 'shared/utils/shortcodesRender/shortcodesRender'
import type { EffectsCarouselScreenPropsModel } from './EffectsCarouselScreen.model'
import styles from './EffectsCarouselScreen.module.scss'
import type { SurveyState } from 'store/type'
import { connect } from 'react-redux'
import { GLOBAL_SHORTCODES } from 'shared/constants/shortcodes/Shortcodes.d'
import type { CircleChoiceOptionModel } from 'components/CircleChoice/CircleChoice.model'
import { UsingKeys } from 'shared/constants/Constants.d'
import { CircleChoice } from 'components/CircleChoice/CircleChoice.component'
import { matchCondition } from 'shared/utils/matchCondition/matchCondition'
import type { DeepReadonly } from 'shared/types/general'

const USING_TYPES = Object.keys(UsingKeys)

export const EffectsCarouselScreenComponent: React.FC<
  EffectsCarouselScreenPropsModel
> = (props) => {
  const {
    currentScreenId,
    headerProps,
    screenData,
    footerProps,
    shortcodes,
    states
  } = props

  const { title, subtitle } = screenData

  /**
   * Set the initial value of the "circle choices" to display on this screen.
   *
   * @returns The initial value of the "circle choices" to display on this screen.
   */
  const initCircles = (): DeepReadonly<CircleChoiceOptionModel[]> => {
    let includeCircles: DeepReadonly<CircleChoiceOptionModel[]> = []

    if (states) {
      const currentKey = Object.keys(screenData).find(
        (key) =>
          USING_TYPES.includes(key) &&
          screenData[key as keyof typeof UsingKeys] !== null
      )
      // set Circles
      if (currentKey) {
        includeCircles = screenData[currentKey as keyof typeof UsingKeys]
      }
    }

    return includeCircles
  }

  const [circles] =
    useState<DeepReadonly<CircleChoiceOptionModel[]>>(initCircles)
  const [value, setValue] = useState<string>()

  const { getNextScreenId } = matchCondition()

  const handleFooterProps = () => {
    const updatedFooterProps = { ...footerProps }

    if (!value) {
      updatedFooterProps.invalid = true
    } else {
      updatedFooterProps.invalid = false
    }

    if (updatedFooterProps.nextScreenId) {
      updatedFooterProps.nextScreenId = getNextScreenId(
        undefined,
        footerProps.conditions,
        footerProps.nextScreenId
      )
    }

    return updatedFooterProps
  }

  return (
    <div className={`drug-app-screen ${styles.effectscarouselscreen}`}>
      <Screen
        currentScreenId={currentScreenId}
        headerProps={headerProps}
        footerProps={handleFooterProps()}
      >
        <div className={styles['effectscarouselscreen-content']}>
          <div className={styles['effectscarouselscreen-titles']}>
            {title && (
              <h1
                className="screen-titles--title"
                dangerouslySetInnerHTML={{
                  __html:
                    shortcodesRender(
                      shortcodes,
                      outroGlobalShortcodesRender(
                        GLOBAL_SHORTCODES.OUTRO_USING_TYPES,
                        title
                      )
                    ) ||
                    outroGlobalShortcodesRender(
                      GLOBAL_SHORTCODES.OUTRO_USING_TYPES,
                      title
                    )
                }}
              />
            )}
            {subtitle && (
              <p
                className="screen-titles--subtitle"
                dangerouslySetInnerHTML={{ __html: subtitle }}
              />
            )}
          </div>
          <div className={styles['effectscarouselscreen-amount']}>
            <CircleChoice options={circles} setValue={(v) => setValue(v)} />
          </div>
        </div>
      </Screen>
    </div>
  )
}

const mapStateToProps = (state: SurveyState) => ({
  states: state.surveyData
})

export const EffectsCarouselScreen = connect(mapStateToProps)(
  EffectsCarouselScreenComponent
)
