import React from 'react'
import styles from './AddMultiDate.module.scss'
import classnames from 'classnames'
import { AddMultiDatePropsModel } from './AddMultiDate.model'
import { Box } from '@mui/material'
import { Carousel } from 'components/Carousel/Carousel.component'
import { ordinal_suffix_of } from 'shared/utils/ordinalSuffix/ordinalSuffix'
import * as iterUtils from 'shared/utils/iterUtils/iterUtils'
import type { DateSquareProps } from 'components/DateSquare/DateSquare.model'
import { DateSquare } from 'components/DateSquare/DateSquare.component'
import type { AddDateButtonProps } from 'components/AddDateButton/AddDateButton.model'
import { AddDateButton } from 'components/AddDateButton/AddDateButton.component'

export const AddMultiDate: React.FC<AddMultiDatePropsModel> = (props) => {
  const {
    title,
    subtitle,
    dates = [],
    addDate,
    requiredDate,
    disabled = false
  } = props

  const otherRequire = dates ? requiredDate - dates.length : requiredDate

  const generateRecordedDatesCarouselSlides =
    (): React.ReactElement<DateSquareProps>[] => {
      return dates.map((date, index) => (
        <DateSquare
          key={`add-multidate-saved-date-${index}`}
          date={date}
          title={
            index == 0 ? 'Last day' : `${ordinal_suffix_of(index + 1)} last day`
          }
          styleClasses={{
            body: classnames(
              styles['addmultidate__slide'],
              styles['addmultidate__slide--date']
            )
          }}
        />
      ))
    }

  const generateAddableDatesCarouselSlides =
    (): React.ReactElement<AddDateButtonProps>[] => {
      return iterUtils.range(otherRequire).map((value) => (
        <AddDateButton
          key={`add-multidate-add-date-${value}`}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onClick={disabled ? () => {} : addDate}
          className={classnames(
            styles['addmultidate__slide'],
            styles['addmultidate__adddate'],
            disabled && styles['addmultidate--disabled']
          )}
        />
      ))
    }

  const generateCarouselSlides = (): React.JSX.Element[] => {
    const recordedDatesSlides: React.JSX.Element[] =
      generateRecordedDatesCarouselSlides()
    const addableDatesSlides = generateAddableDatesCarouselSlides()

    return recordedDatesSlides.concat(addableDatesSlides)
  }

  const carouselSlides = generateCarouselSlides()

  return (
    <Box className={classnames(styles.addmultidate)}>
      <h1
        className={classnames(
          'screen-titles--title',
          styles['addmultidate__title']
        )}
      >
        {title}
      </h1>
      {subtitle && (
        <h2
          className={classnames(
            'screen-titles--subtitle',
            styles['addmultidate__subtitle']
          )}
        >
          {subtitle}
        </h2>
      )}
      <Carousel slides={carouselSlides} />
    </Box>
  )
}
