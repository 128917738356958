import React, { useMemo, useState } from 'react'
import type {
  SliderImageProps,
  SliderPropsModel,
  SliderThumbComponentProps
} from './Slider.model'
import MuiSlider, { SliderThumb } from '@mui/material/Slider'
import styles from './Slider.module.scss'
import { styled } from '@mui/material/styles'
import { Icon } from 'components/Icons/Icon.component'
import { colors } from 'shared/theme/theme'
import { DEFAULT_EMPTY_IMAGE_PATH } from 'shared/constants/Constants.d'
import classnames from 'classnames'
import { IconType } from 'components/Icons/Icon.model'
import { OptionalButtons } from 'components/OptionalButtons/OptionalButtons.component'
import { useColourPicker } from 'context/ColourContext'
import { isString } from 'shared/types/guards'

const IconSlider = styled(MuiSlider)(() => {
  const { secondaryColour } = useColourPicker()

  return {
    color: secondaryColour,
    height: 10,
    '& .MuiSlider-valueLabel': {
      backgroundColor: secondaryColour
    }
  }
})

/**
 * Generate slider thumb component
 * @param thumbProps Html attributes for slider thumb
 * @returns {JSX.Element} Slider thumb component
 */
const SliderThumbComponent: React.FC<SliderThumbComponentProps> = (props) => {
  const { children, icon, ...other } = props

  const { secondaryColour } = useColourPicker()

  return (
    <SliderThumb {...other}>
      {children}
      {icon && (
        <Icon
          icon={icon}
          size={47}
          stroke={colors.white}
          fill={secondaryColour}
        />
      )}
    </SliderThumb>
  )
}

/**
 * Generate static image
 * @returns {(JSX.Element | null)} image or placeholder
 */
const SliderImage: React.FC<SliderImageProps> = (props) => {
  const { imageSrc, sliderTitle } = props

  if (!imageSrc) {
    return null
  }

  if (imageSrc == DEFAULT_EMPTY_IMAGE_PATH) {
    return <div className="image-space-holder">Picture / illustration</div>
  }

  return <img className={styles.image} src={imageSrc} alt={sliderTitle} />
}

export const Slider: React.FC<SliderPropsModel> = (props) => {
  const {
    className,
    range = false,
    title,
    subtitle,
    image,
    labelPrefix,
    labelSuffix,
    icon = IconType.Hand,
    min = 0,
    max = 100,
    step = 1,
    defaultValue,
    setSurveyValue,
    minLabel,
    maxLabel,
    customLabels,
    optionalButtons,
    ...componentProps
  } = props

  const { secondaryColour } = useColourPicker()

  // Memoise the default value of the slider, so that the `value` prop passed to the
  // `MuiSlider` component is not `undefined` to avoid any warnings
  const muiSliderDefaultValue = useMemo((): number | number[] => {
    /**
     * Generate default value for slider
     * @returns {(number | number[])} default slider value or range value
     */
    const sliderDefault = () => (range ? [min, max] : min)

    if (defaultValue == null) {
      return sliderDefault()
    }

    // I am not sure why the original code checked if `defaultValue[0]` may be a string,
    // if the only possible array type that may be passed, at least according to
    // TypeScript, is `number[]`.
    //
    // One possibility is that they wanted to account for the `String` type as well
    // (generated by `new String(...)`), as the `typeof` this would *not* be `'string'`.
    //
    // In this new code, however, the type guard `isString` should cover this case.
    //
    // I decided to leave the check for if index 0 is still a string type for now (just
    // removed the cast to `any`), as I don't think it will affect much anyway, and it's
    // there just in case.
    if (
      isString(defaultValue) ||
      (Array.isArray(defaultValue) && isString(defaultValue[0]))
    ) {
      return sliderDefault()
    }

    return defaultValue
  }, [defaultValue, max, min, range])

  const [resetOptionalButtons, setResetOptionalButtons] =
    useState<boolean>(false)

  // Track the local value of the slider in this component, and have its value set in the
  // `onChange` prop function of `MuiSlider`.
  //
  // The actual survey value which is set by the slider will only be updated in the
  // `onChangeCommitted` prop function of `MuiSlider`.
  //
  // This was necessary, as changing the actual survey value in Redux in the `onChange`
  // prop function slowed the slider down too much, causing it to become laggy.
  const [sliderValue, setSliderValue] = useState<number | number[]>(
    muiSliderDefaultValue
  )

  /**
   * Format prefix or suffix to number
   * @param value Input string for format
   * @returns {string} Formatted string with prefix and suffix
   */
  const handleLabelFormat = (value: number): string => {
    let displayValue = value.toString()
    // Check Custom Label
    if (customLabels && customLabels.length > 0) {
      const customLabel = customLabels.find((item) => item.value === value)
      if (customLabel) return customLabel.label
    }

    // Add Prefix & Suffix
    if (labelPrefix) {
      displayValue = labelPrefix + ' ' + displayValue
    }
    if (labelSuffix) {
      displayValue = displayValue + ' ' + labelSuffix
    }
    return displayValue
  }

  const handleSurveyValueChange = (
    _event: Event | React.SyntheticEvent<Element, Event>,
    value: number | number[]
  ) => {
    setResetOptionalButtons(!resetOptionalButtons)
    setSurveyValue(value)
  }

  /**
   * When an optional button (if any) is clicked, the button value (string) will be
   * saved as the survey value, while the slider value will be reset.
   * @param value The string value of the clicked optional button
   */
  const handleOptionalButtonClick = (value: string) => {
    setSurveyValue(value)
    setSliderValue(muiSliderDefaultValue)
  }

  return (
    <div
      className={classnames(styles.sliderWrapper, className, {
        [styles['sliderWrapper--noImage']]: !image
      })}
      {...componentProps}
    >
      {title && (
        <h1
          className="screen-titles--title"
          dangerouslySetInnerHTML={{ __html: title }}
        />
      )}
      {subtitle && (
        <p
          className="screen-titles--subtitle"
          dangerouslySetInnerHTML={{ __html: subtitle }}
        />
      )}
      <div
        className={classnames(styles.sliderAlign, {
          [styles['sliderAlign--middle']]: !title && !subtitle && !image,
          [styles['sliderAlign--noImage']]: !image
        })}
      >
        <>
          <SliderImage imageSrc={image} sliderTitle={title} />

          <div className={classnames(styles['slider__inner-wrapper'])}>
            <IconSlider
              className={classnames(styles.slider, {
                [styles['slider--round']]:
                  !labelPrefix &&
                  !labelSuffix &&
                  (!customLabels || customLabels.length === 0)
              })}
              slots={{ thumb: SliderThumbComponent }}
              // Need `as any` cast due to MUI not yet supporting type inference here
              // - https://github.com/mui/mui-x/issues/10389
              // - https://github.com/mui/mui-x/issues/9775
              slotProps={{ thumb: { icon } as any }}
              valueLabelFormat={handleLabelFormat}
              valueLabelDisplay="on"
              // Use `value` instead of `defaultValue` as we want the slider to be
              // controlled
              value={sliderValue}
              min={min}
              max={max}
              step={step}
              marks={
                minLabel && maxLabel
                  ? [
                      {
                        value: min,
                        label: minLabel
                      },
                      {
                        value: max,
                        label: maxLabel
                      }
                    ]
                  : false
              }
              onChange={(_event, value) => setSliderValue(value)}
              onChangeCommitted={handleSurveyValueChange}
            />
          </div>
          {optionalButtons && (
            <OptionalButtons
              optionalButtons={optionalButtons}
              color={secondaryColour}
              reset={resetOptionalButtons}
              onButtonSelect={handleOptionalButtonClick}
            />
          )}
        </>
      </div>
    </div>
  )
}
