import React, { useContext, useState, useRef } from 'react'
import classnames from 'classnames'
import styles from './CircleChoice.module.scss'
import type {
  CircleChoiceOptionModel,
  CircleChoicePropsModel
} from './CircleChoice.model'
import { AudioContext } from 'context/AudioContext'
import type { DeepReadonly } from 'shared/types/general'
import { Carousel } from '../Carousel/Carousel.component'
import { CircleChoiceItem } from './CircleChoiceItem/CircleChoiceItem.component'

export const CircleChoice: React.FC<CircleChoicePropsModel> = (props) => {
  const { title, subtitle, options, setValue } = props
  const { playAudio } = useContext(AudioContext)
  const [selected, setSelected] = useState<string>()

  const prevOptions = useRef<DeepReadonly<CircleChoiceOptionModel[]>>(options)

  // Clear Option Text when options changed
  if (options !== prevOptions.current) {
    prevOptions.current = options
    setSelected(undefined)
  }

  /**
   * Handle an option being selected in this component, also calling the `setValue` prop
   * function with the option label that was passed to this function.
   *
   * @param optionLabel - The label of the option which is being selected.
   */
  const handleOptionSelect = (optionLabel: string) => {
    // Set the `selected` state variable
    setSelected(optionLabel)
    // Also set the value passed as a prop
    setValue(optionLabel)
  }

  const handleClick = (option: CircleChoiceOptionModel) => {
    if (selected !== option.label && option?.voices && option?.id) {
      playAudio?.(`${option?.id}-option`)
    }
    handleOptionSelect(option.label)
  }

  const listItems = options.map((option, index) => (
    <CircleChoiceItem
      key={`circle-choice-item-${index}`}
      selected={selected}
      option={option}
      onHandleClick={handleClick}
    />
  ))

  return (
    <div className={styles.circlechoice}>
      {title && (
        <h1
          className={classnames(
            'screen-titles--title',
            styles['circlechoice__title']
          )}
          dangerouslySetInnerHTML={{ __html: title }}
        />
      )}
      {subtitle && (
        <p
          className={classnames(
            'screen-titles--subtitle',
            styles['circlechoice__subtitle']
          )}
          dangerouslySetInnerHTML={{ __html: subtitle }}
        />
      )}
      <div className={classnames(styles['circlechoice__content'])}>
        <h2
          className={styles['circlechoice__optionText']}
          aria-hidden={selected ? 'true' : 'false'}
          dangerouslySetInnerHTML={{
            __html: selected || ''
          }}
        />
        <div
          className={classnames(styles['circlechoice__wrapper'], {
            'no-padding': options.length > 6
          })}
          style={{
            display: options.length < 7 ? 'flex' : 'block',
            width: 160 * options.length
          }}
        >
          {options.length > 6 ? (
            <Carousel slides={listItems} slidesToShow={5} />
          ) : (
            <>{listItems}</>
          )}
        </div>
      </div>
    </div>
  )
}
