import type { ShortCodeModel } from 'api/client.model'
import type { MultipleChoiceModel } from 'components/MultipleChoice/MultipleChoice.model'
import type { MultipleChoiceScreenData } from 'screens/survey/MultipleChoiceScreen/MultipleChoiceScreen.model'
import { UsingTypes } from 'shared/constants/Constants.d'
import {
  GLOBAL_SHORTCODES,
  OUTRO_USING_TYPES_TEXT
} from 'shared/constants/shortcodes/Shortcodes.d'
import type { DeepReadonly } from 'shared/types/general'
import store from 'store/store'

const Fraction = require('fractional').Fraction

export const shortcodesRender = (
  shortcodes?: DeepReadonly<ShortCodeModel[]>,
  title?: string
) => {
  if (!shortcodes || !title) {
    return title
  }

  const data = store.getState()
  let newTitle = title
  shortcodes.forEach((shortcode) => {
    const screens = data.survey?.screens.filter(
      (screen) => screen.surveyField === shortcode.surveyField
    )
    const surveyAnswer = data.surveyData
      ? data.surveyData[shortcode.surveyField]
      : undefined

    if (screens && screens.length > 0 && surveyAnswer) {
      let selectedOption: MultipleChoiceModel | undefined
      screens.some((screen) => {
        const screenData = screen.data
        if (Object.keys(screenData)[0] === 'multipleChoice') {
          selectedOption = (
            screenData.multipleChoice as MultipleChoiceScreenData
          ).options.find((option) => option.value === surveyAnswer)
          return selectedOption
        } else {
          newTitle = newTitle.replace(shortcode.shortcode, surveyAnswer)
        }
      })

      if (selectedOption) {
        newTitle = newTitle.replace(shortcode.shortcode, selectedOption.label)
      } else {
        newTitle = newTitle.replace(shortcode.shortcode, surveyAnswer)
      }
    }
  })
  return newTitle
}

export const outroGlobalShortcodesRender = (
  shortcode: GLOBAL_SHORTCODES,
  title: string,
  types?: readonly string[],
  amount?: number
) => {
  const drugs: string[] = [
    UsingTypes.cannabis,
    UsingTypes.meth,
    UsingTypes.heroin,
    UsingTypes.opioids,
    UsingTypes.benzos,
    UsingTypes.other
  ]

  if (
    types &&
    types.length > 0 &&
    shortcode === GLOBAL_SHORTCODES.OUTRO_USING_TYPES
  ) {
    const usingGrog = types.includes(UsingTypes.grog)
    const usingTobacco = types.includes(UsingTypes.tobacco)
    const usingDrugs = types.filter((type) => drugs.includes(type)).length > 0

    if (usingGrog && !usingTobacco && !usingDrugs) {
      return title.replace(shortcode, OUTRO_USING_TYPES_TEXT.DRINKING)
    }
    if (!usingGrog && usingTobacco && !usingDrugs) {
      return title.replace(shortcode, OUTRO_USING_TYPES_TEXT.SMOKING)
    }
    if (!usingGrog && !usingTobacco && usingDrugs) {
      return title.replace(shortcode, OUTRO_USING_TYPES_TEXT.DRUGS)
    }
    if (usingGrog && usingTobacco && !usingDrugs) {
      return title.replace(shortcode, OUTRO_USING_TYPES_TEXT.DANDS)
    }
    if (usingGrog && !usingTobacco && usingDrugs) {
      return title.replace(shortcode, OUTRO_USING_TYPES_TEXT.DANDD)
    }
    if (!usingGrog && usingTobacco && usingDrugs) {
      return title.replace(shortcode, OUTRO_USING_TYPES_TEXT.SANDD)
    }
    return title.replace(shortcode, OUTRO_USING_TYPES_TEXT.ALL)
  } else if (
    amount != null &&
    amount !== 0 &&
    shortcode === GLOBAL_SHORTCODES.OUTRO_STANDARD_DRINKS
  ) {
    const fraction = new Fraction(amount).toString()
    const intParts = fraction.split(' ')
    const int = intParts[0]
    const fracParts = intParts[1] ? intParts[1].split('/') : fraction.split('/')

    return title.replace(
      shortcode,
      `${intParts[1] ? int : ''}<sup>${fracParts[0]}</sup>&frasl;<sub>${
        fracParts[1]
      }</sub>`
    )
  }
  return title
}
