import {
  LOCATION_DATA_API_ENDPOINT,
  LOGIN_DATA_API_ENDPOINT,
  SURVEY_DATA_API_BASE,
  SURVEY_DATA_API_ENDPOINT,
  SURVEY_SUBMISSION_API_BASE,
  SURVEY_SUBMISSION_API_ENDPOINT,
  FEEDBACK_SUBMISSION_API_ENDPOINT,
  FORGOT_PIN_API_ENDPOINT,
  VALIDATE_SLK_API_ENDPOINT,
  LIST_SLK_API_ENDPOINT
} from 'shared/constants/Constants.d'
import type { DeepReadonly } from 'shared/types/general'
import type { SurveyResponse } from 'store/type'
import type {
  UseAPIHookStateModel,
  SubmissionAPIResponseModel,
  UseAPIHookLoginDataModel,
  DailyFeedbackModel,
  FeedbackErrorAPIResponseModel,
  SLKResponseModel,
  TargetAPIResponseModel
} from './client.model'

/**
 * A custom hook to handle Umbraco API
 */
export const surveyAPI = (): UseAPIHookStateModel => {
  const login = async (data: UseAPIHookLoginDataModel) => {
    return fetch(SURVEY_DATA_API_BASE + LOGIN_DATA_API_ENDPOINT, {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify(data)
    })
      .then(async (res) => res.json())
      .then(
        (response) => response,
        (error) => {
          console.error('Fetch API Failed with error: ', error)
          throw error
        }
      )
  }

  const fetchSurveyData = async (id: string) => {
    return fetch(`${SURVEY_DATA_API_BASE}${SURVEY_DATA_API_ENDPOINT}/${id}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'GET'
    })
      .then(async (res) => res.json())
      .then(
        (data) => {
          return data
        },
        (error) => {
          console.error('Fetch API Failed with error: ', error)
          throw error
        }
      )
  }

  const fetchLocationData = async () => {
    return fetch(SURVEY_DATA_API_BASE + LOCATION_DATA_API_ENDPOINT, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'GET'
    })
      .then(async (res) => res.json())
      .then(
        (data) => {
          return data
        },
        (error) => {
          console.error('Fetch Location API Failed with error: ', error)
          throw error
        }
      )
  }

  const fetchGrogShopData = async (id: string) => {
    return fetch(
      `${SURVEY_DATA_API_BASE}${SURVEY_DATA_API_ENDPOINT}/${id}/shop`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'GET'
      }
    )
      .then(async (res) => res.json())
      .then(
        (data) => {
          return data
        },
        (error) => {
          console.error('Fetch Grog Shop API Failed with error: ', error)
          throw error
        }
      )
  }

  const fetchSLKListData = async (serviceId?: string) => {
    return fetch(
      `${SURVEY_SUBMISSION_API_BASE}${LIST_SLK_API_ENDPOINT}/service/${serviceId}`,
      {
        method: 'GET',
        headers: { 'Content-Type': 'text/plain' }
      }
    )
      .then(async (res) => res.json())
      .then((response) => {
        console.log('List SLK API response: ', response)
        return response as SLKResponseModel
      })
      .catch((error) => {
        console.log('List SLK API failed with error: ', error)
        throw error
      })
  }

  const handleSurveySubmit = async (
    id: string,
    data: DeepReadonly<SurveyResponse[]>
  ): Promise<SubmissionAPIResponseModel> => {
    return fetch(
      `${SURVEY_SUBMISSION_API_BASE}${SURVEY_SUBMISSION_API_ENDPOINT}/${id}`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
      }
    )
      .then(async (res) => res.json())
      .then((response) => {
        return response as SubmissionAPIResponseModel
      })
      .catch((error) => {
        console.error('Submission API Failed with error: ', error)
        return error as SubmissionAPIResponseModel
      })
  }

  const fetchTargets = async (
    data: DeepReadonly<SurveyResponse[]>
  ): Promise<TargetAPIResponseModel> => {
    return fetch(
      `${SURVEY_SUBMISSION_API_BASE}${SURVEY_SUBMISSION_API_ENDPOINT}/target`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
      }
    )
      .then(async (res) => res.json())
      .then((response) => {
        return response as TargetAPIResponseModel
      })
      .catch((error) => {
        console.error('Target API Failed with error: ', error)
        return error as TargetAPIResponseModel
      })
  }

  const handleFeedbackSubmit = async (data: DailyFeedbackModel) => {
    return fetch(SURVEY_DATA_API_BASE + FEEDBACK_SUBMISSION_API_ENDPOINT, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    })
      .then(async (res) => res.json())
      .then((response) => {
        console.log('Feedback API Response: ', response)
        return response as number
      })
      .catch((error) => {
        console.error('Feedback API Failed with error: ', error)
        return error as FeedbackErrorAPIResponseModel
      })
  }

  const handleForgotPinSubmit = async (data: string) => {
    return fetch(SURVEY_DATA_API_BASE + FORGOT_PIN_API_ENDPOINT, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 'Username': data })
    })
      .then(async (res) => res.json())
      .then((response) => {
        console.log('Forgot pin API response: ', response)
        return response
      })
      .catch((error) => {
        console.log('Forgot pin API failed with error: ', error)
        return error
      })
  }

  const handleSLKValidation = async (data: string): Promise<boolean> => {
    return fetch(
      `${SURVEY_SUBMISSION_API_BASE}${VALIDATE_SLK_API_ENDPOINT}/${data}`,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      }
    )
      .then(async (res) => res.json())
      .then((response) => {
        console.log('Validate SLK API response: ', response)
        return response as boolean
      })
      .catch((error) => {
        console.log('Validate SLK API failed with error: ', error)
        throw error
      })
  }

  return {
    login,
    fetchSurveyData,
    fetchLocationData,
    fetchGrogShopData,
    fetchSLKListData,
    handleSurveySubmit,
    fetchTargets,
    handleFeedbackSubmit,
    handleForgotPinSubmit,
    handleSLKValidation
  }
}
