import React, { useState } from 'react'
import {
  DynamicPropsModel,
  DynamicSliderImageProps
} from './DynamicSlider.model'
import MuiSlider, { SliderThumb } from '@mui/material/Slider'
import styles from './DynamicSlider.module.scss'
import { styled } from '@mui/material/styles'
import { Icon } from 'components/Icons/Icon.component'
import { colors } from 'shared/theme/theme'
import classnames from 'classnames'
import { IconType } from 'components/Icons/Icon.model'
import { OptionalButtons } from 'components/OptionalButtons/OptionalButtons.component'
import { ImageContainer } from 'components/ImageContainer/ImageContainer.component'
import { useColourPicker } from 'context/ColourContext'
import { DEFAULT_EMPTY_IMAGE_PATH } from 'shared/constants/Constants.d'

const CustomSlider = ({ customColor = colors.black, ...props }) => (
  <MuiSlider {...props} />
)

const IconSlider = styled(CustomSlider)(({ customColor = colors.black }) => ({
  color: customColor,
  height: 10,
  '& .MuiSlider-valueLabel': {
    backgroundColor: customColor
  }
}))

/**
 * Generate slider thumb component
 * @param thumbProps Html attributes for slider thumb
 * @returns {JSX.Element} Slider thumb component
 */
const SliderThumbComponent = (
  thumbProps: React.HTMLAttributes<HTMLElement>
) => {
  const { children, ...other } = thumbProps
  return (
    <SliderThumb {...other}>
      {children}
      <Icon icon={IconType.Hand} size={50} stroke={colors.white} />
    </SliderThumb>
  )
}

/**
 * Generate static image
 * @returns {(JSX.Element | null)} image or placeholder
 */
const DynamicSliderImage: React.FC<DynamicSliderImageProps> = (props) => {
  const { imageSrc, sliderTitle, hasOptionalButtons } = props

  if (!imageSrc) {
    return null
  }

  if (imageSrc == DEFAULT_EMPTY_IMAGE_PATH) {
    return <div className="image-space-holder">Picture / illustration</div>
  }

  return (
    <img
      className={styles.image}
      src={imageSrc}
      style={{
        maxHeight: hasOptionalButtons ? 200 : 300
      }}
      role="presentation"
      alt={sliderTitle}
    />
  )
}

export const DynamicSlider: React.FC<DynamicPropsModel> = (props) => {
  const {
    className,
    title,
    subtitle,
    image,
    labelPrefix,
    labelSuffix,
    icon = IconType.Hand,
    min = 0,
    max = 100,
    step = 1,
    defaultValue = 0,
    dynamicImage,
    setValue,
    minLabel,
    maxLabel,
    customLabels,
    optionalButtons,
    isOutro,
    customComponent
  } = props

  const { secondaryColour } = useColourPicker()

  const [resetOptionalButtons, setResetOptionalButtons] =
    useState<boolean>(false)
  const [currentValue, setCurrentValue] = useState<number>(0)

  /**
   * Format prefix or suffix to number
   * @param value Input string for format
   * @returns {string} Formatted string with prefix and suffix
   */
  const handleLabelFormat = (value: number) => {
    let displayValue = value.toString()
    // Check Custom Label
    if (customLabels && customLabels.length > 0) {
      const customLabel = customLabels.find((item) => item.value === value)
      if (customLabel) return customLabel.label
    }

    // Add Prefix & Suffix
    if (labelPrefix) {
      displayValue = labelPrefix + ' ' + displayValue
    }
    if (labelSuffix) {
      displayValue = displayValue + ' ' + labelSuffix
    }
    return displayValue
  }

  const customImage = customLabels?.find(
    (item) => item.value == defaultValue
  )?.image

  const handleValueChange = (
    _event: Event | React.SyntheticEvent<Element, Event>,
    value: number | number[]
  ) => {
    // setResetOptionalButtons(!resetOptionalButtons)
    setValue(value as number)
    setCurrentValue(value as number)
  }

  return (
    <div
      className={classnames(styles.dynamicslider, className)}
      style={
        !image
          ? {
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start'
            }
          : {}
      }
    >
      {title && (
        <h1
          className="screen-titles--title"
          dangerouslySetInnerHTML={{ __html: title }}
        />
      )}
      {subtitle && (
        <p
          className="screen-titles--subtitle"
          dangerouslySetInnerHTML={{ __html: subtitle }}
        />
      )}
      <div
        className={classnames(styles.sliderAlign, {
          [styles['sliderAlign--middle']]: !title && !subtitle && !image
        })}
        style={{
          margin: 'auto',
          width: '100%',
          height: '50vh'
        }}
      >
        {dynamicImage && !customImage ? (
          !isOutro ? (
            <ImageContainer
              count={handleLabelFormat(currentValue || Number(defaultValue))}
              srcImg={image}
              minimumValue={Number(defaultValue)}
            />
          ) : (
            <ImageContainer
              count={handleLabelFormat(Number(defaultValue))}
              srcImg={image}
              minimumValue={Number(defaultValue)}
            />
          )
        ) : isOutro && customComponent ? (
          <div className={styles['dynamicslider-customComponent']}>
            {customComponent}
          </div>
        ) : (
          <div className={styles['dynamicslider-staticImage']}>
            <DynamicSliderImage
              imageSrc={customImage || image}
              sliderTitle={title}
              hasOptionalButtons={optionalButtons && optionalButtons.length > 0}
            />
          </div>
        )}

        {!isOutro && (
          <div className={classnames(styles['dynamicslider-innerWrapper'])}>
            <IconSlider
              customColor={secondaryColour}
              sx={{
                '& .MuiSlider-thumb': {
                  'svg': {
                    'path': {
                      fill: secondaryColour
                    }
                  }
                }
              }}
              className={classnames(styles.slider, {
                [styles['slider--round']]:
                  !labelPrefix &&
                  !labelSuffix &&
                  (!customLabels || customLabels.length === 0)
              })}
              components={{
                Thumb: SliderThumbComponent
              }}
              valueLabelFormat={handleLabelFormat}
              valueLabelDisplay="on"
              defaultValue={0}
              min={0}
              max={max || 100}
              step={step || 1}
              value={currentValue}
              onChange={handleValueChange}
              style={{
                marginTop: !image ? '-20vh' : '0vh'
              }}
            />
          </div>
        )}
        {optionalButtons && (
          <OptionalButtons
            optionalButtons={optionalButtons}
            color={secondaryColour}
            wide={true}
            reset={resetOptionalButtons}
            onButtonSelect={setValue}
          />
        )}
      </div>
    </div>
  )
}
