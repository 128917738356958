import type {
  GrogBrandModel,
  GrogContainerModel,
  GrogProductModel,
  GrogSubTypeModel,
  GrogTypeCategoryModel
} from 'api/client.model'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { GrogDiaryStep } from 'screens/survey/GrogDiaryScreen/GrogDiaryScreen.model'
import type { DeepReadonly } from 'shared/types/general'
import { typeCategoryHasSubTypes } from 'shared/types/guards'
import { AlcoholCalc } from '../alcoholCalculation/alcoholCalculation'
import {
  updateGrogDiaryCurrentConsumption,
  updateGrogDiaryCurrentSelections,
  updateGrogDiaryUserJourney,
  updateSurveyAnswer
} from 'store/reducer'
import { v4 as uuidv4 } from 'uuid'
import type {
  CurrentGrogConsumptionState,
  CurrentGrogDiarySelectionsState,
  GrogConsumptionData,
  GrogConsumptionState,
  GrogConsumptionStateHookProps,
  GrogConsumptionTransitionState
} from './grogConsumptionState.model'
import { GrogConsumptionTransitionStep } from './grogConsumptionState.model'
import type {
  GrogDiaryData,
  GrogDiaryUserJourneySkippedStepData,
  GrogDiaryUserJourneySkippedStepState,
  SurveyGrogDiaryCurrentConsumption,
  SurveyGrogDiaryCurrentSelections
} from 'store/type'
import * as utils from './utils'
import {
  findAllBrands,
  findAllSubTypes,
  findBrandByName,
  findContainerByName,
  findProductByName,
  findSubTypeById,
  findTypeCategoryById
} from '../grogShopSearch/grogShopSearch'

/**
 * A custom React hook to extract data from the user's current types/categories /
 * subtypes/ brand selection data in the Grog Diary in their Redux survey state, and map
 * it to pseudo React-style state variables and setters, including by mapping arrays of
 * numeric data model IDs to arrays Grog Shop model types.
 *
 * @param typesCategories - The list containing all Grog Shop types/categories of alcohol,
 * and by extension, all Grog Shop data model instances.
 * @param currentSelectionIds - The user's current selection of types/categories /
 * subtypes / brands of alcohol from the Redux state, as arrays of numeric IDs.
 *
 * @returns An object defining "pseudo React-style state data", providing state variables
 * and setters for the user's current alcohol type selections in the Grog Diary.
 */
const useCurrentGrogConsumptionSelections = (
  typesCategories: DeepReadonly<(GrogTypeCategoryModel | GrogSubTypeModel)[]>,
  currentSelectionIds: DeepReadonly<SurveyGrogDiaryCurrentSelections>
): CurrentGrogDiarySelectionsState => {
  const dispatch = useDispatch()

  const activeTypesCats = currentSelectionIds.activeTypesCatsIds.map(
    (id) => findTypeCategoryById(typesCategories, id)!
  )
  const setActiveTypesCats = (
    value: DeepReadonly<(GrogTypeCategoryModel | GrogSubTypeModel)[]>
  ) => {
    dispatch(
      updateGrogDiaryCurrentSelections({
        actionType: 'setValue',
        key: 'activeTypesCatsIds',
        value: value.map((typeCategory) => typeCategory.id)
      })
    )
  }

  const allSubTypes = findAllSubTypes(typesCategories)

  const activeSubTypes = allSubTypes.filter((subType) =>
    currentSelectionIds.activeSubTypesIds.includes(subType.id)
  )
  const setActiveSubTypes = (value: DeepReadonly<GrogSubTypeModel[]>) => {
    dispatch(
      updateGrogDiaryCurrentSelections({
        actionType: 'setValue',
        key: 'activeSubTypesIds',
        value: value.map((subType) => subType.id)
      })
    )
  }

  const allBrands = findAllBrands(allSubTypes)

  const activeBrands = allBrands.filter((brand) =>
    currentSelectionIds.activeBrandsIds.includes(brand.id)
  )
  const setActiveBrands = (value: DeepReadonly<GrogBrandModel[]>) => {
    dispatch(
      updateGrogDiaryCurrentSelections({
        actionType: 'setValue',
        key: 'activeBrandsIds',
        value: value.map((brand) => brand.id)
      })
    )
  }

  return {
    activeTypesCats,
    setActiveTypesCats,

    activeSubTypes,
    setActiveSubTypes,

    activeBrands,
    setActiveBrands
  }
}

/**
 * A custom React hook to extract data from the user's current consumption in the Grog
 * Diary in their Redux survey state, and map it to pseudo React-style state variables and
 * setters, including by mapping numeric and string IDs/names to Grog Shop model types.
 *
 * @param typesCategories - The list containing all Grog Shop types/categories of alcohol,
 * and by extension, all Grog Shop data model instances.
 * @param currentConsumptionState - The user's current consumption state sourced from
 * Redux survey data, if defined.
 *
 * @returns An object defining "pseudo React-style state data", providing state variables
 * and setters for the user's current consumption state in the Grog Diary.
 */
const useCurrentGrogConsumptionState = (
  typesCategories: DeepReadonly<(GrogTypeCategoryModel | GrogSubTypeModel)[]>,
  currentConsumptionState:
    | DeepReadonly<SurveyGrogDiaryCurrentConsumption>
    | undefined
): CurrentGrogConsumptionState => {
  const dispatch = useDispatch()

  // Worried about constant recalculations. Maybe `useMemo` may be worth it down the line?

  const curTypeCat = findTypeCategoryById(
    typesCategories,
    currentConsumptionState?.typeCategoryId
  )
  const setCurTypeCat = (
    value:
      | DeepReadonly<GrogTypeCategoryModel>
      | DeepReadonly<GrogSubTypeModel>
      | undefined
  ) => {
    dispatch(
      updateGrogDiaryCurrentConsumption({
        actionType: 'setValue',
        key: 'typeCategoryId',
        value: value?.id
      })
    )
  }

  const curSubType = findSubTypeById(
    curTypeCat,
    currentConsumptionState?.subTypeId
  )
  const setCurSubType = (value: DeepReadonly<GrogSubTypeModel> | undefined) => {
    dispatch(
      updateGrogDiaryCurrentConsumption({
        actionType: 'setValue',
        key: 'subTypeId',
        value: value?.id
      })
    )
  }

  const curBrand = findBrandByName(
    curTypeCat,
    curSubType,
    currentConsumptionState?.brandName
  )
  const setCurBrand = (value: DeepReadonly<GrogBrandModel> | undefined) => {
    dispatch(
      updateGrogDiaryCurrentConsumption({
        actionType: 'setValue',
        key: 'brandName',
        value: value?.name
      })
    )
  }

  const curProduct = findProductByName(
    curBrand,
    currentConsumptionState?.productName
  )
  const setCurProduct = (value: DeepReadonly<GrogProductModel> | undefined) => {
    dispatch(
      updateGrogDiaryCurrentConsumption({
        actionType: 'setValue',
        key: 'productName',
        value: value?.name
      })
    )
  }

  const curContainer = findContainerByName(
    curTypeCat,
    curSubType,
    currentConsumptionState?.containerName
  )
  const setCurContainer = (
    value: DeepReadonly<GrogContainerModel> | undefined
  ) => {
    dispatch(
      updateGrogDiaryCurrentConsumption({
        actionType: 'setValue',
        key: 'containerName',
        value: value?.name || value?.localDisplayName
      })
    )
  }

  const curDrinkAmount = currentConsumptionState?.drinkAmount
  const setCurDrinkAmount = (value: number | undefined) => {
    dispatch(
      updateGrogDiaryCurrentConsumption({
        actionType: 'setValue',
        key: 'drinkAmount',
        value
      })
    )
  }

  const curContainerAmount = currentConsumptionState?.containerAmount
  const setCurContainerAmount = (
    value: number | readonly number[] | undefined
  ) => {
    dispatch(
      updateGrogDiaryCurrentConsumption({
        actionType: 'setValue',
        key: 'containerAmount',
        value
      })
    )
  }

  const curIsGroup = currentConsumptionState?.isGroup
  const setCurIsGroup = (value: boolean | undefined) => {
    dispatch(
      updateGrogDiaryCurrentConsumption({
        actionType: 'setValue',
        key: 'isGroup',
        value
      })
    )
  }

  const curShare = currentConsumptionState?.shareAmount
  const setCurShare = (value: number | undefined) => {
    dispatch(
      updateGrogDiaryCurrentConsumption({
        actionType: 'setValue',
        key: 'shareAmount',
        value
      })
    )
  }

  const curMore = currentConsumptionState?.more
  const setCurMore = (value: boolean | undefined) => {
    dispatch(
      updateGrogDiaryCurrentConsumption({
        actionType: 'setValue',
        key: 'more',
        value
      })
    )
  }

  return {
    curTypeCat,
    setCurTypeCat,

    curSubType,
    setCurSubType,

    curBrand,
    setCurBrand,

    curProduct,
    setCurProduct,

    curContainer,
    setCurContainer,

    curDrinkAmount,
    setCurDrinkAmount,

    curContainerAmount,
    setCurContainerAmount,

    curIsGroup,
    setCurIsGroup,

    curShare,
    setCurShare,

    curMore,
    setCurMore
  }
}

/**
 * A custom React hook which manages the state and state transition logic for consumptions
 * within the Grog Diary for a specific user.
 *
 * @param props - The properties required by the hook to properly manage the state.
 *
 * @returns An object containing data representing the user's consumption state in the
 * Grog Diary, and functions which can be used to interact with the user's consumption
 * status in the Grog Diary.
 */
export const useGrogConsumptionState = (
  props: GrogConsumptionStateHookProps
): GrogConsumptionState => {
  const {
    name,
    groupStdDrinksThreshold,
    typesCategories,
    grogDiaryUserJourney,
    grogDiaryCurrentState,
    date,
    people,
    step,
    diary
  } = props

  const dispatch = useDispatch()

  // This hook is a bit messy, but by structuring like this, it reduces friction in
  // changing existing parts of this hook which previously used the React state
  // values/`setState` functions.
  const {
    curTypeCat,
    setCurTypeCat,

    curSubType,
    setCurSubType,

    curBrand,
    setCurBrand,

    curProduct,
    setCurProduct,

    curContainer,
    setCurContainer,

    curDrinkAmount,
    setCurDrinkAmount,

    curContainerAmount,
    setCurContainerAmount,

    curIsGroup,
    setCurIsGroup,

    curShare,
    setCurShare,

    curMore,
    setCurMore
  } = useCurrentGrogConsumptionState(
    typesCategories,
    grogDiaryCurrentState.consumption
  )

  const {
    activeTypesCats,
    setActiveTypesCats,

    activeSubTypes,
    setActiveSubTypes,

    activeBrands,
    setActiveBrands
  } = useCurrentGrogConsumptionSelections(
    typesCategories,
    grogDiaryCurrentState.selections
  )

  /**
   * If a container is selected, return the amount of alcohol within that container,
   * otherwise (taking into account mixers if required), otherwise, return `undefined` (as
   * it is unset).
   *
   * @returns If a container is selected, the amount of alcohol within it, otherwise
   * `undefined`.
   */
  const getContainerAlcoholAmount = (): number | undefined => {
    if (curContainerAmount == null) {
      // If container amount is not set, return `undefined`
      return undefined
    } else if (Array.isArray(curContainerAmount)) {
      // Container amount is array (as we are mixing juice/fizzy drink and alcohol)
      // Return the alcohol portion
      return curContainerAmount[0]
    } else {
      // Container amount just contains alcohol, so return this
      return curContainerAmount as number
    }
  }

  /**
   * Get the percentage of alcohol for the currently selected consumption of alcohol, or
   * return `undefined` if a brand/subtype is not currently selected.
   *
   * @returns The percentage of alcohol for the currently selected brand/subtype, or
   * `undefined` if this is not yet selected.
   */
  const getAlcoholPercentage = (): number | undefined => {
    let alcoholPercentage = undefined

    if (curBrand) {
      alcoholPercentage = curBrand.alcoholPercent
    } else if (curSubType) {
      alcoholPercentage = curSubType.alcoholPercent
    }

    if (alcoholPercentage != null) {
      return parseFloat(alcoholPercentage)
    } else {
      return undefined
    }
  }

  const getInitialConsumptionsFromDiary = (): GrogConsumptionData[] => {
    if (!diary) {
      // Undefined = empty array
      return []
    }

    // Get array of keys (ISO dates) sorted in reverse lexicographic order. This is to
    // ensure that we get each consumption in the order it was added
    const diaryKeyValuePairs = Object.entries(diary).sort(([keyA], [keyB]) => {
      return keyB.localeCompare(keyA)
    })

    const diaryConsumptions = []

    for (const [isoDateString, dateData] of diaryKeyValuePairs) {
      const date = new Date(isoDateString)

      for (const diaryConsumption of dateData.consumptions) {
        // Restore the objects
        const typeCategory = findTypeCategoryById(
          typesCategories,
          diaryConsumption.typecategory?.id
        )

        if (!typeCategory) {
          // As this is an incomplete consumption, we don't want to record it
          continue
        }

        const subType = findSubTypeById(
          typeCategory,
          diaryConsumption.subtype?.id
        )

        const brand = findBrandByName(
          typeCategory,
          subType,
          diaryConsumption.brand
        )

        const product = findProductByName(brand, diaryConsumption.product?.name)

        const container = findContainerByName(
          typeCategory,
          subType,
          diaryConsumption.container.name
        )

        const drinkAmount = diaryConsumption.drinkAmounts.totalAmount
        const shareAmount = diaryConsumption.drinkAmounts.shareAmount

        // The container amount - need to take into account any fizzy drink or not
        const containerGrogAmount = diaryConsumption.container.grog
        const containerFizzyAmount = diaryConsumption.container.fizzy

        let containerAmount
        if (containerFizzyAmount != null) {
          // Last container amount becomes an array
          containerAmount = [
            containerGrogAmount!,
            containerFizzyAmount + containerGrogAmount!
          ]
        } else {
          // Last container amount becomes a number
          containerAmount = containerGrogAmount
        }

        const consumption = {
          date,
          people: dateData.people,
          typeCat: typeCategory,
          subType,
          brand,
          product,
          container,
          drinkAmount,
          containerAmount,
          isGroup: shareAmount != null,
          share: shareAmount
        }

        diaryConsumptions.push(consumption)
      }
    }

    return diaryConsumptions
  }

  const [allConsumptions, setAllConsumptions] = useState<
    readonly GrogConsumptionData[]
  >(getInitialConsumptionsFromDiary())

  const { calcStandardDrinks } = AlcoholCalc()
  const selectedDateList = utils.getCalendarEventsFromDiary(diary)

  /**
   * Mark the most recent step in the user's grog diary journey as "skipped", storing
   * metadata to restore if the user navigates "back" again.
   *
   * @param skippedState - The current state of the Grog Diary at the point of skipping.
   * This will be stored as metadata to provide information on the state of the Grog Diary
   * for the skipped step.
   */
  const markCurrentStepAsSkipped = (
    skippedState: DeepReadonly<GrogDiaryUserJourneySkippedStepState>
  ) => {
    dispatch(
      updateGrogDiaryUserJourney({
        actionType: 'mark-skipped',
        data: skippedState
      })
    )
  }

  /**
   * Restore the consumption state of the Grog Diary that was present from a step which
   * was skipped.
   *
   * @param skippedStepData - The state of the Grog Diary present at the time a step was
   * skipped that will be restored.
   */
  const restoreSkippedStepData = (
    skippedStepData: DeepReadonly<GrogDiaryUserJourneySkippedStepData>
  ) => {
    const skippedTypeCategory = findTypeCategoryById(
      typesCategories,
      skippedStepData.skippedState.typeCategoryId
    )!

    setCurTypeCat(skippedTypeCategory)

    const skippedSubType = findSubTypeById(
      skippedTypeCategory,
      skippedStepData.skippedState.subTypeId
    )
    const skippedBrand = findBrandByName(
      skippedTypeCategory,
      skippedSubType,
      skippedStepData.skippedState.brandName
    )
    const skippedProduct = findProductByName(
      skippedBrand,
      skippedStepData.skippedState.productName
    )
    const skippedContainer = findContainerByName(
      skippedTypeCategory,
      skippedSubType,
      skippedStepData.skippedState.containerName
    )

    // Restore current data to come back to later, if the Grog Diary was not at the end of
    // its cycle
    if (step.value < GrogDiaryStep.MORE) {
      if (curBrand != null) {
        setActiveBrands([curBrand, ...activeBrands])
      } else if (curSubType != null) {
        setActiveSubTypes([curSubType, ...activeSubTypes])
      } else if (curTypeCat != null) {
        setActiveTypesCats([curTypeCat, ...activeTypesCats])
      }
    }

    // Restore state at time of skipping, including any undefined variables

    // Drink Amount
    setCurDrinkAmount(skippedStepData.skippedState.drinkAmount)

    // Selected product or container
    // If the correct logic has been maintained, either product OR container-related data
    // should be undefined (as selection can only be one or the other)
    setCurContainerAmount(skippedStepData.skippedState.containerAmount)
    setCurContainer(skippedContainer)
    setCurProduct(skippedProduct)

    // Brand
    setCurBrand(skippedBrand)

    // Subtype
    setCurSubType(skippedSubType)
  }

  /**
   * A wrapper function to perform a backwards step whilst recording a consumption. If the
   * previous step was skipped, restore the consumption state that was present when this
   * state was skipped.
   */
  const consumptionStepBackward = () => {
    const previousStepData = grogDiaryUserJourney.at(-2)
    if (previousStepData == null) {
      // No previous step
      return
    }

    if (previousStepData.skipped) {
      // Restore data from the skipped step
      restoreSkippedStepData(previousStepData)
    }

    step.stepBackward()
  }

  /**
   * Fetch the oldest date from all records in the currently saved Grog Diary. This
   * currently corresponds to the most recently selected date in the current flow of the
   * Grog Diary.
   *
   * @returns The oldes date from all records in the currently saved Grog Diary, or `null`
   * if no dates are currently saved.
   */
  const getOldestDate = (): Date | null => {
    const mappedDateSecondsList = selectedDateList.map((event) =>
      event.date.getTime()
    )
    if (mappedDateSecondsList.length === 0) {
      return null
    }

    return new Date(Math.min(...mappedDateSecondsList))
  }

  /**
   * Reset the selection values for the current step.
   *
   * @returns An object representing the current type/categories, subtypes, and brands
   * selection after being reset.
   */
  const resetActiveSelections = (): {
    typesCats: DeepReadonly<(GrogTypeCategoryModel | GrogSubTypeModel)[]>
    subTypes: DeepReadonly<GrogSubTypeModel[]>
    brands: DeepReadonly<GrogBrandModel[]>
  } => {
    const newActiveSelections = {
      typesCats: activeTypesCats,
      subTypes: activeSubTypes,
      brands: activeBrands
    }

    switch (step.value) {
      case GrogDiaryStep.BRAND:
        newActiveSelections.brands = []
        setActiveBrands(newActiveSelections.brands)
        break
      case GrogDiaryStep.SUBTYPE:
        newActiveSelections.subTypes = []
        setActiveSubTypes(newActiveSelections.subTypes)
        break
      case GrogDiaryStep.TYPESCATEGORIES:
        newActiveSelections.typesCats = []
        setActiveTypesCats(newActiveSelections.typesCats)
        break
      default:
        // Nothing to reset
        break
    }

    return newActiveSelections
  }

  /**
   * Navigate back to a previous page which contextually has been a slider or selection
   * screen, ensuring the selections which will be made on this current page are cleared.
   */
  const goBackToSliderOrSelection = () => {
    const previousStepData = grogDiaryUserJourney.at(-2)
    if (previousStepData == null) {
      // No previous step
      return
    }

    resetActiveSelections()

    if (previousStepData.skipped) {
      // Previous step was skipped – don't attempt to change anything from the Redux
      // survey data
      restoreSkippedStepData(previousStepData)
      step.stepBackward()
      return
    }

    // Otherwise, previous step was not skipped

    switch (previousStepData.step) {
      case GrogDiaryStep.MORE:
        // Clear confirmation of further consumptions
        setCurMore(undefined)
        break
      case GrogDiaryStep.SHARE:
      case GrogDiaryStep.IS_GROUP:
      case GrogDiaryStep.PRODUCT_CONTAINER:
        popFromDiary(true)
        // Reset the "group share" amounts
        setCurShare(undefined)
        break
      case GrogDiaryStep.PRODUCT: {
        setCurDrinkAmount(undefined)
        setCurContainerAmount(undefined)
        setCurContainer(undefined)
        setCurProduct(undefined)

        break
      }
      case GrogDiaryStep.BRAND:
        setCurProduct(undefined)
        setCurContainer(undefined)
        setCurBrand(undefined)
        setActiveBrands([])
        break
      case GrogDiaryStep.SUBTYPE:
        // Type cat must be defined
        if (curBrand) {
          setCurBrand(undefined)
        }

        if (step.value === GrogDiaryStep.PRODUCT) {
          // Current step is product, so we need to remove the selected product/container
          setCurProduct(undefined)
          setCurContainer(undefined)
        }

        setCurSubType(undefined)
        setActiveSubTypes([])
        break
      case GrogDiaryStep.TYPESCATEGORIES:
        // Unset active type category

        if (step.value === GrogDiaryStep.PRODUCT) {
          // Current step is product, so we need to remove the selected product/container
          setCurProduct(undefined)
          setCurContainer(undefined)
        } else if (step.value === GrogDiaryStep.BRAND) {
          // If we had a type/category with no subtypes, but had brands, we need to clear
          // the selected brands just in case
          setCurBrand(undefined)
        } else if (step.value === GrogDiaryStep.SUBTYPE) {
          setCurSubType(undefined)
        }

        setCurTypeCat(undefined)
        setActiveTypesCats([])
        break
      case GrogDiaryStep.PEOPLE:
        people.set(undefined)
        date.set(undefined)
        break
      case GrogDiaryStep.LASTDAY:
        date.set(undefined)
        break
      default:
        // Other cases not covered here
        break
    }

    step.stepBackward()
  }

  /**
   * Add a provided set of consumption data to the user's Grog Diary data stored in Redux.
   *
   * @param consumption - The consumption data to store in the user's Grog Diary data.
   */
  const addConsumptionToDiary = (consumption: GrogConsumptionData) => {
    if (!date.value) {
      return
    }

    const updatedDiary = { ...diary }
    const dateISOString = date.value.toISOString()

    if (!(dateISOString in updatedDiary)) {
      updatedDiary[dateISOString] = {
        people: people.value,
        consumptions: []
      }
    }

    updatedDiary[dateISOString] = {
      ...updatedDiary[dateISOString],
      consumptions: [
        ...updatedDiary[dateISOString].consumptions,
        {
          uuid: uuidv4(),
          ...(consumption.typeCat && {
            typecategory: {
              id: consumption.typeCat.id,
              name: consumption.typeCat.name
            }
          }),
          ...(consumption.subType && {
            subtype: {
              id: consumption.subType.id,
              name: consumption.subType.name
            }
          }),
          brand: consumption.brand?.name,
          ...(consumption.product && {
            product: {
              name: consumption.product.name,
              image: consumption.product.image,
              capacity: consumption.product.capacity
            },
            subContainer: {
              amount: consumption.product.subContainer,
              stepsInEach: consumption.product.stepsPerSubContainer
            }
          }),
          container: {
            name:
              consumption.container?.localDisplayName ||
              consumption.container?.name,
            capacity: consumption.container?.capacity,
            image: consumption.container?.image,
            topBorder: consumption.container?.topBorder,
            bottomBorder: consumption.container?.bottomBorder,
            colour: consumption.brand?.colour || consumption.subType?.colour,
            fillMaskImage: consumption.container?.fillMaskImage,
            grog: Array.isArray(consumption.containerAmount)
              ? consumption.containerAmount[0]
              : consumption.containerAmount,
            fizzy: Array.isArray(consumption.containerAmount)
              ? consumption.containerAmount[1] - consumption.containerAmount[0]
              : undefined
          },
          drinkAmounts: {
            totalAmount: curDrinkAmount,
            shareAmount: curShare,
            individualAmount:
              curShare != null && curShare !== 0 ? curShare : curDrinkAmount
          },
          alcoholPercentage: curBrand?.alcoholPercent
            ? parseFloat(curBrand.alcoholPercent)
            : curSubType?.alcoholPercent
            ? parseFloat(curSubType.alcoholPercent)
            : undefined
        }
      ]
    }

    dispatch(
      updateSurveyAnswer({
        [name]: updatedDiary
      })
    )
  }

  /**
   * Return the number of consumptions for the current date in the Grog Diary, or `null`
   * if the current date has not been set yet, or the `diary` passed to the
   * {@link useGrogConsumptionState} hook is `undefined`.
   *
   * @returns The number of consumptions for the current date in the Grog Diary, or `null`
   * if the current date is not set, or the `diary` data is `undefined`.
   */
  const numConsumptionsCurrentDate = (): number | null => {
    if (diary == null || date.value == null) {
      // No date set, OR diary is `undefined` (for some reason), return `null`
      return null
    }

    const currentConsumptionData = diary[date.value.toISOString()] as
      | DeepReadonly<GrogDiaryData>
      | undefined
    if (currentConsumptionData == null) {
      // No consumption data for this date recorded
      return null
    }

    return currentConsumptionData.consumptions.length
  }

  /**
   * Check whether a given alcohol type instance is defined, and if so, whether an audio
   * fragment for the provided qualifier key exists within it.
   *
   * @param grogTypeModel - The alchohol type instance to check whether an appropriate
   * audio fragment exists, or `undefined` if unset.
   * @param fragmentQualifier - The qualifying "key" to check for existing audio fragments
   * in a defined alcohol type instance.
   *
   * @returns `true` if `grogTypeModel` is defined AND an audio fragment with the
   * qualifying key given in `fragmentQualifier` exists, `false` otherwise.
   */
  const audioFragmentTypeExists = (
    grogTypeModel:
      | DeepReadonly<GrogTypeCategoryModel>
      | DeepReadonly<GrogSubTypeModel>
      | undefined,
    fragmentQualifier: string
  ): boolean => {
    const foundFragment = grogTypeModel?.audioFragments?.find(
      (fragment) => fragment.name === fragmentQualifier
    )
    return foundFragment != null
  }

  /**
   * Construct a key used to play an audio fragment from a selection within the Grog
   * Diary. If no selections are active, or no audio fragments exist for the provided
   * qualifier, `null` is returned.
   *
   * @param fragmentQualifier - The qualifying "key" for the fragment.
   *
   * @returns The key used to play an appropriate audio fragment per the current
   * selections in the Grog Diary, or `null` if no selections or fragments matching the
   * provided qualifier exist.
   */
  const constructAudioFragmentsKey = (
    fragmentQualifier: string
  ): string | null => {
    if (audioFragmentTypeExists(curSubType, fragmentQualifier)) {
      return `subType-${curSubType!.id}-audio-${fragmentQualifier}`
    } else if (audioFragmentTypeExists(curTypeCat, fragmentQualifier)) {
      return `${curTypeCat!.id}-audio-${fragmentQualifier}`
    } else {
      // Not defined due to types not being selected
      return null
    }
  }

  /**
   * Store the current consumption data in the Grog Diary in the global Redux store for
   * the user, as well as in the hook's local array of all consumptions.
   *
   * This function should only be called once a complete consumption has been recorded.
   */
  const storeConsumptionData = () => {
    const consumptionData = {
      date: date.value!,
      people: people.value!,
      typeCat: curTypeCat,
      subType: curSubType,
      brand: curBrand,
      product: curProduct,
      container: curContainer,
      drinkAmount: curDrinkAmount,
      containerAmount: curContainerAmount,
      isGroup: curIsGroup,
      share: curShare
    }

    setAllConsumptions((consumptions) => [...consumptions, consumptionData])
    addConsumptionToDiary(consumptionData)
  }

  /**
   * Reset any selections present for product/containers, container alcohol and fizzy
   * drink/juice amounts, individual consumption amounts, whether the user was drinking in
   * a group or not, and any group share consumption data.
   */
  const resetProductConsumptionState = () => {
    setCurIsGroup(undefined)
    setCurShare(undefined)
    setCurMore(undefined)
    setCurProduct(undefined)
    setCurContainer(undefined)
    setCurDrinkAmount(undefined)
    setCurContainerAmount(undefined)
  }

  /**
   * Resets all consumption data for the current Grog Diary state for the current user.
   *
   * If `newDate` is set to `true`, the date and people within the user's drinking circle
   * will also be reset.
   *
   * @param [newDate=false] Whether to reset the data and people in the user's drinking
   * circle for the Grog Diary state of the current user. Defaults to `false`.
   */
  const resetState = (newDate = false) => {
    if (newDate) {
      date.set(undefined)
      people.set(undefined)
    }

    setCurTypeCat(undefined)
    setCurSubType(undefined)
    setCurBrand(undefined)

    resetProductConsumptionState()
  }

  /**
   * Delete the most-recently added consumption from the user's Grog Diary data stored in
   * the Redux store from a specified date.
   *
   * If a specified date is not provided, or is `undefined`, the function will delete the
   * consumption from the most recently-added consumption data.
   *
   * @param fromDate - The date that the most recent consumption should be removed from.
   */
  const deleteConsumption = (fromDate?: Date) => {
    const diaryCopy = { ...diary }

    let popDate
    if (!fromDate) {
      const oldestDate = getOldestDate()
      if (!oldestDate) {
        // If previous date is null, don't do anything
        return
      }

      // Otherwise, set the date to the "oldest date" in the survey data
      popDate = oldestDate
    } else {
      // Set the pop date to the date specified, ONLY IF there exists some record for this
      // date in the survey data
      if (diaryCopy[fromDate.toISOString()] === undefined) {
        return
      }

      popDate = fromDate
    }

    // Continue, ONLY IF there exists some record for this date in the survey data
    if (diaryCopy[popDate.toISOString()] === undefined) {
      return
    }

    const popDateISOString = popDate.toISOString()

    const surveyRecordToPopConsumption = diaryCopy[popDateISOString]

    // Remove the most recently-added consumption record
    const surveyRecordPoppedConsumption = {
      ...surveyRecordToPopConsumption,
      consumptions: surveyRecordToPopConsumption.consumptions.slice(0, -1)
    }

    if (surveyRecordPoppedConsumption.consumptions.length === 0) {
      // If EVERY consumption record for a specific date is removed, we may remove there
      // even being a record in the diary existing for that date
      delete diaryCopy[popDateISOString]
    } else {
      // Save the modified survey record to the survey state
      diaryCopy[popDateISOString] = surveyRecordPoppedConsumption
    }

    dispatch(updateSurveyAnswer({ [name]: diaryCopy }))
  }

  /**
   * Extract the consumption record that was most recently added to the Grog Diary survey
   * data for the current date, and restore information stored in this consumption to
   * state variables in the React component.
   *
   * Optionally, also removes ("pops") this most recent added consumption record from the
   * saved diary data.
   *
   * @param [removeStoredConsumption=false] Whether to remove the most recent consumption
   * record from the saved diary data. Defaults to `false`.
   *
   * @returns `true` if a consumption was successfully popped for the current date, and
   * `false` if there was no existing consumption for this date which was popped.
   */
  const popFromDiary = (removeStoredConsumption = true): boolean => {
    // Only pop if date matches

    // Try get it from the consumptions array
    const lastConsumption = allConsumptions.at(-1)
    if (
      !lastConsumption ||
      lastConsumption.date.valueOf() !== date.value?.valueOf()
    ) {
      return false
    }

    if (
      curBrand &&
      lastConsumption.brand &&
      curBrand.name !== lastConsumption.brand.name
    ) {
      // push to front
      setActiveBrands([curBrand, ...activeBrands])
    }

    if (
      curSubType &&
      lastConsumption.subType &&
      curSubType.name !== lastConsumption.subType.name
    ) {
      // push to front
      setActiveSubTypes([curSubType, ...activeSubTypes])
    }

    if (
      curTypeCat &&
      lastConsumption.typeCat &&
      curTypeCat.name !== lastConsumption.typeCat.name
    ) {
      // push to front
      setActiveTypesCats([curTypeCat, ...activeTypesCats])
    }

    // Set state
    people.set(lastConsumption.people)

    setCurTypeCat(lastConsumption.typeCat)
    setCurSubType(lastConsumption.subType)
    setCurBrand(lastConsumption.brand)
    setCurProduct(lastConsumption.product)
    setCurContainer(lastConsumption.container)
    setCurDrinkAmount(lastConsumption.drinkAmount)
    setCurContainerAmount(lastConsumption.containerAmount)
    setCurIsGroup(lastConsumption.isGroup)
    setCurShare(lastConsumption.share)

    if (removeStoredConsumption) {
      // Remove if explicitly requested
      setAllConsumptions((consumptions) => consumptions.slice(0, -1))
      deleteConsumption(lastConsumption.date)
    }

    date.set(lastConsumption.date)

    return true
  }

  /**
   * Skips the current step, recording the current consumption state simultaneously for
   * this step.
   */
  const skipStep = () => {
    if (!curTypeCat) {
      // If type cat is not set, we cannot skip - throw an error for this
      throw new Error('Cannot skip if type/category is not set')
    }

    const skipState = {
      typeCategoryId: curTypeCat.id,
      subTypeId: curSubType?.id,
      brandName: curBrand?.name,
      productName: curProduct?.name,
      containerName: curContainer?.name,
      containerAmount: curContainerAmount,
      drinkAmount: curDrinkAmount
    }

    // Reset multi-choice active selections
    const activeSelections = resetActiveSelections()

    // Reset other selections not covered above
    switch (step.value) {
      case GrogDiaryStep.PRODUCT:
        // We need to reset a product/container that may be selected
        skipState.productName = undefined
        skipState.containerName = undefined
        resetProductConsumptionState()
        break

      case GrogDiaryStep.CUSTOM_CONTAINER:
        // We need to reset the filled amount in the vessel-based container
        skipState.containerAmount = undefined
        resetProductConsumptionState()
        break

      case GrogDiaryStep.PRODUCT_CONTAINER:
        // We need to reset the individual consumption amount
        skipState.drinkAmount = undefined
        resetProductConsumptionState()
        break

      case GrogDiaryStep.SHARE:
        // As we aren't storing the share amount, just reset the consumption state
        resetProductConsumptionState()
        break

      default:
        // NOOP
        break
    }

    let nextStep

    if (activeSelections.brands.length > 0) {
      // Next step is to switch to the next active brand
      const nextActiveBrands = [...activeBrands]
      const nextBrand = nextActiveBrands.shift()!
      setActiveBrands(nextActiveBrands)

      nextStep = GrogDiaryStep.PRODUCT
      setCurBrand(nextBrand)
    } else if (activeSelections.subTypes.length > 0) {
      const nextActiveSubTypes = [...activeSubTypes]
      const nextSubType = nextActiveSubTypes.shift()!
      setActiveSubTypes(nextActiveSubTypes)

      if (utils.grogTypeHasBrands(nextSubType)) {
        nextStep = GrogDiaryStep.BRAND
      } else {
        nextStep = GrogDiaryStep.PRODUCT
      }

      setCurSubType(nextSubType)
    } else if (activeSelections.typesCats.length > 0) {
      const nextActiveTypesCats = [...activeTypesCats]
      const nextTypeCat = nextActiveTypesCats.shift()!
      setActiveTypesCats(nextActiveTypesCats)

      if (typeCategoryHasSubTypes(nextTypeCat)) {
        nextStep = GrogDiaryStep.SUBTYPE
      } else if (utils.grogTypeHasBrands(nextTypeCat)) {
        nextStep = GrogDiaryStep.BRAND
      } else {
        nextStep = GrogDiaryStep.PRODUCT
      }

      setCurTypeCat(nextTypeCat)
    } else {
      // No more consumptions to skip to

      // Get all consumptions for current date
      const numCurrentDateConsumptions = allConsumptions.filter(
        (consumption) => consumption.date.valueOf() === date.value?.valueOf()
      ).length
      if (numCurrentDateConsumptions > 0) {
        // We can move on to the summary page – but first, show the confirmation screen
        nextStep = GrogDiaryStep.MORE
      } else {
        // There are no consumptions to show (user has skipped everyting), so we need to
        // navigate back to the types/categories screen
        // At the same time, in order to prevent "going back" unnecessarily to previous
        // selection screens (as currently is implemented in the Grog App), we need to
        // clear the history of consumption steps
        dispatch(
          updateGrogDiaryUserJourney({ actionType: 'rewind-to-date-start' })
        )

        step.stepForward(GrogDiaryStep.TYPESCATEGORIES)
        return
      }
    }

    markCurrentStepAsSkipped(skipState)
    step.stepForward(nextStep)
  }

  /**
   * Transition the Grog Diary to the next appropriate step, depending on the current
   * consumption state of the Grog Diary, and whether the user is navigating backwards or
   * forwards.
   *
   * @param direction - Whether the user is navigating backwards or forwards in the Grog
   * Diary.
   * @param transitionState - An optional object describing the current state of the Grog
   * Diary if this function needs to be called recursively to do multiple automated
   * transitions, as the asynchronous nature of setting the Redux state may prevent survey
   * state variables from updating in time.
   */
  const transition = (
    direction: GrogConsumptionTransitionStep,
    transitionState?: GrogConsumptionTransitionState
  ) => {
    const transitionStep = transitionState?.step ?? step.value
    const selectedTypesCats =
      transitionState?.selectedTypesCats ?? activeTypesCats
    const selectedSubTypes = transitionState?.selectedSubTypes ?? activeSubTypes
    const selectedBrands = transitionState?.selectedBrands ?? activeBrands
    const itemWasAddedToDiary = transitionState?.itemWasAddedToDiary ?? false

    switch (transitionStep) {
      case GrogDiaryStep.TYPESCATEGORIES:
        switch (direction) {
          case GrogConsumptionTransitionStep.NEXT: {
            const nextActiveTypesCats = [...selectedTypesCats]
            const nextTypeCat = nextActiveTypesCats.shift()
            setActiveTypesCats(nextActiveTypesCats)

            if (!nextTypeCat) {
              // No chosen types categories remaining
              setCurTypeCat(undefined)

              if (itemWasAddedToDiary) {
                step.stepForward(GrogDiaryStep.MORE)
              }
              return
            }

            let nextStep
            if (typeCategoryHasSubTypes(nextTypeCat)) {
              nextStep = GrogDiaryStep.SUBTYPE
            } else if (utils.grogTypeHasBrands(nextTypeCat)) {
              nextStep = GrogDiaryStep.BRAND
            } else {
              nextStep = GrogDiaryStep.PRODUCT
            }

            setCurTypeCat(nextTypeCat)
            step.stepForward(nextStep)
            break
          }

          case GrogConsumptionTransitionStep.BACK:
            setActiveTypesCats([])
            consumptionStepBackward()
            break
        }
        break

      case GrogDiaryStep.SUBTYPE:
        switch (direction) {
          case GrogConsumptionTransitionStep.NEXT: {
            const nextActiveSubTypes = [...selectedSubTypes]
            const nextSubType = nextActiveSubTypes.shift()
            setActiveSubTypes(nextActiveSubTypes)

            if (!nextSubType) {
              // No chosen subtypes remaining
              setCurSubType(undefined)

              step.set(GrogDiaryStep.TYPESCATEGORIES)
              transition(GrogConsumptionTransitionStep.NEXT, {
                step: GrogDiaryStep.TYPESCATEGORIES,
                selectedTypesCats,
                selectedSubTypes: nextActiveSubTypes,
                selectedBrands,
                itemWasAddedToDiary
              })
              return
            }

            let nextStep
            if (utils.grogTypeHasBrands(nextSubType)) {
              nextStep = GrogDiaryStep.BRAND
            } else {
              nextStep = GrogDiaryStep.PRODUCT
            }

            setCurSubType(nextSubType)
            step.stepForward(nextStep)
            break
          }

          case GrogConsumptionTransitionStep.BACK:
            goBackToSliderOrSelection()
            break
        }
        break

      case GrogDiaryStep.BRAND:
        switch (direction) {
          case GrogConsumptionTransitionStep.NEXT: {
            const nextActiveBrands = [...selectedBrands]
            const nextBrand = nextActiveBrands.shift()

            if (!nextBrand) {
              // No chosen brands remaining
              setCurBrand(undefined)

              step.set(GrogDiaryStep.SUBTYPE)
              transition(GrogConsumptionTransitionStep.NEXT, {
                step: GrogDiaryStep.SUBTYPE,
                selectedTypesCats,
                selectedSubTypes,
                selectedBrands: nextActiveBrands,
                itemWasAddedToDiary
              })
              return
            }

            setCurBrand(nextBrand)
            setActiveBrands(nextActiveBrands)
            step.stepForward(GrogDiaryStep.PRODUCT)
            break
          }

          case GrogConsumptionTransitionStep.BACK:
            goBackToSliderOrSelection()
            break
        }
        break

      case GrogDiaryStep.PRODUCT:
        switch (direction) {
          case GrogConsumptionTransitionStep.NEXT: {
            let nextStep
            if (curContainer) {
              nextStep = GrogDiaryStep.CUSTOM_CONTAINER
            } else {
              nextStep = GrogDiaryStep.PRODUCT_CONTAINER
            }

            step.stepForward(nextStep)
            break
          }

          case GrogConsumptionTransitionStep.BACK:
            goBackToSliderOrSelection()
            break
        }
        break

      case GrogDiaryStep.CUSTOM_CONTAINER:
        switch (direction) {
          case GrogConsumptionTransitionStep.NEXT:
            step.stepForward(GrogDiaryStep.PRODUCT_CONTAINER)
            break

          case GrogConsumptionTransitionStep.BACK:
            goBackToSliderOrSelection()
            break
        }
        break

      case GrogDiaryStep.PRODUCT_CONTAINER:
        switch (direction) {
          case GrogConsumptionTransitionStep.NEXT: {
            const standardDrinks = calcStandardDrinks({
              container: {
                grog: Array.isArray(curContainerAmount)
                  ? curContainerAmount[0]
                  : curContainerAmount,
                capacity: curContainer?.capacity
              },
              drinkAmount:
                curShare != null && curShare !== 0 ? curShare : curDrinkAmount,
              ...(curProduct && {
                productCapacity: curProduct.capacity,
                subContainer: {
                  amount: curProduct.subContainer,
                  stepsInEach: curProduct.stepsPerSubContainer
                }
              }),
              alcoholPercentage: curBrand?.alcoholPercent
                ? parseFloat(curBrand.alcoholPercent)
                : curSubType?.alcoholPercent
                ? parseFloat(curSubType.alcoholPercent)
                : undefined
            })

            if (
              people.value != null &&
              people.value > 1 &&
              standardDrinks > (groupStdDrinksThreshold ?? 0)
            ) {
              step.stepForward(GrogDiaryStep.IS_GROUP)
            } else {
              storeConsumptionData()
              resetProductConsumptionState()

              step.set(GrogDiaryStep.BRAND)
              transition(GrogConsumptionTransitionStep.NEXT, {
                step: GrogDiaryStep.BRAND,
                selectedTypesCats,
                selectedSubTypes,
                selectedBrands,
                itemWasAddedToDiary: true
              })
            }

            break
          }

          case GrogConsumptionTransitionStep.BACK:
            setCurDrinkAmount(undefined)

            if (curContainer) {
              setCurContainerAmount(undefined)
              // step.stepBackward()
              consumptionStepBackward()
            } else {
              goBackToSliderOrSelection()
            }

            break
        }
        break

      case GrogDiaryStep.IS_GROUP:
        switch (direction) {
          case GrogConsumptionTransitionStep.NEXT:
            if (curIsGroup) {
              // defined AND true
              step.stepForward(GrogDiaryStep.SHARE)
            } else {
              storeConsumptionData()
              resetProductConsumptionState()

              step.set(GrogDiaryStep.BRAND)
              transition(GrogConsumptionTransitionStep.NEXT, {
                step: GrogDiaryStep.BRAND,
                selectedTypesCats,
                selectedBrands,
                selectedSubTypes,
                itemWasAddedToDiary: true
              })
            }
            break

          case GrogConsumptionTransitionStep.BACK:
            setCurDrinkAmount(undefined)
            setCurIsGroup(undefined)
            consumptionStepBackward()
            break
        }
        break

      case GrogDiaryStep.SHARE:
        switch (direction) {
          case GrogConsumptionTransitionStep.NEXT:
            storeConsumptionData()
            resetProductConsumptionState()

            step.set(GrogDiaryStep.BRAND)
            transition(GrogConsumptionTransitionStep.NEXT, {
              step: GrogDiaryStep.BRAND,
              selectedTypesCats,
              selectedSubTypes,
              selectedBrands,
              itemWasAddedToDiary: true
            })
            break

          case GrogConsumptionTransitionStep.BACK:
            setCurShare(undefined)
            setCurIsGroup(undefined)
            consumptionStepBackward()
            break
        }
        break

      case GrogDiaryStep.MORE:
        switch (direction) {
          case GrogConsumptionTransitionStep.NEXT: {
            let nextStep
            if (curMore) {
              // Use will still continue adding more consumptions
              // Go to types/categories screen
              nextStep = GrogDiaryStep.TYPESCATEGORIES
            } else {
              // No more consumptions to add - move to diary
              nextStep = GrogDiaryStep.DIARY
            }

            step.stepForward(nextStep)
            break
          }

          case GrogConsumptionTransitionStep.BACK:
            goBackToSliderOrSelection()
            break
        }
        break

      default:
        // NOOP
        break
    }
  }

  return {
    current: {
      typeCat: { value: curTypeCat, set: setCurTypeCat },
      subType: { value: curSubType, set: setCurSubType },
      brand: { value: curBrand, set: setCurBrand },
      product: { value: curProduct, set: setCurProduct },
      container: { value: curContainer, set: setCurContainer },
      drinkAmount: { value: curDrinkAmount, set: setCurDrinkAmount },
      containerAmount: {
        value: curContainerAmount,
        set: setCurContainerAmount
      },
      isGroup: { value: curIsGroup, set: setCurIsGroup },
      share: { value: curShare, set: setCurShare },
      more: { value: curMore, set: setCurMore },

      selections: {
        activeTypesCats: { value: activeTypesCats, set: setActiveTypesCats },
        activeSubTypes: { value: activeSubTypes, set: setActiveSubTypes },
        activeBrands: { value: activeBrands, set: setActiveBrands }
      },

      audioFragmentsKeys: {
        whatTypes: constructAudioFragmentsKey('what-types'),
        howMuch: constructAudioFragmentsKey('how-much')
      }
    },

    transition,
    skipStep,
    resetState,
    resetProductConsumptionState,
    popFromDiary,
    deleteConsumption,
    goBackToPreviousConsumptionScreen: goBackToSliderOrSelection,
    getContainerAlcoholAmount,
    getAlcoholPercentage,
    numConsumptionsCurrentDate
  }
}
