import classnames from 'classnames'
import { Screen } from 'components/Screen/Screen.component'
import { SectionProgress } from 'components/SectionProgress/SectionProgress.component'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { USING_STATUS } from 'shared/constants/SurveyFields.d'
import { matchCondition } from 'shared/utils/matchCondition/matchCondition'
import { shortcodesRender } from 'shared/utils/shortcodesRender/shortcodesRender'
import { SurveyState } from 'store/type'
import { SectionProgressScreenPropsModel } from './SectionProgressScreen.model'
import styles from './SectionProgressScreen.module.scss'

export const SectionProgressScreenComponent: React.FC<
  SectionProgressScreenPropsModel
> = (props) => {
  const {
    currentScreenId,
    headerProps,
    screenData: { title, subtitle, options },
    footerProps,
    shortcodes,
    surveyData
  } = props

  const { getNextScreenId } = matchCondition()

  const [nextScreenId, setNextScreenId] = useState<string>()

  const filteredOptions =
    surveyData != null && surveyData[USING_STATUS] != null
      ? options.filter((option) =>
          surveyData[USING_STATUS].includes(option.value || option.label)
        )
      : options

  const handleFooterProps = () => {
    const updatedFooterProps = { ...footerProps }

    if (nextScreenId) {
      updatedFooterProps.nextScreenId = nextScreenId
    } else {
      updatedFooterProps.nextScreenId = getNextScreenId(
        undefined,
        footerProps.conditions,
        footerProps.nextScreenId
      )
    }

    return updatedFooterProps
  }

  return (
    <div
      className={classnames('drug-app-screen', styles.sectionprogressscreen)}
    >
      <Screen
        currentScreenId={currentScreenId}
        headerProps={headerProps}
        footerProps={handleFooterProps()}
      >
        <div className={styles['sectionprogressscreen-wrapper']}>
          <SectionProgress
            title={shortcodesRender(shortcodes, title)}
            subtitle={shortcodesRender(shortcodes, subtitle)}
            options={filteredOptions}
            setOptionScreenId={(value) =>
              setNextScreenId(value ? value : undefined)
            }
          />
        </div>
      </Screen>
    </div>
  )
}

const mapStateToProps = (state: SurveyState) => ({
  surveyData: state.surveyData
})

export const SectionProgressScreen = connect(mapStateToProps)(
  SectionProgressScreenComponent
)
