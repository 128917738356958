import React from 'react'
import styles from './ModalButtons.module.scss'
import type { ModalButtonsProps } from './ModalButtons.model'
import { ModalVariation } from '../Modal/Modal.model'
import { Button } from 'components/Button/Button.component'
import { IconType } from 'components/Icons/Icon.model'

export const ModalButtons: React.FC<ModalButtonsProps> = (props) => {
  const {
    value,
    setValue,
    optionValues,
    curQuestionId,
    handleModalClose,
    nextQuestion,
    handleNextQuestion,
    variation = ModalVariation.DEFAULT
  } = props

  if (variation === ModalVariation.INFO) {
    return (
      <div className={styles['modal-buttons__close-button']}>
        <Button
          className={styles.button}
          noBorder={true}
          icon={IconType.Close}
          onClick={() => handleModalClose(true)}
        ></Button>
      </div>
    )
  }

  return (
    <>
      <Button
        variation="primary"
        width="l"
        onClick={() => handleModalClose(true)}
      >
        Close
      </Button>
      <Button
        variation="primary"
        width="l"
        disabled={
          !optionValues || (!!curQuestionId && !optionValues?.[curQuestionId])
        }
        onClick={() => {
          // This is a hotfix of 'Other' button affecting the flows
          // https://github.com/sydney-uni-ict/Drug_App/issues/36
          if (
            setValue != null &&
            Array.isArray(value) &&
            optionValues !== undefined
          ) {
            const valueList: string[] = []

            value.forEach((v) => {
              if (typeof v === 'string') valueList.push(v)
            })
            if (optionValues && Array.isArray(optionValues.main)) {
              const optionValuesList = optionValues.main as string[]
              optionValuesList.forEach((ov) => valueList.push(ov))
            }
            setValue(valueList)
          }

          nextQuestion
            ? handleNextQuestion(nextQuestion)
            : handleModalClose(false)
        }}
      >
        Confirm
      </Button>
    </>
  )
}
