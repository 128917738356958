import React from 'react'
import styles from './SummaryList.module.scss'
import classnames from 'classnames'
import { SummaryDrinkListing } from './SummaryDrinkListing/SummaryDrinkListing.component'
import type { SummaryListProps } from './SummaryList.model'

export const SummaryList: React.FC<SummaryListProps> = (props) => {
  const {
    updatedConsumptionList,
    onDiaryIncrease,
    onDiaryDecrease,
    isOutro = false,
    scrollRef
  } = props

  return (
    <div
      className={classnames(styles['summary-list__container'])}
      style={{
        maxHeight: window.outerHeight < 800 && isOutro ? 285 : undefined
      }}
      ref={scrollRef}
    >
      {Object.keys(updatedConsumptionList).map((key) => (
        <div
          key={`grog-summary-section-${key}`}
          className={classnames(styles['summary-list'])}
        >
          <h4 className={classnames(styles['summary-list__type-title'])}>
            {key}
          </h4>

          <div style={{ height: '100%', overflowY: 'auto' }}>
            {updatedConsumptionList[key].map((item) => (
              <SummaryDrinkListing
                key={`grog-summary-section-${key}-${item.uuid}`}
                item={item}
                onDiaryIncrease={onDiaryIncrease}
                onDiaryDecrease={onDiaryDecrease}
                isOutro={isOutro}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}
