import React from 'react'
import styles from './DashboardAnalysis.module.scss'
import type { SpecialisedAnalysisComponentProps } from 'components/ra-dashboard/Analysis/Analysis.model'
import { Analysis } from 'components/ra-dashboard/Analysis/Analysis.component'

export const DashboardAnalysis: React.FC<SpecialisedAnalysisComponentProps> = (
  props
) => {
  const { title, values, sectionIndex } = props

  return (
    <Analysis
      title={title}
      values={values}
      sectionIndex={sectionIndex}
      renderQualifier="dashboard"
      drawProgressBar={false}
      stylingClasses={{
        mainDiv: styles['dashboard-analysis__section'],
        wrapperDiv: styles['dashboard-analysis__section__wrapper'],
        labels: styles['dashboard-analysis__section__labels']
      }}
    />
  )
}
