export const sliceIntoChunks = <T>(
  arr: readonly T[],
  chunkSize: number
): T[][] => {
  const res: T[][] = []
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize)
    res.push(chunk)
  }
  return res
}
