import React from 'react'
import classnames from 'classnames'
import styles from './AddDateButton.module.scss'
import type { AddDateButtonProps } from './AddDateButton.model'
import { Border } from 'components/Border/Border.component'
import { BorderType } from 'components/Border/Border.model'
import { Icon } from 'components/Icons/Icon.component'
import { IconType } from 'components/Icons/Icon.model'
import { colors } from 'shared/theme/theme'

export const AddDateButton: React.FC<AddDateButtonProps> = (props) => {
  const { onClick, className } = props

  return (
    <button
      onClick={onClick}
      className={classnames('u-border-style-sketch', className)}
    >
      <Border type={BorderType.Card1} />
      <Icon
        className={classnames(styles['add-date-button__icon'])}
        icon={IconType.Plus}
        size={36}
        stroke={colors.white}
        fill={colors.black}
        border={true}
      />
      <h4>Add Date</h4>
    </button>
  )
}
