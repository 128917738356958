import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import styles from './ProgressScreen.module.scss'
import { SideMenu } from 'components/ra-dashboard/SideMenu/SideMenu.component'
import { RADashboardMenu } from '../index.model'
import { useNavigate } from 'react-router-dom'
import type { SurveyState } from 'store/type'
import type { ProgressScreenPropsModel } from './ProgressScreen.model'
import type { Dispatch } from 'redux'
import { colors } from 'shared/theme/theme'
import Box from '@mui/material/Box'
import { Button } from 'components/Button/Button.component'
import { generateOptions } from 'shared/utils/generateOptions/generateOptions'
import { RADashCalc } from 'shared/utils/raDashboardCalculation/raDashboardCalculation'
import { BorderType } from 'components/Border/Border.model'
import { IconType } from 'components/Icons/Icon.model'
import classnames from 'classnames'
import { syncSurveysAPI } from 'store/actionCreators'
import { Card } from 'components/Card/Card.component'
import type { TargetGender } from 'shared/utils/raDashboardCalculation/raDashboardCalculation.model'
import { mapProjectSiteTargetCollection } from 'store/mapper'
import { ProjectSiteSelect } from 'components/ProjectSiteSelect/ProjectSiteSelect.component'
import { Snackbar } from 'components/Snackbar/Snackbar.component'
import { SUCCESSFULLY_SYNCED } from 'shared/constants/messages/Messages.d'
import { AnalysisBorderLinearProgress } from 'components/ra-dashboard/Analysis/AnalysisBorderLinearProgress/AnalysisBorderLinearProgress.component'
import { ProgressTargets } from 'components/ra-dashboard/Progress/ProgressTargets/ProgressTargets.component'

const ProgressScreenComponent: React.FC<ProgressScreenPropsModel> = (props) => {
  const {
    offline,
    serviceId,
    syncRounds,
    syncStatus,
    projects,
    projectSiteTargets,
    projectId: selectedProjectId,
    siteId: selectedSiteId,
    surveyResponses
  } = props

  const navigate = useNavigate()
  const dispatch: Dispatch<any> = useDispatch()

  const [projectId, setProjectId] = useState<string>(selectedProjectId)
  const [siteId, setSiteId] = useState<string>(selectedSiteId)
  const [isSync, setIsSync] = useState<boolean>(false)
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false)

  // Map a projectSiteTargets instance from project if the state.projectSiteTargets does not exist
  const projectSiteTargetCollection =
    projectSiteTargets.length > 0
      ? projectSiteTargets
      : mapProjectSiteTargetCollection(projects)

  const { generateProjectOptions, generateSiteOptions } = generateOptions()
  const { calcTargetSurveys } = RADashCalc()

  const targetSurveys = calcTargetSurveys(projectId, siteId)

  const handleMenuChange = (menu: string) => {
    navigate(`/ra-dashboard/${menu}`)
  }

  const handleSyncAPI = () => {
    setIsSync(true)
    // Create a clean projectSiteTargetsRequest without the numbers of collections populated
    const projectSiteTargetsRequest = mapProjectSiteTargetCollection(projects)
    dispatch(
      syncSurveysAPI({
        serviceId,
        surveyResponses,
        projectSiteTargets: projectSiteTargetsRequest
      })
    )

    setOpenSnackbar(true)
  }

  /**
   * Overall progress: Tracks the total synced completed surveys against the total target. (GitHub #508)
   */
  const getSiteOverallCollection = () => {
    const selectedProject = projectSiteTargetCollection.find(
      (p) => p.id === projectId
    )
    const site = selectedProject?.sites?.find((s) => s.id === siteId)
    if (selectedProject && site) {
      const numOfSyncedCompletedSurveys: number = site.numberOfCollection ?? 0
      return numOfSyncedCompletedSurveys
    }
    return 0
  }

  const calcTargetNumber = (
    total: boolean,
    age?: number[],
    gender?: TargetGender
  ) => {
    if (targetSurveys) {
      let totalNum = 0
      let filteredNum = 0

      targetSurveys.forEach((target) => {
        totalNum += target.count

        if (
          age &&
          gender &&
          age[0] <= target.minAge &&
          age[1] >= target.maxAge &&
          gender === target.gender
        ) {
          filteredNum += target.count
        }
      })

      if (total) {
        return totalNum
      } else {
        return filteredNum
      }
    }
    return 0
  }

  useEffect(() => {
    setIsSync(false)
  }, [syncRounds, surveyResponses, projectId, siteId])

  return (
    <div className={styles.progressscreen}>
      <SideMenu
        options={RADashboardMenu}
        value={RADashboardMenu[2].value}
        setValue={handleMenuChange}
      />
      <main className={styles['progressscreen-content']}>
        <h1
          style={{
            visibility: 'hidden',
            position: 'absolute',
            pointerEvents: 'none'
          }}
        >
          Overall Progress
        </h1>
        <div className={styles['progressscreen-row1']}>
          <Box component="form" className={styles['progressscreen-row1-form']}>
            <ProjectSiteSelect
              selectedProjectId={projectId}
              selectedSiteId={siteId}
              setSelectedProjectId={setProjectId}
              setSelectedSiteId={setSiteId}
              projects={projects}
              additionalStylingClasses={styles['progressscreen-select']}
              borderType={BorderType.Select2}
              style={{
                marginRight: 20,
                backgroundColor: colors.white
              }}
            />
          </Box>
          {offline ? (
            <Button
              className={styles['progressscreen-row1-syncButton']}
              variation="primary"
              disabled
              icon={IconType.NotSync}
              style={{ backgroundColor: colors.white }}
            >
              Sync
            </Button>
          ) : (
            <Button
              className={classnames(styles['progressscreen-row1-syncButton'], {
                [styles['progressscreen-row1-syncButton--loading']]: isSync
              })}
              onClick={handleSyncAPI}
              icon={IconType.Sync}
              borderType={BorderType.Option1}
              style={{
                backgroundColor: isSync ? colors.greenSmoke : colors.white
              }}
            >
              {!isSync && 'Sync'}
            </Button>
          )}
          {!isSync && (
            <Snackbar
              message={
                syncStatus && syncStatus.isSynced
                  ? SUCCESSFULLY_SYNCED
                  : syncStatus?.errorMessage || ''
              }
              isError={(syncStatus && !syncStatus.isSynced) || false}
              open={openSnackbar}
              setOpen={setOpenSnackbar}
            ></Snackbar>
          )}
        </div>
        <div className={styles['progressscreen-row2']}>
          <Card
            className={styles['progressscreen-card']}
            title={`${generateProjectOptions(projects, projectId)[0].label} / ${
              generateSiteOptions(projects, projectId)[0].label
            }`}
            titleColor={colors.breakerBay}
            titleHeight={57}
            centerAlign={false}
          >
            <div
              className={classnames(
                styles['progressscreen-analysisWrapper'],
                styles['progressscreen-analysisWrapper--total']
              )}
            >
              <div className={styles['progressscreen-analysis-section']}>
                <div
                  className={styles['progressscreen-analysis-section-wrapper']}
                >
                  {siteId && siteId !== '' && (
                    <Box sx={{ width: '100%' }}>
                      <label
                        id="site-progress-label"
                        className={
                          styles['progressscreen-analysis-section-labels']
                        }
                      >
                        <span>People surveyed at this site overall</span>
                        <span
                          className={
                            styles['progressscreen-analysis-section-labels-big']
                          }
                        >
                          {`${getSiteOverallCollection()} / ${calcTargetNumber(
                            true
                          )}`}
                          <span>
                            {(
                              (getSiteOverallCollection() /
                                calcTargetNumber(true)) *
                              100
                            ).toFixed(1)}
                            %
                          </span>
                        </span>
                      </label>
                      <AnalysisBorderLinearProgress
                        className={styles['progressscreen-analysis-progress']}
                        variant="determinate"
                        value={
                          (getSiteOverallCollection() /
                            calcTargetNumber(true)) *
                          100
                        }
                        style={{ height: 16 }}
                        aria-labelledby="site-progress-label"
                      />
                    </Box>
                  )}
                </div>
              </div>
            </div>
            <div className={styles['progressscreen-analysisWrapper']}>
              <ProgressTargets
                projectId={projectId}
                siteId={siteId}
                projectSiteTargetCollection={projectSiteTargetCollection}
              />
            </div>
          </Card>
        </div>
      </main>
    </div>
  )
}

const mapStateToProps = (state: SurveyState) => ({
  offline: state.offline || false,
  serviceId: state.service?.id,
  syncRounds: state.syncRounds,
  syncStatus: state.syncStatus,
  projects: state.projects ?? [],
  projectSiteTargets: state.projectSiteTargets ?? [],
  projectId: state.selectedProjectSite?.projectId || '',
  siteId: state.selectedProjectSite?.siteId || '',
  surveyResponses: state.surveyResponses
})

export const ProgressScreen = connect(mapStateToProps)(ProgressScreenComponent)
