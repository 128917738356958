import React from 'react'
import { connect } from 'react-redux'
import styles from './SupportScreen.module.scss'
import { SideMenu } from 'components/ra-dashboard/SideMenu/SideMenu.component'
import { RADashboardMenu } from '../index.model'
import { useNavigate } from 'react-router-dom'
import { SurveyState } from 'store/type'
import { SupportCard } from 'components/SupportCard/SupportCard.component'
import { SupportScreenPropsModel } from './SupportScreen.model'

const SupportScreenComponent: React.FC<SupportScreenPropsModel> = (props) => {
  const { contacts } = props
  const navigate = useNavigate()

  const handleMenuChange = (menu: string) => {
    navigate(`/ra-dashboard/${menu}`)
  }

  return (
    <div className={styles.supportscreen}>
      <SideMenu
        options={RADashboardMenu}
        value={RADashboardMenu[3].value}
        setValue={handleMenuChange}
      />

      <h1
        style={{
          visibility: 'hidden',
          position: 'absolute',
          pointerEvents: 'none'
        }}
      >
        Support Contacts
      </h1>
      <main className={styles['supportscreen-content']}>
        {contacts &&
          contacts.map((contact, index) => (
            <div
              key={`support-screen-support-card-${index}`}
              className={styles['supportscreen-cards']}
            >
              <SupportCard
                name={contact.name || ''}
                email={contact.email || ''}
                phone={contact.phone || ''}
                image={contact.image || ''}
              />
            </div>
          ))}
      </main>
    </div>
  )
}

const mapStateToProps = (state: SurveyState) => ({
  contacts: state.service?.contacts
})

export const SupportScreen = connect(mapStateToProps)(SupportScreenComponent)
