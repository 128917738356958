import type { SLKResponseModel } from 'api/client.model'
import type { DeepReadonly } from 'shared/types/general'
import type { SurveyResponse } from 'store/type'
import type { FilterSlksFilters } from './slkUtils.model'

/**
 * Obtain a list of SLKs from some data structured in the {@link SLKResponseModel}
 * interface.
 *
 * If a project ID is specified in the `projectId` parameter, the function will obtain a
 * list of all SLKs only associated with the specified ID, if it is valid, otherwise, if
 * the ID is not valid, it will return `null`.
 *
 * Otherwise, the function will return a list of all SLKs from the provided list of SLKs
 * matching the {@link SLKResponseModel} interface.
 *
 * @param slks - A list of all downloaded SLKs, grouped by project ID.
 * @param projectId - An optional parmater specifying the project ID that SLKs should be
 * retrieved from. If this is not specified, the function will return a list of all SLKs
 * for all projects, if the provided project ID has an associated list of SLKs.
 *
 * @returns If a `projectId` value is specified, the list of all SLKs for this project,
 * otherwise `null` if the project ID does not have an associated list of SLKs. Otherwise,
 * a list of all downloaded SLKs.
 */
export const getSlksFromResponseModel = (
  slks: DeepReadonly<SLKResponseModel>,
  projectId?: string
): readonly string[] | null => {
  if (projectId == null) {
    // If no project ID was provided as a filter, simply flat-map all the SLKs as a single
    // array
    return slks.flatMap((entry) => entry.slkList)
  }

  // Otherwise, return the list of SLKs matching the defined project ID
  const slkEntry = slks.find((entry) => entry.projectId === projectId)
  return slkEntry?.slkList ?? null
}

/**
 * Obtain a list of all SLKs which are currently in progress (i.e. can be continued).
 *
 * These can be filtered for a specific project ID (with the `projectId` parameter),
 * otherwise, this function will return the list of all in-progress survey SLKs.
 *
 * @param surveyResponses - A list of survey responses from participants.
 * @param projectId - An optional parameter allowing for the SLKs to be filtered by only
 * those which match a project ID, if specified.
 *
 * @returns A list of SLKs for surveys currently in progress. If the `projectId` parameter
 * is specified, the SLKs will be from only this project ID, otherwise, all in-progress
 * survey SLKs will be returned.
 */
export const getContinuingSurveySlks = (
  surveyResponses: DeepReadonly<SurveyResponse[]>,
  projectId?: string
): string[] => {
  const slks: string[] = []

  for (const surveyResponse of surveyResponses) {
    if (surveyResponse.metadata?.slk == null) {
      // Survey response has no set metadata, including SLK - we should ignore it
      continue
    }

    if (projectId != null && surveyResponse.metadata.projectId !== projectId) {
      // A project ID to filter surveys was provided, but the survey response's project ID
      // does not match this - we should ignore it
      continue
    }

    if (surveyResponse.metadata.flaggedComingBack) {
      // SLK has been flagged as "continuing"
      slks.push(surveyResponse.metadata.slk)
    }
  }

  return slks
}

/**
 * Filter all SLKs saved in the application by some specified criteria, and return a list
 * of these filtered SLKs.
 *
 * The list of SLKs to be filtered is a list of objects satisfying the
 * {@link SLKResponseModel} interface. In addition, a list of currently-saved survey
 * responses is passed with the `surveyResponses` parameter to use as part of filtering
 * logic for the function.
 *
 * @param allSlks - A list of all SLKs for the application, grouped by project ID.
 * @param surveyResponses - A list of survey responses from participants.
 * @param filters - The filters that should be used to a list of SLKs for the application.
 *
 * @returns A list of SLKs, filtered by any of the provided filters.
 */
export const filterAllSavedSlks = (
  allSlks: DeepReadonly<SLKResponseModel>,
  surveyResponses: DeepReadonly<SurveyResponse[]>,
  filters: DeepReadonly<FilterSlksFilters>
): string[] => {
  const slksMatchingProjectId =
    getSlksFromResponseModel(allSlks, filters.projectId) ?? []

  // We only have one filter at the moment, so this logic is a bit coupled to the
  // `filters.isInProgress` filter (as the logic for handling this is somewhat complex)
  //
  // TODO: Generalise the logic to be applicable for more filters

  // Get the array of SLKs for in-progress surveys
  const continuingSurveysSlkArray = getContinuingSurveySlks(
    surveyResponses,
    filters.projectId
  )
  // Convert this array to a `Set` to allow for quick lookups of SLKs
  const continuingSurveySlks = new Set(continuingSurveysSlkArray)

  const filteredSlksToReturn: string[] = []

  for (const slk of slksMatchingProjectId) {
    if (filters.isInProgress != null) {
      const slkMatchesContinuingSurvey = continuingSurveySlks.has(slk)

      // SHOULD ONLY add SLKs which represent a survey in progress AND the current SLK
      // DOES NOT match an SLK that is in progress
      const filterInProgressAndSlkNotContinuing =
        filters.isInProgress && !slkMatchesContinuingSurvey

      // SHOULD ONLY add SLKs which represent surveys NOT in progress (i.e. not to be
      // continued) AND the current SLK MATCHES an SLK that is in progress
      const filterNotInProgressAndSlkContinuing =
        !filters.isInProgress && slkMatchesContinuingSurvey

      if (
        filterInProgressAndSlkNotContinuing ||
        filterNotInProgressAndSlkContinuing
      ) {
        // Conditions for filter not satisfied - ignore current SLK
        continue
      }
    }

    // Filters for SLK satisfied - add to array of SLKs to return
    filteredSlksToReturn.push(slk)
  }

  // Return the final filtered SLKs
  return filteredSlksToReturn
}
